import { MYPAGE_API, USER_API, USER_CROWDFUNDING_API } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';
import utils from '@/uitls/Uitls';
import FormData from 'form-data'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const state = () => ({
  item: {
    user: null,
    affiliate: null,
    paymentStatus: null,
  },
  loadingOfItem: false,
  data: null,
  userInfo: {},
})

const getters = {
  item: (state) => state.item,
  data: (state) => state.data,
  loadingOfItem: (state) => state.loadingOfItem,
  userInfo: (state) => state.userInfo,
  affiliateCode: (state) => state.item.affiliate,
  isAuthenticated: (state) =>
    state.userInfo && Object.keys(state.userInfo).length > 0,
}

const mutations = {
  SET_ITEM(state, item) {
    state.item = item
    state.loadingOfItem = false
  },
  setLoadingOfItem(state, loading) {
    state.loadingOfItem = loading
  },
  SET_DATA(state, item) {
    // console.log('SET_DATA:item: ', item)
    // console.log('user.user: ', item.user)
    // console.log('user.affiliate: ', item.affiliate)
    // console.log('user.paymentStatus: ', item.paymentStatus)
    state.item = item
  },
  SET_USER_INFO(state, item) {
    state.userInfo = item
  },
  setAffiliateCode(state, code) {
    state.item.affiliate = code
  },
}

const actions = {
  async fetchMypage({ commit, state, dispatch }, id) {
    try {
      // commit('setLoadingOfItem', true)
      const response = await this.$axios.get(`${MYPAGE_API}`)

      if (response && response.data) {
        commit('SET_DATA', response.data)
        if (response.data.user) {
          commit('SET_USER_INFO', response.data.user)
        }
        return response.data
      }
      // commit('setLoadingOfItem', false)
      return null
    } catch (error) {
      return null
    }
  },
  // async deleteItem() {},
  // updateUserInfo({ commit }, data) {
  //   commit('SET_ITEM', data)
  // },

  async deleteItem() { },
  async fetchUserHistory({ commit }, params = {}) {
    try {
      const userId = params.userId
      delete params.id
      const response = await this.$axios.get(
        `${USER_API}/point/history/${userId}`,
        {
          params,
        }
      )
      return response.data
    } catch (error) {
      return null
    }
  },

  async fetchUserCouponHistory({ commit }, params = {}) {
    try {
      const userId = params.userId
      delete params.id
      const response = await this.$axios.get(`${USER_API}/coupon/${userId}`, {
        params,
      })
      return response.data
    } catch (error) {
      return null
    }
  },

  async getUserById({ commit, state, dispatch }, id) {
    try {
      const response = await axiosClient.get(`${USER_API}/${id}`)
      commit('SET_USER_INFO', response.data)
      return response.data
    } catch (error) {
      return null
    }
  },
  async updateUserInfo({ commit, state, dispatch }, data) {
    try {
      const formData = new FormData()
      Object.entries(data.user).forEach(([key, value]) => {
        if (key === 'profile') {
          Object.entries(value).forEach(([pKey, pValue]) => {
            formData.append('user[profile][' + pKey + ']', pValue)
          })
        } else {
          formData.append('user[' + key + ']', value)
        }
      })
      if (data.password) {
        formData.append('password', data.password)
      }
      if (data.picture) {
        formData.append('picture', data.picture)
      }

      const response = await axiosClient.post(
        `${USER_API}/${data.user.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-HTTP-Method-Override': 'PUT',
          },
        }
      )
      commit('SET_USER_INFO', response.data)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error
      }
      return null
    }
  },

  async fetchAllFavorites({ commit }, params = {}) {
    try {
      let userId = 0
      if (!utils.isEmpty(params.id)) {
        userId = params.id
      }
      delete params.id
      const response = await this.$axios.get(
        `${USER_API}/favorite/${userId}/all`,
        { params }
      )
      return response.data
    } catch (error) {
      return null
    }
  },
  async fetchFavorites({ commit }, params = {}) {
    try {

      let userId = 0
      if (!utils.isEmpty(params.id)) {
        userId = params.id
      }
      delete params.id

      const response = await axiosClient.get(`${USER_API}/favorite/${userId}`, {
        params,
      })

      return response.data
    } catch (error) {
      return null
    }
  },
  async addFavorite({ commit }, params = {}) {
    try {
      const response = await axiosClient.post(`${USER_API}/favorite`, params)
      return response.data
    } catch (error) {
      return null
    }
  },
  async deleteFavorite({ commit }, params = {}) {
    try {
      const response = await axiosClient.delete(`${USER_API}/favorite`, {
        params,
      })
      return response.data
    } catch (error) {
      return null
    }
  },
  async fetchSettingsList({ commit }, payload = {}) {
    try {
      const params = utils.cloneDeep(payload)
      let propertyType
      if (!utils.isEmpty(params.propertyType)) {
        propertyType = params.propertyType
      }
      delete params.propertyType
      const response = await axiosClient.get(
        `${USER_API}/settings/${propertyType}`,
        {
          params,
        }
      )
      return response.data
    } catch (error) {
      return null
    }
  },
  async addSetting({ commit }, params = {}) {
    try {
      const response = await axiosClient.post(`${USER_API}/settings`, params)
      return response.data
    } catch (error) {
      return null
    }
  },
  async updateSetting({ commit }, params = {}) {
    try {
      const response = await axiosClient.put(`${USER_API}/settings`, params)
      return response.data
    } catch (error) {
      return null
    }
  },
  async addNotification({ commit }, params = {}) {
    try {
      const response = await this.$axios.post(
        `${USER_API}/notification`,
        params
      )
      return response.data
    } catch (error) {
      return null
    }
  },

  async fetchUserMissions({ commit }, params = {}) {
    try {
      let userId = 0
      if (!utils.isEmpty(params.id)) {
        userId = params.id
      }
      let confirmed = null
      if (!utils.isEmpty(params.confirmed)) {
        confirmed = params.confirmed
      }

      const userMissions = await this.$axios.get(
        `${USER_API}/mission/${userId}`,
        { params: { confirmed } }
      )
      return userMissions.data
    } catch (error) {
      return null
    }
  },

  async confirmeUserMissions({ commit }, params = {}) {
    try {
      let userId = 0
      if (!utils.isEmpty(params.id)) {
        userId = params.id
      }
      const ids = params.ids

      const response = await this.$axios.put(
        `${USER_API}/mission/confirmed/${userId}`,
        { ids }
      )

      return response
    } catch (error) {
      return null
    }
  },

  async fetchUserInvites({ commit }, params = {}) {
    try {
      let userId = 0
      if (!utils.isEmpty(params.id)) {
        userId = params.id
      }
      let confirmed = null
      if (!utils.isEmpty(params.confirmed)) {
        confirmed = params.confirmed
      }

      const userMissions = await this.$axios.get(
        `${USER_API}/invite/${userId}`,
        {
          params: { confirmed },
        }
      )
      return userMissions.data
    } catch (error) {
      return null
    }
  },

  async confirmeUserInvites({ commit }, params = {}) {
    try {
      let userId = 0
      if (!utils.isEmpty(params.id)) {
        userId = params.id
      }
      const ids = params.ids

      const response = await this.$axios.put(
        `${USER_API}/invite/confirmed/${userId}`,
        { ids }
      )

      return response
    } catch (error) {
      return null
    }
  },

  async fetchInviteCode({ commit }, userId = 0) {
    try {
      const response = await this.$axios.get(`${USER_API}/affiliate/${userId}`)

      return response
    } catch (error) {
      return null
    }
  },

  async registerInvitedCode({ commit }, code) {
    try {
      const response = await this.$axios.post(`${USER_API}/invite`, {
        affiliate_code: code,
      })

      return response
    } catch (error) {
      return null
    }
  },

  async fetchUserProvider({ commit }, params = {}) {
    try {
      const response = await this.$axios.get(
        `${USER_API}/provider/${params.id}`,
        { params: { provider_code: params.provider_code } }
      )
      return response.data
    } catch (error) {
      return null
    }
  },
  async storeUserProvider({ commit }, params = {}) {
    try {
      const response = await this.$axios.post(`${USER_API}/provider`, params)
      return response.data
    } catch (error) {
      if (error.response) {
        return error.response.data
      }
      return error
    }
  },
  async fetchUserAuthentication({ commit }, params = {}) {
    try {
      const response = await this.$axios.get(
        `${USER_API}/authentication/${params.id}`
      )
      return response.data
    } catch (error) {
      return null
    }
  },
  async updateUserAuthenticationLine({ commit }, params = {}) {
    try {
      const response = await this.$axios.put(
        `${USER_API}/authentication/line`,
        params
      )
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error
      }
      return null
    }
  },
  isSubscribedMember({ commit, state }) {
    const grade =
      state.userInfo && state.userInfo.membership_grade
        ? state.userInfo.membership_grade
        : 1
    return (
      grade === this.$utils.CONST.MEMBERSHIP_GRADE_L1 ||
      grade === this.$utils.CONST.MEMBERSHIP_GRADE_L2
    )
  },
  async addVoucher({ commit }, params = {}) {
    try {
      const response = await axiosClient.post(`${USER_CROWDFUNDING_API}`, params)
      return response.data
    } catch (error) {
      return null
    }
  },
  async purchaseVoucher({ commit }, params = {}) {
    const response = await axiosClient.post(`${USER_CROWDFUNDING_API}/buy`, params)
    if (response && response.data && response.data.userData) {
      commit('SET_USER_INFO', response.data.userData)
    }
    return response
  },
  async useVoucher({ commit }, params = {}) {
    try {
      const response = await axiosClient.post(`${USER_CROWDFUNDING_API}/use`, params)
      return response.data
    } catch (error) {
      return null
    }
  },
  async getUserVoucher({ commit, state, dispatch }, id) {
    try {
      const response = await axiosClient.get(`${USER_CROWDFUNDING_API}/${id}`)
      return response.data
    } catch (error) {
      return null
    }
  },
  async getUserVoucherList({ commit }, params = {}) {
    try {
      const response = await axiosClient.get(`${USER_CROWDFUNDING_API}/list`, { params })
      return response.data
    } catch (error) {
      return null
    }
  },
  async getUserVoucherData({ commit }, id) {
    try {
      const response = await axiosClient.get(`${USER_CROWDFUNDING_API}/detail/${id}`)
      return response.data
    } catch (error) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}