<template>
  <body v-bind:style="{ backgroundColor: '#000000' }">
    <BusinessNaviBar />
    <v-sheet
      class="pa-2 ma-0 mx-auto"
      color="transparent"
      max-width="1200"
      rounded="lg"
      width="100%"
      elevation="0"
    >
      <br />
      <v-sheet class="ma-2 mt-0" rounded>
        <v-row justify="center" no-gutters>
          <v-col cols="12" lg="6" md="6">
            <v-img
              :src=bg_img
              :lazy-src="local_logo"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,1)"
              max-height="400"
              class="align-center"
              cover
            >
              <p class="text-h4 mt-4 font-weight-bold text-center text-white">
                {{ $t("busienssPage.beau4biz") }}
              </p>
              <p class="text-body-2 text-white text-center mt-2 pa-4">
                {{ $t("busienssPage.beau4bizDesc") }}
              </p>
            </v-img>
          </v-col>

          <v-col cols="12" lg="6" md="6">
            <p class="text-h6 pa-4">{{ $t("busienssPage.createACtitle") }}</p>
            <v-container class="ma-0">
              <v-form v-model="formValid" @submit.prevent="onSubmit">
                <v-text-field
                  class="pa-2"
                  hide-details="auto"
                  v-model="emailNew"
                  clearable
                  type="email"
                  :label="$t('loginpage.email')"
                  variant="underlined"
                  :readonly="loading"
                  :placeholder="$t('loginpage.your_email')"
                  prepend-icon="mdi-email"
                  :error-messages="v$.emailNew.$errors.map((e) => e.$message)"
                  @input="v$.emailNew.$touch"
                  @blur="v$.emailNew.$touch"
                ></v-text-field>

                <v-btn
                  :ripple="false"
                  class="mt-6 pa-4 text-capitalize"
                  :disabled="!formValid"
                  rounded="pill"
                  :loading="loading"
                  block
                  color="success"
                  size="x-large"
                  type="submit"
                  elevation="0"
                  @click="checkUserEmail"
                >
                  {{ $t("busienssPage.register") }}
                </v-btn>
              </v-form>
            </v-container>
            <div align="center" class="mt-5 mb-5">
              <v-btn-toggle v-model="toggle_exclusive"  density="compact">
                <v-btn
                  variant="text"
                  size="small"
                  :ripple="false"
                  @click="languageOnClick('en')"
                >
                  English
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  :ripple="false"
                  @click="languageOnClick('zhCN')"
                >
                  中文
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </body>
  <VSonner position="top-center" />
</template>

<script>
import BusinessNaviBar from "@/components/BusinessNaviBar.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { useHead } from "@vueuse/head";
import { VSonner, toast } from "vuetify-sonner";
import { useVuelidate } from "@vuelidate/core";
import {
  email,
  required,
  sameAs,
  helpers,
  minLength,
  numeric,
  maxLength,
} from "@vuelidate/validators";

export default {
  components: {
    VSonner,
    BusinessNaviBar
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });

    return { v$: useVuelidate() };
  },

  computed: {
    ...mapState({
      checkEmail: (state) => state.CheckUserEmailStore.property,
      registerInfo: (state) => state.ShopRegisterStore.registerInfo,
    }),
  },

  mounted() {},

  data: () => ({
    formValid: false,
    emailNew: null,
    loading: false,
    checkEmailProperty: null,
    loading: false,
    local_logo: require("@/assets/img/balck120.png"),
    bg_img: require("@/assets/img/bg.webp"),
  }),

  validations: () => ({
    emailNew: {
      required,
      email,
    },
  }),

  methods: {
    ...mapActions({
      parseData: "CheckUserEmailStore/getData",
    }),

    onSubmit() {
      if (!this.formValid) return;
    },

    async checkUserEmail() {
      if (!this.emailNew) return;

      this.loading = true;

      await this.parseData(this.emailNew)
        .then(
          () => {
            this.loading = false;
            this.checkEmailProperty = this.checkEmail;
            if (this.checkEmailProperty.result === 1) {
              toast(this.$i18n.tc("busienssPage.createACtitleAlert"), {
                cardProps: {
                  color: "error",
                  class: "my-toast",
                },
              });
            } else {
              const item = {
                user_email: this.emailNew,
              };

              this.$store
                .dispatch("ShopRegisterStore/updateRegisInfo", item)
                .then((data) => {
                  this.$router.push({
                    name: "ShopRegisterName",
                  });
                })
                .catch((e) => {
                  console.error("error: ", e);
                });
            }
          },
          (error) => {
            this.loading = false;
            toast(
              "Please check your email format. Special characters email unsupported",
              {
                cardProps: {
                  color: "error",
                  class: "my-toast",
                },
              }
            );
            console.error(error);
          }
        )
        .catch((e) => {
          this.loading = false;
          alert("error: ", e);
        });
    },

    languageOnClick(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style scoped>
body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
}
</style>