import {
  REGISTER_API,
  AUTH_API,
  FICKS_ACTIVATE_API,
  SIGNOUT_API,
  WITHDRAWAL_API,
  ONETIME_TOKEN_API,
  CUSTOM_TOKEN_API,
  FICKS_USER_STATUS_API,
} from '@/store/api'


import axiosClient from '@/plugins/axiosClient';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import firebase from '@/plugins/firebase'
const auth = firebase.auth();

const state = () => ({
  user: null, // user firebase
  idToken: null, // idToken from firebase
  item: null, // user from api
  loadingOfItem: false,
  qrToken: null,
  productCode: null,
  serverRedirectUrl: null,
  profile: null,
  profile_img: null,
  trouble_ids: null,
  interest_ids: null,
  isTemporaryRegistration: false,
  migrationUserStatus: 0,
  migrationUserEmail: '',
  migrationUserPassword: null,
  migrationUserActivationToken: null,
  migrationUserId: null,
  migrationUserMailVerification: false,
  migrationUserNewEmail: null,
  migrationUserNewPassword: null,
  isPhoneVerification: false,
  isLineApp: false,
  isTokenUserStored: false,
  isAnonymous: false,
})

const getters = {
  user: (state) => state.user,
  // isAuthenticated: (state) => !!state.item,
  isAuthenticated: (state) => !!state.idToken,
  idToken: (state) => state.idToken,
  item: (state) => state.item,
  loadingOfItem: (state) => state.loadingOfItem,
  qrToken: (state) => state.qrToken,
  productCode: (state) => state.productCode,
  serverRedirectUrl: (state) => state.serverRedirectUrl,
  profile: (state) => state.profile,
  profile_img: (state) => state.profile_img,
  trouble_ids: (state) => state.trouble_ids,
  interest_ids: (state) => state.interest_ids,
  isTemporaryRegistration: (state) => state.isTemporaryRegistration,
  migrationUserStatus: (state) => state.migrationUserStatus,
  migrationUserMailVerification: (state) => state.migrationUserMailVerification,
  migrationUserEmail: (state) => state.migrationUserEmail,
  migrationUserPassword: (state) => state.migrationUserPassword,
  migrationUserActivationToken: (state) => state.migrationUserActivationToken,
  migrationUserId: (state) => state.migrationUserId,
  migrationUserNewEmail: (state) => state.migrationUserNewEmail,
  migrationUserNewPassword: (state) => state.migrationUserNewPassword,
  isPhoneVerification: (state) => state.isPhoneVerification,
  isLineApp: (state) => state.isLineApp,
  isTokenUserStored: (state) => state.isTokenUserStored,
  isAnonymous: (state) => state.isAnonymous,
}

const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  setIdToken(state, payload) {
    state.idToken = payload
  },
  SET_ITEM(state, item) {
    state.item = item
    state.loadingOfItem = false
  },
  SET_QR_TOKEN(state, payload) {
    state.qrToken = payload
  },
  SET_PRODUCT_CODE(state, payload) {
    state.productCode = payload
  },
  SET_SERVER_REDIRECT_URL(state, payload) {
    state.serverRedirectUrl = payload
  },
  SET_PROFILE(state, payload) {
    state.profile = payload
  },
  SET_PROFILE_IMG(state, payload) {
    state.profile_img = payload
  },
  SET_TROUBLE(state, payload) {
    state.trouble_ids = payload
  },
  SET_INTEREST(state, payload) {
    state.interest_ids = payload
  },
  SET_IS_TEMPORARY_REGISTRATION(state, payload) {
    state.isTemporaryRegistration = payload
  },
  SET_MIGRATION_USER_STATUS(state, payload) {
    state.migrationUserStatus = payload
  },
  SET_MIGRATION_USER_EMAIL(state, payload) {
    state.migrationUserEmail = payload
  },
  SET_MIGRATION_USER_PASSWORD(state, payload) {
    state.migrationUserPassword = payload
  },
  SET_MIGRATION_USER_ACTIVATION_TOKEN(state, token) {
    state.migrationUserActivationToken = token
  },
  SET_FICKS_ID(state, id) {
    state.migrationUserId = id
  },
  SET_MIGRATION_USER_MAIL_VERIFICATION(state, status) {
    state.migrationUserMailVerification = status
  },
  SET_MIGRATION_USER_NEW_EMAIL(state, email) {
    state.migrationUserNewEmail = email
  },
  SET_MIGRATION_USER_NEW_PASSWORD(state, password) {
    state.migrationUserNewPassword = password
  },
  SET_IS_PHONE_VERIFICATION(state, isPhoneVerification) {
    state.isPhoneVerification = isPhoneVerification
  },
  SET_IS_LINE_APP(state, isLineApp) {
    state.isLineApp = isLineApp
  },
  SET_IS_TOKEN_USER_STORED(state, isTokenUserStored) {
    state.isTokenUserStored = isTokenUserStored
  },
  SET_IS_ANONYMOUS(state, isAnonymous) {
    state.isAnonymous = isAnonymous
  },
}

const actions = {

  async checkUserLoginState({ commit }) {
    await auth.onAuthStateChanged((user) => {
      if (user) {
        commit('SET_USER', user)
        user.getIdToken().then(async (idToken) => {
          await commit('setIdToken', idToken)
        })
      } else {
        commit('SET_USER', null)
        commit('setIdToken', null)
      }
    });

  },

  signUp({ commit }, { email, password }) {
    return auth.createUserWithEmailAndPassword(email, password)
  },

  sendEmailVerification() {
    return auth.currentUser.sendEmailVerification()
  },

  signInAnonymously() {
    return auth.signInAnonymously()
  },

  signInWithEmail({ commit }, { email, password }) {
    return auth.signInWithEmailAndPassword(email, password)
  },

  signInWithToken({ commit }, customToken) {
    return auth.signInWithCustomToken(customToken)
  },

  signInWithFacebook() {
    const fbProvider = new firebase.auth.FacebookAuthProvider()
    return auth.signInWithPopup(fbProvider)
  },

  signInWithTwitter() {
    const fbProvider = new firebase.auth.TwitterAuthProvider()
    return auth.signInWithPopup(fbProvider)
  },

  signInWithGoogle() {
    const glProvider = new firebase.auth.GoogleAuthProvider()
    return auth.signInWithPopup(glProvider)
  },

  getCredentialWithEmail({ commit }, { email, password }) {
    return firebase.auth.EmailAuthProvider.credential(email, password)
  },

  getCredentialWithPhone({ commit }, { verificationId, code }) {
    return firebase.auth.PhoneAuthProvider.credential(verificationId, code)
  },

  signInWithCredential({ commit }, credential) {
    return auth.signInWithCredential(credential)
  },

  async signOut({ commit }) {
    await axiosClient.post(`${SIGNOUT_API}`)
    return auth.signOut().then(() => {
      commit('SET_ITEM', null)
      commit('SET_USER', null)
    })
  },

  async callSignOutApi() {
    await this.$axios.post(`${SIGNOUT_API}`)
    // eslint-disable-next-line
    return auth.signOut().then(() => { })
  },

  signOutOfirebase() {
    // eslint-disable-next-line
    return auth.signOut().then(() => { })
  },

  setIdToken({ commit }, idToken) {
    commit('setIdToken', idToken)
  },

  setUser({ commit }, data) {
    commit('SET_USER', data)
  },

  setIsTemporaryRegistration({ commit }, data) {
    commit('SET_IS_TEMPORARY_REGISTRATION', data)
  },

  setIsPhoneVerification({ commit }, data) {
    commit('SET_IS_PHONE_VERIFICATION', data)
  },

  setIsLineApp({ commit }, data) {
    commit('SET_IS_LINE_APP', data)
  },

  setIsTokenUserStored({ commit }, data) {
    commit('SET_IS_TOKEN_USER_STORED', data)
  },

  setIsAnonymous({ commit }, data) {
    commit('SET_IS_ANONYMOUS', data)
  },

  async loginWithIdToken({ commit, dispatch }, params) {
    try {

      const response = await thi.$axios.post(`${AUTH_API}`, {
        id_token: params.id_token,
        affiliate_code: params.affiliate_code,
        product_code: params.product_code,
        redirect_url: params.redirect_url,
      })
      // console.log('[authStore] >> loginWithIdToken >> response: ', response.data)
      commit('SET_ITEM', response.data)
      commit('userStore/SET_USER_INFO', response.data.user, { root: true })
      return response.data
    } catch (error) {
      console.log('[authStore]error: ', error)

      return null
    }
  },

  //stanley version(Work)
  async loginWithIdToken2({ commit }, params) {
    const response = await axiosClient
      .post(AUTH_API, params, {
        Authorization: "Bearer " + params.id_token,
      })

    cookies.remove('userToken')

    await cookies.set('userToken', response.data.token)

    if (response.data.user) {
      cookies.set("userId", response.data.user.id,0);
      cookies.set("userName", response.data.user.name,0);
      cookies.set("userEmail", response.data.user.email,0);
      if (response.data.user.profile && response.data.user.profile.phone,0) {
        cookies.set("userPhone", response.data.user.profile.phone,0);
      }
    }

    commit('SET_ITEM', response.data)
    commit('userStore/SET_USER_INFO', response.data.user, { root: true })

    return response.data

    /*
    await axiosClient
      .post(AUTH_API, params, {
        Authorization: "Bearer " + params.id_token,
      })
    .then((res) => {
      console.log('loginWithIdToken2: ', res.data)
      commit('SET_ITEM', res.data)
      // commit('userStore/SET_USER_INFO', res.data.user, { root: true })
      return res.data
    })
    .catch((error) => {
      console.error(error);
      return null
    })
    */
  },


  async getUserStatusWithFicksToken({ commit }, token) {
    const response = await this.$axios.get(`${FICKS_USER_STATUS_API}`, {
      params: {
        ficks_token: token,
      },
    })
    commit('SET_MIGRATION_USER_EMAIL', response.data.email)
    commit('SET_MIGRATION_USER_STATUS', response.data.status)
    commit('SET_FICKS_ID', response.data.ficks_id)
    commit(
      'SET_MIGRATION_USER_MAIL_VERIFICATION',
      response.data.mail_verification
    )
    return response.data
  },

  async getUserStatusWithEmailAndPassword({ commit }, account) {
    const email = account.email
    const password = account.password
    const response = await this.$axios.get(`${FICKS_USER_STATUS_API}`, {
      params: {
        email,
        password,
      },
    })
    commit('SET_MIGRATION_USER_EMAIL', email)
    commit('SET_MIGRATION_USER_PASSWORD', password)
    commit('SET_MIGRATION_USER_STATUS', response.data.status)
    commit('SET_FICKS_ID', response.data.ficks_id)
    commit(
      'SET_MIGRATION_USER_MAIL_VERIFICATION',
      response.data.mail_verification
    )
    return response.data
  },

  async activateFicksAccount({ state, commit }, account) {
    const email = account.email
    const password = state.migrationUserPassword
    const newPassword = account.password
    const migrationUserId = state.migrationUserId
    const redirectUrl = account.redirectUrl

    const response = await this.$axios.post(`${FICKS_ACTIVATE_API}`, {
      email,
      password,
      new_password: newPassword,
      ficks_id: migrationUserId,
      redirect_url: redirectUrl,
    })
    commit('SET_MIGRATION_USER_NEW_EMAIL', email)
    commit('SET_MIGRATION_USER_NEW_PASSWORD', newPassword)
    commit('SET_MIGRATION_USER_STATUS', response.data.status)
    commit(
      'SET_MIGRATION_USER_MAIL_VERIFICATION',
      response.data.mail_verification
    )
    return response.data
  },

  async registerWithIdToken({ commit, dispatch }, data) {
    try {
      let formData = new FormData()
      if (data) {
        formData = this.$utils.appendUserProfileData(data, formData)
      }
      if (data.picture) {
        formData.append('picture', data.picture)
      }
      const response = await this.$axios.post(`${REGISTER_API}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-HTTP-Method-Override': 'POST',
        },
      })
      return response.data
    } catch (error) {
      return null
    }
  },

  async withdrawal({ commit }) {
    const response = await this.$axios.delete(`${WITHDRAWAL_API}`)
    commit('SET_ITEM', null)
    commit('SET_USER', null)
    commit('setIdToken', null)
    return response.data
  },

  async getOneTimeToken({ commit }, userId) {
    try {
      const response = await this.$axios.get(`${ONETIME_TOKEN_API}/${userId}`)
      commit('SET_QR_TOKEN', response.data.token)
      return response.data
    } catch (error) {
      return null
    }
  },

  async getCustomToken({ commit }, params) {
    try {
      const response = await this.$axios.get(
        `${CUSTOM_TOKEN_API}/${params.site}`,
        {
          params: {
            code: params.code,
            redirect_path: params.redirect_path,
          },
        }
      )
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error
      }
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
