<template>
  <v-sheet class="pa-0 mt-0" v-if="shops != null">
    <br />
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-store"
        color="blue-darken-1"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t("homeview.shop_area") }}
    </p>

    <v-container class="pa-0 mt-4">
      <v-row no-gutters warp justify="center" class="d-flex">
        <v-col v-for="shop in shops" :key="shop" cols="12" md="4">
          <ShopCardWithAI
            :shopObjects="shop"
            @showThumbImage="showThumbImageEmitEvent"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>

  <v-dialog
    v-model="showThumbImage"
    :max-width="isMobileDevice.value ? '90%' : '80%'"
  >
    <Carousel :starting-image="0" :images="shopPictures" />
  </v-dialog>
</template>

<script>
import utils from "@/uitls/Uitls.js";
import Constants from "@/uitls/Constants";
import ShopCardWithAI from "@/components/ShopCardWithAI.vue";
import Carousel from "@/uitls/Carousel.vue";

export default {
  props: {
    coordinates: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ShopCardWithAI,
    Carousel,
  },

  setup(props) {},

  async created() {
    if (this.coordinates != null) {
      this.loadNearbyLocationShop(this.coordinates);
    }
  },

  data() {
    return {
      shops: null,
      bot_logo_src: require("@/assets/img/robot_120.png"),
      showThumbImage: false,
      shopPictures: [],
    };
  },

  methods: {
    async loadNearbyLocationShop(coordinates) {
      const params = {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        page: 1,
        per_page: 7,
        exclude_shop_id: coordinates.shop_id ? coordinates.shop_id : null,
      };

      await this.$store
        .dispatch("shopStore/locationSearchShop", params)
        .then((response) => {
          if (response.data.length == 0) {
            console.log("ShopNearByShop no shop was found!!!");
            return;
          }

          const shopData = [];
          response.data.forEach((shop, index) => {
            if (shop.status) {
              var shop_img = Constants.NO_IMAGE;
              if (shop.pictures.length > 0) {
                shop_img = shop.pictures[0].picture_url_small;
              }

              //skip own shop
              if (index != 0) {
                shopData.push(utils.setShopDetails(shop, shop_img));
              }
            }
          });
          this.shops = shopData;
          // console.log("  this.nearShops: %o", this.shops[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    showThumbImageEmitEvent(value) {
      const result = this.shops.find(({ shop_id }) => shop_id === value);

      if (result) {
        this.showThumbImage = true;

        this.shopPictures = [];

        for (let i = 0; i < result.shop_img_list.length; i++) {
          this.shopPictures.push({
            id: i,
            big: result.shop_img_list[i].picture_url,
            thumb: result.shop_img_list[i].picture_url_small,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
</style>