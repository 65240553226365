<template>
  <br />
  <v-sheet class="pa-0 ma-0">
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-book"
        color="blue-darken-1"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t("homeview.hair_catalogs") }}
    </p>

    <p class="text-h6 mx-6 mt-4">{{ $t("haircatalog.female_calalog") }}</p>
    <v-row no-gutters warp justify="center" class="d-flex">
      <v-col
        v-for="card in cards_female"
        :key="card.title"
        cols="6"
        lg="3"
        md="2"
      >
        <router-link
          :to="{
            name: 'ModelsPhotoListView',
            params: {
              gender: 0,
              hairtype: card.title,
            },
          }"
          style="text-decoration: none; color: inherit"
        >
          <v-card class="ma-3">
            <v-img :src="card.src"> </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <p class="text-h6" style="font-weight: bold">
                {{ card.title }}
              </p>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </router-link>
      </v-col>
    </v-row>

    <p class="mt-8 text-h6 mx-6">{{ $t("haircatalog.male_calalog") }}</p>
    <v-row no-gutters warp justify="center" class="d-flex">
      <v-col
        v-for="card in cards_male"
        :key="card.title"
        cols="6"
        lg="3"
        md="2"
      >
        <router-link
          :to="{
            name: 'ModelsPhotoListView',
            params: {
              gender: 1,
              hairtype: card.title,
            },
          }"
          style="text-decoration: none; color: inherit"
        >
          <v-card class="ma-3">
            <v-img :src="card.src"> </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <p class="text-h6" style="font-weight: bold">
                {{ card.title }}
              </p>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      imageList: ["g1.jpg", "g2.jpg", "g3.jpg", "g4.jpg", "g5.jpg"],
      image2List: ["m1.jpg", "m2.jpg", "m3.jpg", "m4.jpg", "m5.jpg"],
      cards_female: [
        {
          title: this.$i18n.tc("haircatalog.short"),
          src: "girl_short_hair.png",
          flex: 3,
        },
        {
          title: this.$i18n.tc("haircatalog.medium"),
          src: "girl_medium_hair.jpeg",
          flex: 3,
        },

        {
          title: this.$i18n.tc("haircatalog.semi_long"),
          src: "girl_smilong_hair.jpeg",
          flex: 3,
        },
        {
          title: this.$i18n.tc("haircatalog.long"),
          src: "girl_long_hair.jpeg",
          flex: 3,
        },
      ],
      cards_male: [
        {
          title: this.$i18n.tc("haircatalog.very_short"),
          src: "male_veryshort_hair.jpeg  ",
          flex: 3,
        },
        {
          title: this.$i18n.tc("haircatalog.short"),
          src: "man_short4_hair.jpeg",
          flex: 3,
        },
        {
          title: this.$i18n.tc("haircatalog.medium"),
          src: "man_long_hair.jpeg",
          flex: 3,
        },
        {
          title: this.$i18n.tc("haircatalog.long"),
          src: "man_medium2_hair.jpeg",
          flex: 3,
        },
      ],
    };
  },
};
</script>

<style></style>