<template>
  <v-container>
    <v-row no-gutters>
      <v-col
        v-for="item in locationTags()"
        :key="item.value"
        cols="6"
        md="4"
        sm="2"
      >
        <v-card
          class="mx-auto pa-2"
          max-width="480"
          height="200"
          elevation="0"
          @click="cardOnClick(item)"
        >
          <v-img
            :src="item.img"
            class="align-center"
            gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.8)"
            height="200px"
            cover
          >
            <v-card-title class="text-white text-center text-h5">
              {{ $t(item.title) }}
            </v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import utils from "@/uitls/Uitls";
export default {

  data() {
    return {
      locationTags: utils.locationTags,
    };
  },

  methods: {
    cardOnClick(value) {
      this.$emit("callBackFromAreaCard", value);
    },
  },
};
</script>

<style></style>