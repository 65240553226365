
const API_DOMAIN_URL = "https://api.beauty-360.com";
const API_ACCESS_TOKEN = "haha-not-using";
const ARTICLE_URL = "https://media.beauty-360.com/wp-json/wp/v2";
const ARTICLE_ZH_URL = "https://media.beauty-360.com/zh/wp-json/wp/v2";
const ARTICLE_MS_URL = "https://media.beauty-360.com/ms/wp-json/wp/v2";
const BEAUTY_LOGO = "https://beauty-360.com/img/newlogo.94b59c11.png";
const NO_IMAGE = "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
const BEAUTY_360_PRODUCT_ID = "1";
const FAVORITE_TYPE_ARTICLE = "1";
const FAVORITE_TYPE_CUPON = "2";
const FAVORITE_TYPE_SHOP = "3";
const FAVORITE_TYPE_STAFF = "4";
const MAIL_DELIVERY_SETTING_TYPE = "1";
const IMAGE_URL = "https://storage.googleapis.com/beauty360-dev/";
const GOOGLE_MAP_API = "AIzaSyBANOoSag9EyDK5i8n1wF1PQsDFH3LOpAU";
const WHATSAPP = "https://wa.me/message/JYYGE5SKNUKKK1";


export default {
  API_DOMAIN_URL: API_DOMAIN_URL,
  API_ACCESS_TOKEN: API_ACCESS_TOKEN,
  ARTICLE_URL: ARTICLE_URL,
  ARTICLE_ZH_URL: ARTICLE_ZH_URL,
  ARTICLE_MS_URL: ARTICLE_MS_URL,
  BEAUTY_360_PRODUCT_ID: BEAUTY_360_PRODUCT_ID,
  FAVORITE_TYPE_ARTICLE: FAVORITE_TYPE_ARTICLE,
  FAVORITE_TYPE_CUPON: FAVORITE_TYPE_CUPON,
  FAVORITE_TYPE_SHOP: FAVORITE_TYPE_SHOP,
  FAVORITE_TYPE_STAFF: FAVORITE_TYPE_STAFF,
  MAIL_DELIVERY_SETTING_TYPE: MAIL_DELIVERY_SETTING_TYPE,
  BEAUTY_LOGO: BEAUTY_LOGO,
  NO_IMAGE: NO_IMAGE,
  IMAGE_URL:IMAGE_URL,
  GOOGLE_MAP_API:GOOGLE_MAP_API,
  WHATSAPP:WHATSAPP,
}