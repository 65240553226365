
const GFDataShopRegist = {

  GFDocUrl() {
    return 'https://docs.google.com/forms/d/e/1FAIpQLSdXoDuGsG5c4ZFDIXDZFDDxd7N1x2ExqbyM7sQA_Nxr8hUfog/formResponse'
  },

  GFDocSurvey() {
    return [
      {
        name: 2083441746,
        question: 'email',
      },
      {
        name: 1866929616,
        question: 'your_name',
      },
      {
        name: 2036114990,
        question: 'password',
      },
      {
        name: 1232183528,
        question: 'business_name',
      },
      {
        name: 399589710,
        question: 'website',
      },
      {
        name: 803276868,
        question: 'Service do you offer(Mail)',
      },
      {
        name: 66800836,
        question: 'Service do you offer(Sub)',
      },
      {
        name: 696000910,
        question: 'teamsize',
      },
      {
        name: 854556534,
        question: 'location',
      },
      {
        name: 79650969,
        question: 'address',
      },
      {
        name: 778257119,
        question: 'package',
      },
      {
        name: 1963115898,
        question: 'how beauty',
      },
    ];
  },
}

export default GFDataShopRegist