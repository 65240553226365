<!-- https://github.com/AhlamKhalid/Cart-Vue-3 -->
<template>
  <v-sheet class="pa-0 ma-0 text-center mx-auto" color="white">
    <v-card
      class="mx-auto"
      color="yellow"
      :title="$t('shop.comingSoon')"
      :subtitle="$t('shop.underConstruction')"
      prepend-icon="mdi-alert"
      v-if="debugMode"
    >
    </v-card>
    <Product
      v-for="product in products"
      :key="product.id"
      :product="product"
      :showActionBtn="true"
      @remove-item="openDialog = true"
      @decrement-quantity="decrementQuantity"
      @increment-quantity="incrementQuantity"
    />
  </v-sheet>

  <!-- footer -->
  <v-sheet
    :class="
      isMobileDevice.value
        ? 'cart-footer-mobile mt-12 pa-2'
        : 'cart-footer-pc mt-12 pa-2'
    "
  >
    <p class="text-h5 pa-2" style="font-weight: bold">
      {{ $t("crowdfundingsCheckout.yourCartTitle") }}
    </p>

    <v-row>
      <v-col cols="12">
        <v-sheet class="mt-0" color="transparent">
          <td class="tdLeft">
            <p class="text-black text-body-1 mt-6 mx-4">
              {{ $t("crowdfundingsCheckout.totalItemTitle") }}
            </p>
          </td>
          <td class="tdRight text-body-1 text-black">
            {{
              $t("crowdfundingsCheckout.totalItemCountLabel", {
                itemCount: numCartItems,
              })
            }}
          </td>

          <br />
          <td class="tdLeft">
            <p class="text-black text-body-1 mx-4">
              {{ $t("crowdfundingsCheckout.totalAmountTitle") }}
            </p>
          </td>
          <td class="tdRight text-body-1 text-black">RM{{ cartTotal }}</td>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-btn
          class="text-h6 mt-6 mb-6"
          block
          color="green"
          height="60"
          @click="openBottomSheet"
        >
          {{ $t("crowdfundingsCheckout.proceedToCheckOutTitle") }}
        </v-btn>
      </v-col>
    </v-row>

    <vue-bottom-sheet
      ref="myBottomSheet"
      :max-width="isMobileDevice.value ? 620 : 960"
      max-height="100%"
      transition-duration="0.25"
      :can-swipe="true"
      :overlay="true"
    >
      <v-sheet class="ma-4 mt-n1">
        <p class="text-h5">
          {{ $t("crowdfundingsCheckout.confirmPurchaseTitle") }}
        </p>
        <br />

        <v-form @submit.prevent v-model="paymentForm">
          <v-text-field
            prepend-icon="mdi-account"
            v-model="paymentData.name"
            type="text"
            label="Your Name"
            :rules="[(value) => !!value || 'Required Feild']"
          >
          </v-text-field>

          <v-text-field
            prepend-icon="mdi-email"
            v-model="paymentData.email"
            type="email"
            label="Your Email"
            :rules="[(value) => !!value || 'Required Feild']"
          >
          </v-text-field>

          <v-text-field
            prepend-icon="mdi-phone"
            v-model="paymentData.phone"
            type="text"
            label="Your mobile-number"
            :rules="[(value) => !!value || 'Required Feild', phoneNumberRules]"
          >
          </v-text-field>

          <v-sheet class="mt-6">
            <p class="text-h5">
              {{ $t("crowdfundingsCheckout.orderSummaryTitle") }}
            </p>
            <td class="tdLeft">
              <p class="text-black text-body-1 mt-6 mx-4">
                {{ $t("crowdfundingsCheckout.cartSubtotalTitle") }}
              </p>
            </td>
            <td class="tdRight text-body-1 text-black">
              {{
                $t("crowdfundingsCheckout.totalItemCountLabel", {
                  itemCount: numCartItems,
                })
              }}
            </td>

            <br />
            <td class="tdLeft">
              <p class="text-black text-body-1 mx-4">
                {{ $t("crowdfundingsCheckout.totalAmountTitle") }}
              </p>
            </td>
            <td class="tdRight text-body-1 text-black">RM{{ cartTotal }}</td>
          </v-sheet>

          <v-btn
            :disabled="!paymentForm"
            block
            class="mt-6"
            color="green"
            height="60"
            @click="proceedCheckOut"
          >
            {{ $t("crowdfundingsCheckout.checkoutBtnTitle") }}
          </v-btn>
        </v-form>
      </v-sheet>
    </vue-bottom-sheet>
  </v-sheet>

  <v-dialog v-model="openDialog" width="auto">
    <v-card>
      <v-card-text>
        {{ $t("crowdfundingsCheckout.confirmQuitPurchaseDialogTitle") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" @click="openDialog = false">
          {{ $t("crowdfundingsCheckout.confirmQuitPurchaseDialogBtnNo") }}
        </v-btn>
        <v-btn color="black" @click="backToHome">
          {{ $t("crowdfundingsCheckout.confirmQuitPurchaseDialogBtnYes") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
      >
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>

  <VSonner position="top-center" />
</template>

<script>
import { VSonner, toast } from "vuetify-sonner";
import { mapGetters } from "vuex";
import Constants from "@/uitls/Constants.js";
import Product from "@/components/Product.vue";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";

export default {
  components: {
    Product,
    VSonner,
    VueBottomSheet,
  },
  props: ["crowdfundingId", "crowdfundingItemId"],
  computed: {
    ...mapGetters({
      userInfo: "userStore/userInfo",
    }),
    numCartItems() {
      let result = 0;

      if (this.products.length > 0) {
        this.products.forEach((v) => {
          result += v.quantity;
        });
      }

      return result;
    },
    cartTotal() {
      let result = 0;

      if (this.products.length > 0) {
        this.products.forEach((v) => {
          if (
            v.is_free != undefined &&
            v.is_free == 0 &&
            v.selling_price != undefined &&
            parseFloat(v.selling_price) > 0
          ) {
            result += v.quantity * v.selling_price;
          }
        });
      }

      return result;
    },
  },

  data() {
    return {
      debugMode: true,
      loadingDialog: true,
      bottomSheet: false,
      openDialog: false,
      paymentForm: false,
      paymentData: {
        name: null,
        email: null,
        phone: null,
      },
      products: [],
    };
  },

  async mounted() {
    window.scrollTo(0, 0);
    this.paymentData.name = this.userInfo ? this.userInfo.name : null;
    this.paymentData.email = this.userInfo ? this.userInfo.email : null;
    this.paymentData.phone =
      this.userInfo && this.userInfo.profile
        ? this.userInfo.profile.phone
        : null;
    await this.fetchCrowd();
    this.loadingDialog = false;
  },

  methods: {
    phoneNumberRules(value) {
      if (!value) {
        return true;
      }
      return /^[0-9]*$/.test(value);
    },

    decrementQuantity(product_id) {
      const index = this.products.findIndex((item) => item.id == product_id);
      if (index >= 0 && this.products[index]) {
        if (this.products[index].quantity == 1) {
          this.openDialog = true;
        } else {
          this.products[index].quantity--;
        }
      }
    },
    incrementQuantity(product_id) {
      const index = this.products.findIndex((item) => item.id == product_id);
      if (index >= 0 && this.products[index]) {
        if (this.products[index].quantity == this.products[index].amount) {
          toast(this.$i18n.t("crowdfundingsCheckout.overLimitToastTitle"), {
            cardProps: {
              color: "red",
              class: "my-toast",
            },
          });
        } else {
          this.products[index].quantity++;
        }
      }
    },
    openBottomSheet() {
      this.$refs.myBottomSheet.open();
    },
    backToHome() {
      this.$router.push({ name: "HomeView" });
    },
    async fetchCrowd() {
      this.loadingDialog = true;
      await this.$store
        .dispatch("crowdStore/fetchCrowd", this.crowdfundingId)
        .then(async (response) => {
          if (
            response &&
            response.crowdfunding_items &&
            response.crowdfunding_items.length > 0
          ) {
            const crowdfunding_items = response.crowdfunding_items.find(
              (item) => item.id == this.crowdfundingItemId
            );
            if (
              !crowdfunding_items ||
              crowdfunding_items.is_free ||
              crowdfunding_items.selling_price == 0
            ) {
              await this.showInvalidVoucher();
              return;
            }
            this.products = [
              {
                id: crowdfunding_items.id,
                crowdfunding_id: crowdfunding_items.crowdfunding_id,
                img: new URL(
                  Constants.IMAGE_URL + crowdfunding_items.picture_path
                ).href,
                name: crowdfunding_items.name,
                price: crowdfunding_items.price,
                original_price: crowdfunding_items.original_price,
                description: crowdfunding_items.description,
                quantity: 1,
                amount: crowdfunding_items.amount,
                discount_rate: crowdfunding_items.discount_rate,
                is_free: crowdfunding_items.is_free,
                selling_price: crowdfunding_items.selling_price,
              },
            ];
          } else {
            await this.showInvalidVoucher();
            return;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },

    async proceedCheckOut() {
      this.$refs.myBottomSheet.close();
      this.loadingDialog = true;
      let tobeSubmit = JSON.parse(JSON.stringify(this.paymentData));
      tobeSubmit["product_list"] = this.products;
      await this.$store
        .dispatch("userStore/purchaseVoucher", tobeSubmit)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.crowdfundingSalesorders
          ) {
            if (
              response.data.crowdfundingSalesorders.id &&
              parseFloat(response.data.crowdfundingSalesorders.totalamount) > 0
            ) {
              const paymentURL =
                Constants.API_DOMAIN_URL +
                "/api/v1/user/crowdfunding/checkout_payment/" +
                response.data.crowdfundingSalesorders.id;
              // window.open(paymentURL, '_blank')
              window.location.href = paymentURL;
            } else {
              this.goToUseVoucher();
            }
          }
        })
        .catch((e) => {
          if (e && e.response && e.response.data && e.response.data.error) {
            switch (e.response.data.error) {
              case "exist-crowdfunding-item":
                this.goToUseVoucher();
                break;
              default:
                this.backToHome();
                break;
            }
          } else {
            console.error(e);
            this.backToHome();
          }
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },

    showInvalidVoucher(backToHomeFlag = true) {
      toast(this.$i18n.t("crowdfundingsCheckout.voucherNotFoundToastTitle"), {
        cardProps: {
          color: "red",
          class: "my-toast",
        },
        onDismiss: () => {
          if (backToHomeFlag) {
            this.backToHome();
          }
        },
        onAutoClose: () => {
          if (backToHomeFlag) {
            this.backToHome();
          }
        },
      });
    },
    goToUseVoucher() {
      this.$router.push({
        name: "UseVoucher",
        params: { crowdfundingItemId: this.crowdfundingItemId, shopId: "" },
      });
    },
    backToHome() {
      this.$router.push({ name: "HomeView" });
    },
  },
};
</script>

<style scoped>
/* footer */
.cart-footer-pc {
  height: 280px;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0 30px 20px;
  box-shadow: 0 -6px 20px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.cart-footer-mobile {
  height: 280px;
  width: 100%;
  bottom: 0;
  padding: 0 30px 20px;
  box-shadow: 0 -6px 20px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.tdLeft {
  width: 100%;
  min-width: 200px;
  table-layout: fixed;
  text-align: left;
  /* border: 1px solid black; */
  padding: 0px;
}

.tdRight {
  table-layout: fixed;
  min-width: 100px;
  text-align: right;
  /* border: 1px solid black; */
  padding: 0px;
}

.divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0;
}

.divider::after {
  content: "";
  height: 0.5px;
  background: lightgray;
  flex: 1;
  margin: 0em 0.5em 0em 1em;
}
</style>