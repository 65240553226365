<template>
  <div class="autocomplete">
    <v-text-field
      variant="outlined"
      @input="onChange"
      @click="onClickInput"
      v-model="search"
      @keydown.enter="onChange"
    >
      <v-field-label style="font-weight: normal" class="text-caption mx-n2">
        {{ $t("homeview.search_keyword") }}
      </v-field-label>
    </v-text-field>

    <!-- <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
      <li class="loading" v-if="isLoading">
        <div align="center" class="mt-6">
          <v-progress-circular indeterminate color="blue"></v-progress-circular>
        </div>
      </li>

      <li
        v-else-if="!isLoading && results.length > 0"
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
      >
        <SearchAutocompleteResultDiv :item="result">
        </SearchAutocompleteResultDiv>
      </li>
      <li v-else class="autocomplete-result">
        {{ "No result" }}
      </li>
    </ul> -->
  </div>

  <v-card class="mx-auto mt-n4" v-show="isOpen">
    <v-list>
      <v-list-item v-if="isLoading">
        <v-list-item-content>
          <div align="center" class="mt-0">
            <v-progress-circular
              indeterminate
              color="blue"
            ></v-progress-circular>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-else-if="!isLoading && results.length > 0"
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
      >
        <v-list-item-content>
          <SearchAutocompleteResultDiv :item="result" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-else class="autocomplete-result">
        <v-list-item-content>
          <p style="font-weight: normal" class="text-caption text-grey">
            {{ $t("searchAutocompleteResultDiv.noResult") }}
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import SearchAutocompleteResultDiv from "@/components/SearchAutocompleteResultDiv.vue";

export default {
  name: "SearchAutocomplete",
  components: {
    SearchAutocompleteResultDiv,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputHint: {
      type: String,
      default: "",
    },
    inputLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      results: [],
      search: null,
      isLoading: false,
      prevSearch: null,
    };
  },
  watch: {
    items: function (value, oldValue) {
      this.results = value;
      this.isOpen = true;
      this.isLoading = false;
      this.prevSearch = this.search;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    setResult(result) {
      this.isOpen = false;
      this.$emit("setAutocompleteResult", result);
    },

    filterResults() {
      this.results = this.items.filter((item) => {
        return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
      console.log(this.results);
    },

    onChange() {
      // if (this.prevSearch && this.prevSearch === this.search) {
      //   return
      // }
      if (this.search.length > 0) {
        this.$emit("updateAutocompleteItemList", this.search);
        this.isOpen = true;
        if (this.isAsync) {
          this.isLoading = true;
        } else {
          this.filterResults();
        }
      } else {
        this.isOpen = false;
        this.isLoading = false;
      }
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },

    onClickInput() {
      if (!this.isLoading && !this.isOpen && this.results.length > 0) {
        this.isOpen = true;
      }
    },
  },
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  overflow: auto;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: white;
  height: 200px;
  overflow-y: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: rgb(219, 231, 255);
  color: black;
}
</style>