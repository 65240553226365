<template>
  <v-sheet class="mx-auto" elevation="0" max-width="1200">
    <br />
    <v-list-item class="mt-0">
      <template v-slot:prepend>
        <v-img
          :src="pin_logo_src"
          :width="30"
          alt="Beauty360 recommend"
        ></v-img>
      </template>
      <div class="d-flex align-center justify-space-between">
        <h2 class="text-h6 mx-2" style="font-weight: bold">
          {{ title }}
        </h2>
        <v-btn
          size="small"
          class="text-caption text-black"
          elevation="0"
          variant="text"
          :ripple="false"
          @click="moreOnClick()"
        >
          {{ $t("homeview.show_more") }}
        </v-btn>
      </div>
    </v-list-item>

    <v-slide-group v-if="shops.length > 0" class="pa-0">
      <v-slide-group-item v-for="shopObjects in shops" :key="shopObjects.id">
        <router-link
          :to="{
            name: 'Shop',
            params: { id: shopObjects.shop_id, openPage: '1', scrollPage: '0' },
          }"
          style="text-decoration: none; color: inherit"
        >
          <v-card
            class="pa-0 ma-2"
            elevation="1"
            :ripple="false"
            max-width="320"
          >
            <v-row no-gutters v-if="shopObjects.shop_img_list.length > 1">
              <v-col cols="6">
                <v-img
                  :lazy-src="local_shop"
                  :src="shopObjects.shop_img_small"
                  max-width="100%"
                  :height="140"
                  cover
                  :alt="shopObjects.shop_title"
                >
                  <v-col
                    class="ma-2 text-left"
                    v-if="
                      shopObjects.shop_img_list !== undefined &&
                      shopObjects.shop_img_list.length > 1
                    "
                  >
                    <v-btn
                      elevation="2"
                      color="black"
                      rounded="lg"
                      :ripple="false"
                      prepend-icon="mdi-image"
                      @click.prevent="imageOnClick()"
                    >
                      + {{ shopObjects.shop_img_list.length }}
                    </v-btn>
                  </v-col>
                </v-img>
              </v-col>

              <v-col cols="6">
                <v-img
                  :lazy-src="local_shop"
                  :src="shopObjects.shop_img_list[1].picture_url_small"
                  max-width="100%"
                  :height="140"
                  cover
                  :alt="shopObjects.shop_title"
                />
              </v-col>
            </v-row>

            <v-row no-gutters v-else>
              <v-col cols="12">
                <v-img
                  :lazy-src="local_shop"
                  :src="shopObjects.shop_img_small"
                  max-width="100%"
                  :height="140"
                  cover
                  :alt="shopObjects.shop_title"
                >
                </v-img>
              </v-col>
            </v-row>

            <div
              class="ribbon ribbon-top-right"
              v-if="
                shopObjects.shop_crowdfundings !== undefined &&
                shopObjects.shop_crowdfundings.length > 0
              "
            >
              <span class="text-body-1">{{ $t("homeview.voucher") }}</span>
            </div>

            <v-card-title>
              <v-icon
                v-if="shopObjects.shop_is_official"
                icon="mdi-check-decagram"
                size="18"
                color="blue"
                class="mt-n1"
              />
              {{ shopObjects.shop_title }}
            </v-card-title>

            <v-card-subtitle class="mt-n3">
              <v-icon icon="mdi-map-marker" size="14" color="black" />
              <span
                class="text-black mx-2"
                style="font-size: 13px; font-weight: normal"
              >
                {{ getShopPrefectureTitle(shopObjects) }}
              </span>
            </v-card-subtitle>

            <v-list-item class="mt-2">
              <template v-slot:prepend>
                <v-img :src="bot_logo_src" :width="20" alt="" />
              </template>
              <p class="text-body-2 mx-2 mt-1" style="font-weight: bold">
                {{ $t("shop.about_this_shop") }}
              </p>
            </v-list-item>

            <div v-if="!checkEmpty(shopObjects.ai_review_short)">
              <v-card-text
                class="mt-n5 text-black"
                style="white-space: pre-line"
                v-if="this.$i18n.locale == 'zhCN'"
              >
                <div v-snip="{ lines: 2 }" style="font-weight: normal">
                  {{ shopObjects.ai_review_short_zhcn }}
                </div>
              </v-card-text>

              <v-card-text
                class="mt-n5 text-black"
                style="white-space: pre-line"
                v-else-if="this.$i18n.locale == 'ms'"
              >
                <div v-snip="{ lines: 2 }" style="font-weight: normal">
                  {{ shopObjects.ai_review_short_ms }}
                </div>
              </v-card-text>

              <v-card-text
                class="mt-n5 text-black"
                style="white-space: pre-line"
                v-else
              >
                <div v-snip="{ lines: 2 }" style="font-weight: normal">
                  {{ shopObjects.ai_review_short }}
                </div>
              </v-card-text>
            </div>

            <div v-else>
              <v-card-text
                class="mt-n5 text-black"
                style="white-space: pre-line"
              >
                {{ $t("shop.noShopDetails") }}
              </v-card-text>
            </div>
          </v-card>
        </router-link>
      </v-slide-group-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import Constants from "@/uitls/Constants";
import Uitls from "@/uitls/Uitls";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  props: {
    parseMode: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    title: "",
    params: {},
    shops: [],
    showThumbImage: false,
    shopPictures: [],
    local_shop: require("@/assets/img/logo120.png"),
    bot_logo_src: require("@/assets/img/robot_120.png"),
    pin_logo_src: require("@/assets/img/pin.png"),
  }),

  async mounted() {
    switch (this.parseMode) {
      case "kl_recommend":
        this.title = this.$i18n.t("prefecture.kl");

        this.params = {
          product_id: Constants.BEAUTY_360_PRODUCT_ID,
          page: 1,
          per_page: 8,
          sort: "id",
          allow_ads: 1,
          prefecture_v2: 113,
        };
        break;

      case "jb_recommend":
        this.title = this.$i18n.t("city.countryPrefectureCityJohorBahru");

        this.params = {
          product_id: Constants.BEAUTY_360_PRODUCT_ID,
          page: 1,
          per_page: 8,
          sort: "id",
          allow_ads: 1,
          prefecture_v2: 100,
          city_id: 1,
        };
        break;

      case "new_shop":
        this.title = this.$i18n.t("slideGroup.newShop");

        this.params = {
          product_id: Constants.BEAUTY_360_PRODUCT_ID,
          page: 1,
          per_page: 8,
          sort: "-id",
          allow_ads: 0,
        };
        break;
      default:
        break;
    }

    await this.$store
      .dispatch("shopStore/fetchShops", this.params)
      .then((response) => {
        const shopData = [];

        response.data.forEach((shop) => {
          if (shop.status) {
            var shop_img_small = Constants.NO_IMAGE;
            if (shop.pictures.length > 0) {
              shop_img_small = shop.pictures[0].picture_url_small;
            }
            shopData.push(Uitls.setShopDetails(shop, shop_img_small));
          }
        });
        this.shops = shopData;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    checkEmpty(obj) {
      return Uitls.isEmpty(obj) ? true : false;
    },

    getShopPrefectureTitle(value) {
      if (value.shop_city.code != null && value.shop_city != null) {
        return (
          this.$i18n.t(Uitls.getShopPrefectureTitle(value)) +
          ", " +
          this.$i18n.t("city." + value.shop_city.code)
        );
      } else {
        return this.$i18n.t(Uitls.getShopPrefectureTitle(value));
      }
    },

    moreOnClick() {
      let searchFilterObjectsx = {};

      if (this.parseMode == "new_shop") {
        cookies.set("sortType", "-id", 60 + 60);
        searchFilterObjectsx = {
          searchKeyword: "",
          priceRange: [0, 1000],
          sort: "-id",
        };
      } else {
        cookies.set("sortType", "id", 60 + 60);
        searchFilterObjectsx = {
          searchKeyword: "",
          priceRange: [0, 1000],
          sort: "id",
        };

        if (this.params.prefecture_v2 != null) {
          searchFilterObjectsx.prefecture_v2 = this.params.prefecture_v2;

          if (this.params.city_id != null) {
            searchFilterObjectsx.city_id = this.params.city_id;
          }
        }
      }

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjectsx) },
      });
    },
  },
};
</script>

<style scoped>
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid red;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  background-color: red;
  color: #fff;
  font: 500 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 22px;
}

.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}

.ribbon-top-right span {
  left: -27px;
  top: 29px;
  transform: rotate(45deg);
}
</style>