import { CROWDFUNDING_SALESORDER_API } from '@/store/api'
import axios from 'axios'
import axiosClient from '@/plugins/axiosClient';
import Constants from '@/uitls/Constants'


const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + Constants.API_ACCESS_TOKEN,
  }
};

const state = {
  soList: null,
  soDetail: null
};

const getters = {
  getSoList: state => {
    return state.soList;
  },
  getSoDetail: state => {
    return state.soDetail;
  }
};

const mutations = {
  SET_DATA: (state, data) => {
    state.soList = data;
  },
  SET_DATA2: (state, data) => {
    state.soDetail = data;
  },
};

const actions = {
  async getData({ commit }) {
    const baseURL = Constants.API_DOMAIN_URL + CROWDFUNDING_SALESORDER_API;
    await axios
      .get(baseURL, config)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          console.debug(response.status + ": " + baseURL);
          commit("SET_DATA", response.data);
        } else {
          return Promise.reject;
        }
      })
      .catch((error) => {
        // handle error
        console.error(error);
        throw error;
      });
  },

    
  async getDetail({ commit }, id) {
    try {
        const response = await axiosClient.get(Constants.API_DOMAIN_URL + CROWDFUNDING_SALESORDER_API + "/" + id);
        commit('SET_DATA', response.data);
        return response.data
    } catch (error) {
        return null
    }    
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
