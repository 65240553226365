<template>
  <v-text-field
    v-if="item.questiontype === 'text'"
    class="ma-4"
    :key="item"
    :index="index"
    :type="item.questiontype"
    :label="$t('contactus.' + item.question)"
    :id="'entry.' + item.name"
    :name="'entry.' + item.name"
    :data-vv-as="item.question"
    :v-model="inputvalue"
    v-validate="item.validate === true ? 'required' : item.validate"
    :rules="requireRule"
    variant="underlined"
  ></v-text-field>

  <v-select
    v-else-if="item.questiontype === 'pulldown'"
    class="ma-4"
    :label="$t('contactus.' + item.question)"
    :items="item.options"
    :id="'entry.' + item.name"
    :name="'entry.' + item.name"
    :data-vv-as="item.question"
    :rules="requireRule"
    variant="underlined"
  ></v-select>

  <v-textarea
    v-if="item.questiontype === 'textarea'"
    clearable
    class="ma-4"
    :key="item"
    :index="index"
    :type="item.questiontype"
    rows="2"
    :label="$t('contactus.' + item.question)"
    :id="'entry.' + item.name"
    :name="'entry.' + item.name"
    :data-vv-as="item.question"
    :v-model="inputvalue"
    :rules="requireRule"
    auto-grow
    variant="underlined"
  ></v-textarea>
</template>

<script>
export default {
  props: ["item", "index"],

  setup() {},

  data: function () {
    return {
      PulldownInitialMessage: "Please Select",
      inputvalue: this.item.initialvalue ? this.item.initialvalue : null,
      inputvalues: Array.isArray(this.item.options) ? [] : false,
      requireRule: [
        (value) => {
          if (value?.length > 3) return true;
          return this.$i18n.tc("contactus.value_required");
        },
      ],
    };
  },

  computed: {},
};
</script>

<style>
</style>