<template>
  <v-card
    class="pa-2"
    style="width: 340px; height: 100px"
    elevation="2"
    color="white"
    :ripple="false"
  >
    <router-link
      :to="{
        name: 'Shop',
        params: { id: shopObjects.shop_id, openPage: '1', scrollPage: '0' },
      }"
      style="text-decoration: none; color: inherit"
    >
      <v-row no-gutters>
        <v-col cols="4">
          <v-img
            :lazy-src="local_shop"
            :src="shopObjects.shop_img_small"
            aspect-ratio="1"
            height="80px"
            width="80px"
            cover
          >
          </v-img>
        </v-col>

        <v-col cols="7">
          <div
            v-snip="{ lines: 1 }"
            class="text-body-1 mx-n4 text-black"
            style="font-weight: bold"
          >
            {{ shopObjects.shop_title }}
          </div>

          <div
            v-snip="{ lines: 3 }"
            class="text-caption mx-n4 text-black"
            style="font-weight: normal"
            v-if="shopObjects.ai_review_short != null"
          >
            <p v-if="this.$i18n.locale == 'zhCN'">
              {{ shopObjects.ai_review_short_zhcn }}
            </p>
            <p v-if="this.$i18n.locale == 'ms'">
              {{ shopObjects.ai_review_short_ms }}
            </p>
            <p v-else>
              {{ shopObjects.ai_review_short }}
            </p>
          </div>
          <div v-else>
            <p class="text-caption mx-n4 text-grey">{{ $t("shop.noShopDetails") }}</p>
          </div>
        </v-col>
      </v-row>
    </router-link>
  </v-card>
</template>

<script>
import Uitls from "@/uitls/Uitls";

export default {
  props: {
    shopObjects: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    // setup() receives props as the first argument.
    // console.log(props.shopObjects);
    // console.log(props.shopObjects.shop_crowdfundings[0]);
  },

  data() {
    return {
      local_shop: require("@/assets/img/logo120.png"),
      bot_logo_src: require("@/assets/img/robot_120.png"),
    };
  },

  methods: {
    prefectureCodeToString(code) {
      return Uitls.prefectureCodeToString(code);
    },
    shopPriceRange() {
      return this.shopObjects
        ? Uitls.calculateShopPriceRange(this.shopObjects)
        : "RM-";
    },
  },
};
</script>

<style></style>