
const GFData = {

  GFDocUrl() {
    return 'https://docs.google.com/forms/d/e/1FAIpQLSdB5iclcLF2C6Crky5gdMMdNWcEKnFU5m6k0O1tFGmTHoRDJw/formResponse'
  },

  GFDocSurvey() {
    return [
      {
        name: 2005620554,
        question: 'your_name',
        questiontype: 'text',
        label: 'username',
        placeholder: 'Your name',
        validate: true
      },
      {
        name: 1045781291,
        question: 'email',
        questiontype: 'text',
        label: 'email',
        placeholder: 'yourmail@beauty360.com',
        validate: 'required|email'
      },
      {
        name: 1118353405,
        question: 'contact_number',
        questiontype: 'text',
        placeholder: '013-8888-147',
        validate: 'required|numeric'
      },
      {
        name: 1065046570,
        question: 'please_select',
        questiontype: 'pulldown',
        options: ['Publishing my shop', 'Update Shop info', 'Beauty360 Salon System', 'Service Introduction (Document)', 'Business Partners', 'Campaign', 'Other'],
        validate: 'required'
      },
      {
        name: 2008241755,
        question: 'please_share_info',
        questiontype: 'textarea',
        validate: 'option'
      },
      // {
      //   name: 2133714093,
      //   question: '性別',
      //   questiontype: 'radio',
      //   initialvalue: '男',
      //   options: ['男','女','その他'],
      //   validate: 'required'
      // },
      // {
      //   name: 367145191,
      //   question: '職業',
      //   questiontype: 'radio',
      //   options: ['会社員','公務員','自営業','会社役員','自由業','専業主婦（夫）','学生','パート・アルバイト','無職'],
      //   freeanswer: 'その他',
      //   validate: 'required'
      // },
      // {
      //   name: 1948891795,
      //   question: '好きなJSフレームワーク',
      //   questiontype: 'checkbox',
      //   options: ['jQuery','React.js','Vue.js','AngularJS'],
      //   freeanswer: 'その他',
      //   validate: 'required'
      // },
      // {
      //   name: 632694560,
      //   question: '利用規約への同意',
      //   questiontype: 'checkbox',
      //   options: '利用規約に同意する',
      //   validate: 'required'
      // }
    ];
  },
}

export default GFData