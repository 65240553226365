<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-card
      class="mx-auto"
      color="yellow"
      :title="$t('shop.comingSoon')"
      :subtitle="$t('shop.underConstruction')"
      prepend-icon="mdi-alert"
    ></v-card>
   
    <p class="text-h5 mx-2 mt-4" style="font-weight: bold">
      <v-icon icon="mdi-magnify" color="blue" size="24px" class="mb-1"></v-icon>
      {{ title }} {{ $t("searchresults.hairstyle") }}
      {{ $t("searchresults.search_results") }}
      <span class="text-h5 text-black" style="font-weight: bold"> 140 </span>
    </p>

    <!-- Category Tag -->
    <v-container>
      <v-btn-toggle
        v-model="btnToggleSelected"
        group
        variant="outlined"
        mandatory
        divided
        density="compact"
        rounded="xl"
        color="deep-purple-accent-3"
      >
        <v-btn size="small" :ripple="false"> All </v-btn>
        <v-btn size="small" :ripple="false"> Chinese friendly </v-btn>
        <v-btn size="small" :ripple="false"> Malay friendly </v-btn>
      </v-btn-toggle>
    </v-container>

    <v-sheet color="" class="ma-2">
      <v-chip-group v-model="searchPrefectureChip">
        <v-chip
          closable
          color="blue"
          v-for="n in 8"
          :key="n"
          @click:close="chipClosePrefectureClick(string)"
          >Tags Chip</v-chip
        >
      </v-chip-group>
    </v-sheet>

    <v-row justify="end" class="mx-4 mt-3">
      <v-btn
        :ripple="false"
        value="right"
        variant="text"
        color="blue"
        prepend-icon="mdi-sort"
      >
        {{ $t("searchresults.sort") }}
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-for="(item, index) in sortItems"
              :key="index"
              :value="index"
              @click="sortMenuSelect(item)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </v-row>

    <!-- Card -->
    <v-container>
      <v-row no-gutters warp class="d-flex">
        <v-col v-for="n in 8" :key="n" cols="6" md="4" sm="6">
          <v-card class="ma-1" elevation="1" :ripple="false" max-height="400px">
            <v-img
              :src="tmpImg"
              aspect-ratio="16/9"
              @click="imageClick(n)"
              max-height="300px"
              cover
            >
            </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="blue"
                variant="text"
                icon="mdi-heart-outline"
                :ripple="false"
                size="small"
                @click="linkOnClick"
              ></v-btn>

              <v-btn
                color="blue"
                variant="text"
                icon="mdi-tray-arrow-up"
                size="small"
                :ripple="false"
                @click="snsShare"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>

  <!-- PopUpDialog -->
  <v-dialog v-model="fullImage" width="auto">
    <v-sheet color="white" rounded="lg" class="pa-0">
      <v-card elevation="0">
        <v-card-actions class="justify-space-between">
          <p class="text-h6">Beauty 360</p>
          <v-btn
            class="ma-0"
            icon="mdi-close"
            color="black"
            width="30"
            height="30"
            @click="fullImage = false"
            :ripple="false"
          ></v-btn>
        </v-card-actions>
      </v-card>

      <div class="mb-4">
        <v-img
          class="full-image"
          src="girl_smilong_hair.jpeg"
          max-height="80%"
          cover
          aspect-ratio="1"
        />

        <v-row class="justify-center mx-2 mt-3">
          <v-btn-toggle
            v-model="photo_position"
            rounded="xl"
            color="blue"
            group
            divided
            variant="outlined"
          >
            <v-btn value="front" size="small" :ripple="false"> Front </v-btn>
            <v-btn value="side" size="small" :ripple="false"> Side </v-btn>
            <v-btn value="back" size="small" :ripple="false"> Back </v-btn>
            <v-btn
              icon="mdi-heart-outline"
              :ripple="false"
              @click="linkOnClick"
            ></v-btn>
            <v-btn icon="mdi-tray-arrow-up" :ripple="false" @click="snsShare"></v-btn>
          </v-btn-toggle>
        </v-row>

        <!-- TableDesign -->
        <!-- <v-row no-gutters warp class="ma-2">
          <v-col>
            <v-simple-table>
              <tbody>
                <tr v-for="(title, index) in titleList" v-bind:key="index">
                  <td class="tdTitle">
                    <p class="text-body-2 mx-2 mt-3" style="font-weight: bold">
                      {{ title }}
                    </p>
                  </td>

                  <td class="tdDetail" v-if="index == 0">
                    <div class="mt-n2 d-flex ga-2">
                      <v-chip size="default" variant="text">Less</v-chip>
                      <v-chip size="default" variant="flat" color="blue"
                        >Normal</v-chip
                      >
                      <v-chip size="default" variant="flat" color="blue"
                        >Much</v-chip
                      >
                    </div>
                  </td>

                  <td class="tdDetail" v-if="index == 1">
                    <div class="d-flex ga-2 mt-2">
                      <v-chip size="default" variant="flat" color="blue"
                        >Soft</v-chip
                      >
                      <v-chip size="default" variant="flat" color="blue"
                        >Normal</v-chip
                      >
                      <v-chip size="default" variant="flat" color="blue"
                        >Hard</v-chip
                      >
                    </div>
                  </td>

                  <td class="tdDetail" v-if="index == 2">
                    <div class="d-flex ga-2 mt-2">
                      <v-chip size="default" variant="text">Thin</v-chip>
                      <v-chip size="default" variant="flat" color="blue"
                        >Normal</v-chip
                      >
                      <v-chip size="default" variant="flat" color="blue"
                        >Thick</v-chip
                      >
                    </div>
                  </td>

                  <td class="tdDetail" v-if="index == 3">
                    <div class="d-flex ga-2 mt-2">
                      <v-chip size="small" variant="flat" color="blue"
                        ><v-icon start icon="mdi-shape-circle-plus"></v-icon>
                        Round</v-chip
                      >
                      <v-chip size="small" variant="flat" color="blue"
                        ><v-icon start icon="mdi-shape-oval-plus"></v-icon
                        >Oval</v-chip
                      >
                      <v-chip size="small" variant="text"
                        ><v-icon start icon="mdi-shape-square-plus"></v-icon
                        >Square</v-chip
                      >
                      <v-chip size="small" variant="flat" color="blue"
                        ><v-icon start icon="mdi-shape-polygon-plus"></v-icon
                        >Base</v-chip
                      >
                      <v-chip size="small" variant="flat" color="blue"
                        ><v-icon start icon="mdi-triangle-down-outline"></v-icon
                        >Triangle</v-chip
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row> -->

        <v-container class="mt-10 mb-4">
          <v-row>
            <div class="text-body-2 mx-2" style="font-weight: bold">
              Volumes
            </div>
            <div class="mt-n2 d-flex ga-2">
              <v-chip size="default" variant="text">Less</v-chip>
              <v-chip size="default" variant="flat" color="blue">Normal</v-chip>
              <v-chip size="default" variant="flat" color="blue">Much</v-chip>
            </div>
          </v-row>

          <v-row class="">
            <div class="text-body-2 mx-2 mt-3" style="font-weight: bold">
              Type
            </div>
            <div class="d-flex ga-2 mt-2">
              <v-chip size="default" variant="flat" color="blue">Soft</v-chip>
              <v-chip size="default" variant="flat" color="blue">Normal</v-chip>
              <v-chip size="default" variant="flat" color="blue">Hard</v-chip>
            </div>
          </v-row>

          <v-row>
            <div class="text-body-2 mx-2 mt-3" style="font-weight: bold">
              Thickness
            </div>

            <div class="d-flex ga-2 mt-2">
              <v-chip size="default" variant="text">Thin</v-chip>
              <v-chip size="default" variant="flat" color="blue">Normal</v-chip>
              <v-chip size="default" variant="flat" color="blue">Thick</v-chip>
            </div>
          </v-row>

          <v-row>
            <div class="text-body-2 mx-2 mt-3" style="font-weight: bold">
              Face
            </div>
            <v-col cols="12">
              <v-chip size="default" variant="flat" color="blue"
                ><v-icon start icon="mdi-shape-circle-plus"></v-icon>
                Round</v-chip
              >
              <v-chip size="default" variant="flat" color="blue"
                ><v-icon start icon="mdi-shape-oval-plus"></v-icon>Oval</v-chip
              >
              <v-chip size="default" variant="text"
                ><v-icon start icon="mdi-shape-square-plus"></v-icon
                >Square</v-chip
              >
              <v-chip size="default" variant="flat" color="blue"
                ><v-icon start icon="mdi-shape-polygon-plus"></v-icon
                >Base</v-chip
              >
              <v-chip size="default" variant="flat" color="blue"
                ><v-icon start icon="mdi-triangle-down-outline"></v-icon
                >Triangle</v-chip
              >
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-sheet>
  </v-dialog>

  <SNSShareDialog
    v-if="shopData"
    :shopInfoObjects="shopData"
    :openSNSShareDialog="openSNSShareDialog"
    @result="responseFromSNSDialog"
  ></SNSShareDialog>
</template>
  

<script>
import SNSShareDialog from "@/components/SNSShareDialog.vue";
import { useHead } from "@vueuse/head";

export default {
  props: ["gender", "hairtype"],

  components: {
    SNSShareDialog,
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });
  },

  data() {
    return {
      btnToggleSelected: 0,
      shopData: null,
      openSNSShareDialog: false,
      currentImageIndex: -1,
      fullImage: false,
      photo_position: "front",
      tmpImg: require("@/assets/img/girl.jpg"),
      hair_volumes_chip: ["1", "2"],
      titleList: ["Volumes", "Type", "Thickness", "Face"],
      sortItems: ["Latest", "Highest Review"],
    };
  },

  methods: {
    imageClick(index) {
      this.currentImageIndex = index;
      this.fullImage = true;
    },

    snsShare() {
      this.openSNSShareDialog = true;
    },

    responseFromSNSDialog(obj) {
      this.openSNSShareDialog = false;
    },
  },
};
</script>

<style scoped>
.tdTitle {
  width: 90%;
  height: 2.5em;
  /* background-color: rgba(211, 211, 211, 0.217); */
  border: none;
  text-align: left;
}
.tdDetail {
  width: 10%;
  /* background-color: rgba(211, 211, 211, 0.217); */
  border: none;
  text-align: left;
}
</style>