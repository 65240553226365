<template>
  <v-sheet class="pa-4 ma-0 mx-auto" color="white" max-width="1200">
    <p class="text-h5 mt-6" style="font-weight: bold">Terms Of Use</p>
    <p class="text-body-2">
      By entering your email, logging into your account, or accepting
      notifications, you agree to receive personalized Beauty360 deals each day.
      You may unsubscribe at any time. Welcome to the beauty360 Site (defined
      below). By using it, you are agreeing to these Terms of Use (defined
      below). Please read them carefully. If you have any questions, contact us
      here.
    </p>
    <br />
    <p class="text-h5 mt-6" style="font-weight: bold">
      ACCEPTANCE OF TERMS OF USE
    </p>
    <p>
      Beauty360 sole proprietorship ("Company" or "we" or "us") welcomes you to
      our website, https://beauty-360.com/(“Website”). The Website provides an
      e-commerce platform that enables registered members to place, accept,
      conclude, manage and fulfil orders for the sale and purchase of products
      and/or services online via the Website.Your use of the Website is governed
      by the following terms and conditions ("Terms and Use").
    </p>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 1 (Objective)</p>
      <p>
        The Beauty360 Terms of Use (“ToU”) establish the terms and conditions
        regarding all services (the “Service”) provided by Beauty360. (the
        “Company”), group companies (collectively referred to as the “Group”),
        and the Group’s partner companies (“Partner Companies”) that use the
        Group’s common ID.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 2 (Definitions)</p>
      <p>
        The following terms used in the ToU have meanings as defined below.
        (1)”Member” means a person who has registered as a member of the Service
        in a manner set forth by the Company and has been issued a common ID.
        (2)”Common ID” means the ID and password issued individually to a member
        by the Company. (3)”Service provider” means the Group or partner
        companies providing the Service.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 3 (Application)</p>
      <p>
        1. The ToU applies to all relationships between the Company and members
        that are connected to using the Service.
      </p>
      <p>
        2. For each service within the Service, in the event a service provider
        has individually established terms of service, rules, cautions, etc.
        (collectively referred to as “individual terms”), said individual terms
        will constitute a portion of the ToU and will apply to the use of said
        Service.
      </p>
      <p>
        3. In the event the contents established in the ToU and individual terms
        differ, if there are no special provisions, the contents of the
        individual terms will supersede.
      </p>
      <br />
    </div>
    <div class="about-text">
      <p class="text-h6">Article 4 (Member Registration)</p>
      <p>
        <ul>

      
        <li>
          1. Member registration in a method determined by the Company is
          necessary to use the Service.
        </li>
        <li>
          2. Those who wish to become members can apply for member registration
          to the Company by agreeing to comply with the ToU and providing
          certain information established by the Company in a manner established
          by the Company.
        </li>
        <li>
          3. The Company will determine whether to approve or decline the
          registration of the person who applied for registration (“the
          applicant”) in the manner described in the previous paragraph, and
          will notify the applicant in the event the registration is approved.
          At the point in time the Company carries out said notification, a
          membership contract composed of the ToU (“Contract”) will be
          established between the Company and the applicant.
        </li>
        <li>
          4. The Company may decline an application in the event any of the
          following are applicable. In such an event, the Company does not have
          a duty to explain the reason the applicant was declined nor any other
          responsibilities.
        </li>
      </ul>
        (1) All or part of the registration information provided to the Company
        was false, incorrect, or incomplete. (2) The applicant is already
        registered and the application was redundant. (3) The applicant is a
        minor, an adult ward, a person under curatorship, or a person under
        assistance and has not received permission from their legal
        representative, guardian, curator, or assistant. (4) The applicant has
        violated the ToU in the past. (5) The Company has determined in advance
        that the applicant is likely to violate the ToU. (6) The applicant is a
        member of anti-social forces (Means an organized crime group, a member
        of an organized crime group, far-right-wing group, anti-social force, or
        other similar person. Unchanged below.), or the Company has determined
        that the applicant is connected to or involved with anti-social forces.
        (7) The Company has determined for some other reason that registration
        is not appropriate.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 5 (Changing Registered Information)</p>
      <p>
        1. A member must, in the event their registered information has changed,
        report their changed information to the Company in a manner established
        by the Company without undue delay.
      </p>
      <p>
        2. The Company bears no responsibility for any disadvantage suffered by
        the member caused by failing to make the report in the previous
        paragraph.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 6 (Common ID Management)</p>
      <p>
        1. Members must take personal responsibility to appropriately manage and
        store their common ID, and they may not lend, transfer, change ownership
        of, sell, or allow third parties to use their common ID.
      </p>
      <p>
        2. Responsibility for any damages resulting from improper management,
        negligent use, or malicious use by third parties of the common ID will
        be borne by the member that possesses the common ID, and the Company
        will bear no responsibility.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 7 (Attribution of Rights)</p>
      <p>
        1. All intellectual property rights for the data that constitute the
        Service, including text, images, and programs, belong to the service
        provider using said data or the licensor, and this Contract does not
        grant a license to the member for the intellectual property rights. The
        member must use the intellectual property rights only to the extent that
        is necessary for using the Service.
      </p>
      <p>
        2. The member attests to the Company and guarantees that the data such
        as comments and images posted by the member on the Service (“posted
        data”) does not infringe on the rights of any third party.
      </p>
      <p>
        3. The member grants permission in advance to the Company to use (use,
        adapt, modify, reproduce, etc.) posted data for purposes such as
        advertisements and announcements free of charge, and the member will not
        exercise any moral rights regarding this use. Also, this granting of
        rights will remain in effect even if the member withdraws from the
        Service.
      </p>
    </div>
    <div class="about-text">
      <p class="text-h6">Article 8 (Prohibited Acts)</p>
      <p>
        The member may not commit any action to which any of the following items
        are applicable or to which the Company determines any of the following
        items are applicable when using the Service, in addition to other
        actions established separately in the ToU.
      </p>
      <li>(1) Illegal acts or acts that are likely to be illegal.</li>
      <li>
        (2) Acts that violate public order or morals or are likely to do so.
      </li>
      <li>(3) Obtaining or manipulating points in a wrongful manner.</li>
      <li>
        (4) Acts that interfere with the Company’s operations or are likely to
        do so.
      </li>
      <li>
        (5) Acts that impose an excessive burden on the Service’s network or
        system.
      </li>
      <li>
        (6) Obtaining or modifying points using bots, cheat tools, or other
        technological methods.
      </li>
      <li>
        (7) Interfering with or attempting to wrongfully access the Company’s
        server or network system.
      </li>
      <li>
        (8) Using the Service for commercial purposes without the Company’s
        consent, or preparing to do so.
      </li>
      <li>
        (9) Using abusive language to the Company’s employees or excessively
        demanding from the Company something it has no duty to provide.
      </li>
      <li>(10) Using the Service while impersonating a third party.</li>
      <li>(11) Providing, registering, or publishing false information.</li>
      <li>
        (12) Wrongfully using a common ID, or acts that are likely to involve
        doing so.
      </li>
      <li>
        (13) Acts that infringe upon the rights of other members, third parties,
        or the Company, including intellectual property rights, property rights,
        privacy rights, or rights to reputation, or acts that are likely to do
        so.
      </li>
      <li>
        (14) Defaming other members, third parties, or the Company, or acts that
        are likely to be defamatory.
      </li>
      <li>
        (15) Acts that disadvantage, harm, or discomfort other members, third
        parties, or the Company.
      </li>
      <li>(16) Other acts deemed inappropriate by the Company.</li>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Chapter 9 (Assignment of Status, etc.)</p>
      <p>
        1. Members may not, without written prior consent from the Company,
        assign, transfer, collateralize, or dispose in any other way of their
        status or rights according to this Contract to a third party.
      </p>
      <p>
        2. The Company may transfer the Company’s status in this Contract, its
        rights and duties in the ToU, and member information to companies that
        inherit business concerning the Service (includes business transfer,
        restructuring, and all other events involving the transfer of business).
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 10 (Suspension of Service, etc.)</p>
      <p>
        1. The Company may suspend or interrupt the provision of all or part of
        the Service in any of the following events. (1) An inspection or
        emergency maintenance is being carried out on the computer system
        concerning the Service. (2) Equipment installed or maintained by the
        Company or a third party is experiencing an error, malfunction, failure,
        or some other situation making provision of the Service impossible. (3)
        The operation of the Service is no longer possible due to an earthquake,
        lightning strike, fire, wind or water damage, power outage, natural
        disaster, or epidemic. (4) The Company determines that a suspension or
        interruption is necessary.
      </p>
      <p>
        2. In the event the Company suspends or interrupts the provision of the
        Service in accordance with the previous paragraph, the Company will
        inform members in advance via email, a notification in the Service, or
        another method deemed appropriate by the Company. However, there may be
        an exception in the event of an emergency.
      </p>
      <p>
        3. The Company bears no responsibility for any disadvantages, such as
        damages, to members caused by the suspension or interruption of the
        provision of the Service for any reason.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 11 (Suspension or Revocation of Membership)</p>
      <p>
        1. The Company may, in the event it has determined that any of the
        following are applicable to a member, temporarily suspend said member’s
        use of the Service, revoke their registration as a member, or dissolve
        the Contract without advance notice or demand.
        <ul>

        <li>(1) The member violated a provision of the ToU.</li>
        <li>
          (2) There has been no sign of use of the Service, such as points
          gained or exchanged, for one year or more.
        </li>
        <li>
          (3) The member has interfered with the operation of the Service.
        </li>
        <li>
          (4) It has been discovered that the member’s membership in the Service
          has been revoked in the past.
        </li>
        <li>
          (5) The Company has determined that the member’s use of the Service,
          registration as a member, or the continuation of this Contract is
          inappropriate.
        </li>
      </ul>
      </p>
      <p>
        2. In the event the Company revokes a member’s membership in accordance
        with the previous paragraph, that member will lose their held points and
        all rights and benefits regarding using the Service, and the Company
        bears no responsibility for any resulting disadvantages, such as
        damages.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 12 (Withdrawal)</p>
      <p>
        1. The member can terminate this Contract at any time by completing the
        withdrawal procedures established by the Company.
      </p>
      <p>
        2. By withdrawing, the member loses their held points and all rights
        regarding the use of the Service, and the Company bears no
        responsibility for any resulting disadvantages, such as damages.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 13 (Disclaimer of Warranty and Liability)</p>
      <p>
        1. The Company makes no warranty concerning the completeness, accuracy,
        certainty, or usefulness of any information provided to the member in
        the Service.
      </p>
      <p>
        2. The Company bears no responsibility for any disadvantage, such as
        damages, to the member resulting from the delay, alteration,
        interruption, cessation, or discontinuance of provision of the Service,
        the deletion or loss of posted data provided by the member through the
        Service, or any other incident related to the Service. However, there
        may be exceptions in the event of deliberate or grossly negligent
        conduct by the Company.
      </p>
      <p>
        3. In the event there is a dispute between a member and another member
        or a third party connected to the use of the Service, the Company bears
        no responsibility, and the member must take the responsibility of
        resolving this dispute using their own funds.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 14 (Handling of Personal Information)</p>
      <p>
        The Company will appropriately handle information obtained from members
        in accordance with the privacy policy established separately.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 15 (Notifications to Members)</p>
      <p>
        1. Communications and notifications from members to the Company, such as
        inquiries regarding the Service, as well as communications and
        notifications from the Company to members, such as notifications of
        changes to the ToU, will be carried out in a manner established by the
        Company.
      </p>
      <p>
        2. In the event the notification in the previous paragraph is carried
        out via email, notification to the member will be considered to be
        completed once the Company has sent the email to the member’s registered
        email address.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 16 (Changes to the ToU)</p>
      <p>
        When the Company deems it necessary, the Company may change the content
        of the ToU by displaying the changed ToU content and the date the change
        will take effect on a medium such as the Company’s website or by
        notifying members. However, in the event of a change for which member
        consent is required by law, the Company will obtain consent in a method
        prescribed by the Company.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 17 (Severability)</p>
      <p>
        Even in the event all or part of one of the provisions in the ToU is
        deemed void or unenforceable by laws such as the Consumer Contract Act,
        the remainder of the ToU will remain in effect.
      </p>
    </div>
    <br />
    <div class="about-text">
      <p class="text-h6">Article 18 (Governing Law and Agreed Jurisdiction)</p>
      <p>
        If you are a company / registered business, you must be an incorporated
        legal entity or have a business registration number and are authorized
        under your constitution documents to conduct business with us in
        accordance with the laws of Malaysia;
      </p>
      <p>
        2. Any disputes arising out of or in connection to the ToU or contracts
        for use of the Service are subject to the exclusive jurisdiction of the
        Malaysia District Court in the first instance.
      </p>
    </div>
    <br>
    <div class="about-text">
      <p class="text-h6">Article 19 (Definition of Free Use: Business Account)</p>
      <p>
        For users of the Business version, the free version is limited to use on a single PC device. If additional PC devices are added for use, additional fees may apply.
      </p>
      <p>
        2. Any disputes arising out of or in connection to the ToU or contracts
        for use of the Service are subject to the exclusive jurisdiction of the
        Malaysia District Court in the first instance.
      </p>
    </div>
    <p>End</p>
  </v-sheet>
</template>

<script scoped>
import { useHead } from "@vueuse/head";

export default {
  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });
  },

}

</script>