<template>
  <v-container fluid>
    <v-row align="center" justify="center" class="mt-6">
      <v-btn-toggle
        v-model="toggle_exclusive"
        borderless
        @click="change(toggle_exclusive)"
      >
        <v-btn value="en">
          <p style="font-weight: normal">English</p>
        </v-btn>

        <v-btn value="zh">
          <p style="font-weight: normal">中文</p>
        </v-btn>
      </v-btn-toggle>
      <v-col cols="12" v-if="chineseSruvey">
        <div align="center">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdaFbC-E6GH7fDD9JZRx5W-uFIHlkF8XWMqxwJy8RoGWhMi4Q/viewform?embedded=true?utm_source=beauty360&utm_medium=display&utm_campaign=202405"
            :width="frameWidth"
            :height="frameHeight"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            >Loading…</iframe
          >
        </div>
      </v-col>

      <v-col cols="12" v-else>
        <div align="center">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfoEwYpGQdL8uUmSs_pIiZhNy6r5JS8yqm0uwp0ELnDzfas9g/viewform?embedded=true?utm_source=beauty360&utm_medium=display&utm_campaign=202405"
            :width="frameWidth"
            :height="frameHeight"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            >Loading…</iframe
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });
    return;
  },

  data() {
    return {
      toggle_exclusive: 0,
      chineseSruvey: false,
      frameWidth: window.innerWidth - 36,
      frameHeight: window.innerHeight,
    };
  },

  mounted() {
    // console.log(this.frameWidth)
  },

  methods: {
    change(value) {
      if (value == "en") {
        this.chineseSruvey = false;
      } else {
        this.chineseSruvey = true;
      }
    },
  },
};
</script>

<style scoped>
</style>