import axios from 'axios'
import Constants from '@/uitls/Constants'
import { USER_API } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + Constants.API_ACCESS_TOKEN,
  }
};

const state = {
  userDetail: null,
};

const getters = {
  getUserInfo: state => {
    return state.userDetail;
  }
};

const mutations = {
  SET_DATA: (state, data) => {
    state.userDetail = data;
  },
};

const actions = {
  async getData({ commit }, user_id) {
    const baseURL = USER_API + "/" + user_id
    await axiosClient
      .get(baseURL, config)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          console.log(response.status + ": " + baseURL);
          commit("SET_DATA", response.data);
        } else {
          return Promise.reject;
        }
      })
      .catch((error) => {
        // handle error
        console.error(error);
        throw error;
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
