
<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    max-width="800"
    rounded="lg"
    width="100%"
    elevation="0"
  >

  <div align="center" v-if="type == 1">
    <v-img :src="document_img" max-width="50%" class="ma-6" />   
    <p class="text-h5"> {{ $t("contactus.get_doc_title") }}</p>
    <p class="text-body-1">{{ $t("contactus.get_doc_desc1") }}</p>
    <p class="text-body-1">{{ $t("contactus.get_doc_desc2") }}</p>
    <p class="text-body-1 mb-4">{{ $t("contactus.get_doc_desc3") }}</p>
    <v-divider></v-divider>
  </div>

    <v-form
      v-show="!submitted"
      name="gf_form"
      method="POST"
      target="hidden_iframe"
      :action="gfSurveyUrl"
      @submit.prevent="gf_submit()"
      ref="gfSurvey"
      fast-fail
      lazy-validation
    >
      <div align="center">
        <p class="text-h5 mt-5">{{ $t("contactus.get_touch") }}</p>
        <p class="text-caption pa-2">
          {{ $t("contactus.get_touch_desc") }}
        </p>
      </div>
      <v-row no-gutters class="ma-4">
        <v-col
          v-for="(item, index) in surveyList"
          :key="item"
          cols="12"
          md="12"
        >
          <ContactUsGformVue :item="item" :index="index" />
        </v-col>
      </v-row>

      <v-container>
        <v-btn
          type="submit"
          block
          height="50px"
          width="80%"
          class="mt-0"
          flat
          color="blue"
          @click="submit"
          :ripple="false"
          :loading="loading"
        >
          {{ $t("settingpage.submit") }}</v-btn
        >
      </v-container>
    </v-form>

    <v-sheet
      v-if="submitted"
      elevation="1"
      max-width="600"
      rounded="lg"
      width="90%"
      class="ma-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-email-fast-outline"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">{{ $t("contactus.thank_you") }}</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        {{ $t("contactus.thank_you_desc") }}
      </p>

      <v-divider class="mb-4"></v-divider>
      <div class="text-end">
        <v-btn
          class="ma-2"
          color="success"
          rounded
          variant="flat"
          width="90"
          @click="done"
        >
          {{ $t("busienssPage.done") }}
        </v-btn>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import ContactUsGformVue from "./ContactUsGform.vue";
import GFData from "@/uitls/GFData";
import axios from "axios";
import utils from "@/uitls/Uitls";
import { useHead } from "@vueuse/head";

export default {
  props: ["type"],

  components: {
    ContactUsGformVue,
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });
  },

  data: function () {
    return {
      name: null,
      email: null,
      phone: null,
      submitted: false,
      gfSurveyUrl: GFData.GFDocUrl(),
      surveyList: GFData.GFDocSurvey(),
      loading: false,
      document_img: require("@/assets/img/document.jpg"),
    };
  },

  methods: {
    async gf_submit(e) {
      /*
      this.$validator.validate().then((result) => {
        if (!result) {
          return false;
        }
        document.gf_form.submit();
        this.submitted = true;
      });
      */

      this.loading = true;

      let postItems = [];
      var haveEmptyValue = false;

      for (let index = 0; index < this.surveyList.length; index++) {
        const item = this.surveyList[index];
        if (
          utils.isEmpty(document.getElementById("entry." + item.name).value)
        ) {
          haveEmptyValue = true;
          this.loading = false;
          break;
        } else {
          var postItem =
            "entry." +
            item.name +
            "=" +
            document.getElementById("entry." + item.name).value;
          postItems.push(postItem);
        }
      }

      if (!haveEmptyValue) {
        const sentence = postItems.join("&");
        const url = this.gfSurveyUrl + "?" + sentence;

        setTimeout(() => {
          this.submitted = true;
        }, 2000);

        const response = await axios.get(url);
        console.log(response.status);
      }
    },

    done() {
      this.$router.push({ name: "HomeView" });
    },
  },

  async mounted() {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("name", "hidden_iframe");
    iframe.setAttribute("style", "display: none");
    this.$refs.gfSurvey.appendChild(iframe);
  },
};
</script>

<style>
</style>