
import { TROUBLE_API, USER_API } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';

const state = () => ({
  userTrouble: [],
})

const getters = {
  userTrouble: (state) => state.userTrouble,
}

const mutations = {
  SET_USER_TROUBLE(state, item) {
    state.userTrouble = item
  },
}

const actions = {
  async fetchAll({ commit }) {
    const response = await axiosClient.get(`${TROUBLE_API}`)
    return response.data
  },
  async updateUserTrouble({ commit, state, dispatch }, data) {
    try {
      const response = await axiosClient.post(`${USER_API}/troubles`, data)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error
      }
      return null
    }
  },
  async getUserTroubles({ commit, state, dispatch }, id) {
    try {
      const response = await axiosClient.get(`${USER_API}/troubles/${id}`)
      commit('SET_USER_TROUBLE', response.data)
      return response.data
    } catch (error) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}