import axios from "axios"
import Constants from '@/uitls/Constants'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const axiosClient = axios.create({
  baseURL: Constants.API_DOMAIN_URL,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: false,
})

axiosClient.interceptors.request.use(function (config) {
  
  const token = cookies.get("userToken");
  // console.log("onMount ++ "+token);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
   }
  
   return config;
},
  function (error) {
    return Promise.reject(error);
  }
)

export default axiosClient;