<template>
  <!-- <v-card style="margin: 0 0 0 0; width: 320px; height: 260px" class="ma-1">
    <v-row no-gutters class="ma-2">
      <v-col cols="4" md="4" style="background-color: #ffcdd2">
        <v-img
          src="/salon.jpeg"
          aspect-ratio="1"
          height="100"
          max-width="240"
          cover
        ></v-img>
      </v-col>

      <v-col cols="8" md="8">
        <div class="text-h5 mx-2" style="font-weight: normal">The Grand Salon</div>
        <div class="text-subtitle-2 mx-2" style="font-weight: normal">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. niti
          reprehenderit quibusdam.
        </div>
      </v-col>

      <v-col class="mt-2">
        <v-chip class="mx-2" size="x-small" color="blue" variant="outlined"
          >voucher</v-chip
        >
        <v-list-item v-for="item in priceList" :key="item.title">
          <router-link
            :to="{
              name: 'Shop',
              params: { id: '8', openPage: '2', scrollPage: '500' },
            }"
            style="text-decoration: none; color: inherit"
          >
            <v-list-item-content>
              <div class="text-subtitle-2 text-blue" style="font-weight: bold">
                {{ item.title }} / {{ item.price }}
              </div>
              <v-divider></v-divider>
            </v-list-item-content>
          </router-link>
        </v-list-item>
      </v-col>
    </v-row>
  </v-card> -->

  <v-card
    class="ma-3 pa-3"
    style="margin: 0 0 0 0; width: 420px; height: 150px"
    elevation="1"
  >
    <v-row no-gutters>
      <v-col cols="4">
        <v-img
          src="/salon.jpeg"
          aspect-ratio="1"
          height="122"
          max-width="280"
          cover
        ></v-img>
      </v-col>

      <v-col cols="8">
        <p class="text-h5 mx-2 text-black" style="font-weight: normal">
          THE GRAND SALON
        </p>

        <div class="mx-2">
          <v-icon
            icon="mdi-map-marker"
            size="16"
            color="black"
            class="mx-0 mt-n1"
          />
          <span class="mx-2 text-black" style="font-size: 12px"
            >KL Pavilion Mall</span
          >
        </div>
        <div class="mx-2">
          <v-icon
            icon="mdi-cash"
            color="black"
            size="16"
            class="mx-0 mt-n1"
          /><span style="font-size: 12px" class="mx-2 text-black">RM-</span>
        </div>
        <div class="mx-2">
          <v-icon
            icon="mdi-sofa-single"
            color="black"
            size="16"
            class="mx-0 mt-n1"
          /><span style="font-size: 12px" class="mx-2 text-black"
            >00 seats</span
          >
        </div>
        <div class="mx-2">
          <v-icon
            icon="mdi-briefcase"
            color="black"
            size="16"
            class="mx-0 mt-n1"
          /><span style="font-size: 12px" class="mx-2 text-black"
            >00:00am ~ 00:00pm (Mon off)</span
          >
        </div>
      </v-col>

      <!-- <v-col cols="12">
        <v-chip class="mx-2" size="x-small" color="blue" variant="outlined"
          >voucher</v-chip
        >
        <v-list-item v-for="item in priceList" :key="item.title">
          <router-link
            :to="{
              name: 'Shop',
              params: { id: '8', openPage: '2', scrollPage: '500' },
            }"
            style="text-decoration: none; color: inherit"
          >
            <v-list-item-content>
              <div class="text-subtitle-2 text-blue" style="font-weight: bold">
                {{ item.title }} / {{ item.price }}
              </div>
              <v-divider></v-divider>
            </v-list-item-content>
          </router-link>
        </v-list-item>
      </v-col> -->
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      priceList: [
        {
          title: "Cut + Color",
          eligible: "New customer only",
          desc: "Leekaja Beauty Salon is the best Korean hair salon in Malaysia",
          price: " RM100",
        },
        {
          title: "Color",
          eligible: "All",
          desc: "Leekaja Beauty Salon is the best Korean hair salon in Malaysia",
          price: "RM250",
        },
        {
          title: "Blow",
          eligible: "New customer only",
          desc: "Lea ean hair salon in Malaysia",
          price: "RM50",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style></style>