<template>
  <v-toolbar color="black" flat app height="48">
    <v-toolbar-title>
      <v-img
        alt="Beauty360 Business"
        v-bind:src="logo_src"
        height="30"
        @click="logoClick"
        aspect-ratio="1"
      ></v-img
    ></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      class="text-none"
      variant="outlined"
      rounded="xl"
      size="small"
      color="white"
      :ripple="false"
      @click="joinOnClick"
    >
      <p style="font-weight: normal">{{ $t("busienssPage.joinFree") }}</p>
    </v-btn>
    <v-btn
      class="text-none mx-4"
      variant="text"
      rounded="xl"
      size="small"
      color="white"
      :ripple="false"
      @click="adminLogin"
    >
      <p style="font-weight: normal">{{ $t("loginpage.signin") }}</p>
    </v-btn>
  </v-toolbar>
  <VSonner position="top-center" />
</template>

<script>
import { VSonner, toast } from "vuetify-sonner";

export default {
  components: {
    VSonner,
  },

  data() {
    return {
      logo_src: require("@/assets/newlogo.png"),
    };
  },

  methods: {
    joinOnClick() {
      this.$router.push({
        name: "ShopRegister",
      });
    },

    logoClick() {
      this.$router.push({ name: "HomeView" });
    },

    adminLogin() {
      const url = new URL("https://backyard.beauty-360.com/");
      window.open(url.toString(), "_blank");
    },
  },
};
</script>