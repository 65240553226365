<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn class="ma-0" icon="mdi-arrow-left" @click="$router.push('/settingpage')"></v-btn>
    <v-app-bar-title>{{$t('settingpage.new_email')}}</v-app-bar-title>
  </v-app-bar>

  <v-main>
    <v-container fluid>
      <v-sheet
        elevation="0"
        max-width="1200"
        rounded="lg"
        width="100%"
        class="pa-0 mx-auto mt-6"
      >
        <v-text-field
          :label="$t('settingpage.your_current_email')"
          variant="underlined"
          :model-value="userInfo.email"
          prepend-icon="mdi-email"
          disabled
        ></v-text-field>

        <v-text-field
          :label="$t('settingpage.new_email')"
          variant="underlined"
          v-model="emailNew"
          prepend-icon="mdi-email"
          persistent-hint
          :hint="$t('settingpage.change_mail_desc')"
          :error-messages="v$.emailNew.$errors.map((e) => e.$message)"
          @input="v$.emailNew.$touch"
          @blur="v$.emailNew.$touch"
        ></v-text-field>

        <v-row justify="center" no-gutters>
          <v-btn
            elevation="0"
            color="blue"
            width="93%"
            class="ma-6"
            height="50px"
            :ripple="false"
            rounded="pill"
            :loading="loading"
            @click="checkUserEmail"
          >
          {{$t("settingpage.submit")}}
          </v-btn>
        </v-row>
      </v-sheet>
    </v-container>
  </v-main>
  <VSonner position="top-center" />
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { VSonner, toast } from "vuetify-sonner";

export default {
  components: {
    VSonner,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  computed: {
    ...mapGetters({
      userInfo: "userStore/userInfo",
    }),
  },

  data: () => ({
    emailNew: null,
    loading: false,
    userPost: {
      email: "",
    },
  }),

  validations() {
    return {
      emailNew: {
        required,
        email,
      },
    };
  },

  mounted() {
    console.log("mounted %o",this.userInfo);
    this.userPost.id = this.userInfo.id;
  },

  methods: {
    async checkUserEmail() {
      if (!this.emailNew) return;

      this.loading = true;

      this.userPost.email = this.emailNew;

      const dataUpdate = {
        user: this.userPost,
      };

      await this.$store
        .dispatch("userStore/updateUserInfo", dataUpdate)
        .then((data) => {
          this.loading = false;
          toast(
            "Email address changed. Please check the link for the registered email address.",
            {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            }
          );

          setTimeout(() => {
            this.$router.push({ name: "SettingPage" });
          }, 3000);
        })
        .catch((e) => {
          this.loading = false;
          console.error("error: ", e);
          toast("Email address change faild, please try again.", {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },
  },
};
</script>