<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn
      class="ma-0"
      icon="mdi-arrow-left"
      @click="$router.push('/mypage')"
    ></v-btn>
    <v-app-bar-title>{{ $t("mypage.booking") }}</v-app-bar-title>
  </v-app-bar>

  <v-main>
    <v-sheet
      class="pa-0 ma-0 mx-auto"
      color="white"
      max-width="1200"
      width="100%"
      elevation="0"
    >
      <v-row style="display: flex; justify-content: center; padding: 16px">
        <v-tabs
          v-model="tab"
          bg-color="clear"
          color="blue"
          center-active
          grow
          class="mt-0"
        >
          <v-tab value="reserve">
            <v-icon start icon="mdi-calendar" />
            {{ $t("reservationdetails.reservation") }}
          </v-tab>
          <v-tab value="history">
            <v-icon start icon="mdi-history" />
            {{ $t("reservationdetails.history") }}</v-tab
          >
        </v-tabs>
      </v-row>

      <v-sheet elevation="0" rounded="lg" class="pa-0 mx-auto mt-6">
        <div style="display: flex; justify-content: center">
          <v-window v-model="tab">
            <v-window-item value="reserve" @scroll="handleScroll('reserve')">
              <div v-if="reserveList.length == 0">
                <v-sheet
                  elevation="0"
                  max-width="600"
                  rounded="lg"
                  width="100%"
                  class="pa-4 text-center mx-auto"
                >
                  <v-icon
                    class="mb-5"
                    color="blue"
                    icon="mdi-calendar"
                    size="112"
                  ></v-icon>
                  <h2 class="text-h5 mb-6">
                    {{ $t("settingpage.no_booking") }}
                  </h2>
                  <p class="mb-4 text-medium-emphasis text-body-2">
                    {{ $t("settingpage.like_shop_desc") }}
                    <br />
                    <a
                      :href="'/hairsalon/searchresult'"
                      class="text-decoration-none text-info"
                    >
                      {{ $t("settingpage.find_shop") }}
                    </a>
                  </p>
                </v-sheet>
              </div>
              <div class="booking" v-else>
                <div
                  v-for="reservation in reserveList"
                  :key="reservation.id"
                  @click="goToBookingDetails(reservation.id)"
                >
                  <AppointmentListCard
                    :eachData="reservation"
                  ></AppointmentListCard>
                </div>
              </div>
            </v-window-item>

            <v-window-item value="history" @scroll="handleScroll('history')">
              <div v-if="historyList.length == 0">
                <v-sheet
                  elevation="0"
                  max-width="600"
                  rounded="lg"
                  width="100%"
                  class="pa-4 text-center mx-auto"
                >
                  <v-icon
                    class="mb-5"
                    color="blue"
                    icon="mdi-calendar"
                    size="112"
                  ></v-icon>
                  <h2 class="text-h5 mb-6">
                    {{ $t("settingpage.no_booking") }}
                  </h2>
                  <p class="mb-4 text-medium-emphasis text-body-2">
                    {{ $t("settingpage.like_shop_desc") }}
                    <br />
                    <a
                      :href="'/hairsalon/searchresult'"
                      class="text-decoration-none text-info"
                    >
                      {{ $t("settingpage.find_shop") }}
                    </a>
                  </p>
                </v-sheet>
              </div>

              <div class="booking" v-else>
                <div
                  v-for="history in historyList"
                  :key="history.id"
                  @click="goToBookingDetails(history.id)"
                >
                  <AppointmentListCard
                    :eachData="history"
                  ></AppointmentListCard>
                </div>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-sheet>
      <v-dialog v-model="loadingDialog" persistent width="300">
        <v-sheet height="190" class="text-center">
          <v-progress-circular
            :indeterminate="loadingDialog"
            :size="100"
            color="blue"
            class="mt-12"
            >{{ $t("homeview.loading") }}
          </v-progress-circular>
        </v-sheet>
      </v-dialog>
    </v-sheet>
  </v-main>
</template>
<style>
.booking {
  width: 100%;
  max-width: 400px;
  cursor: pointer;
}
</style>
<script>
import { mapActions } from "vuex";
import AppointmentListCard from "@/components/Booking/AppointmentListCard.vue";

export default {
  components: {
    AppointmentListCard,
  },
  data() {
    return {
      tab: "reserve",
      reserveList: [],
      historyList: [],
      currentReservePage: 1,
      currentHistoryPage: 1,
      perPage: 6,
      hasMoreReserves: true,
      hasMoreHistory: true,
      loadingDialog: true,
      loadingReserve: false,
      loadingHistory: false,
    };
  },

  async mounted() {
    await this.fetchReservations(true, "reserve");
    await this.fetchReservations(false, "history");
    this.loadingDialog = false;
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions({
      listUserReservation: "reservationStore/listUserReservation",
      requestUserReservations: "reservationStore/requestUserReservations",
    }),
    goToBookingDetails(reservationId) {
      this.$router.push(`/bookingdetails/${reservationId}`);
    },

    async fetchReservations(isReserveList = true, tab) {
      if (isReserveList) {
        this.loadingReserve = true;
      } else {
        this.loadingHistory = true;
      }
      if (isReserveList && !this.hasMoreReserves) return;
      if (!isReserveList && !this.hasMoreHistory) return;

      const currentPage = isReserveList
        ? this.currentReservePage
        : this.currentHistoryPage;

      const params = {
        page: currentPage,
        per_page: this.perPage,
        tab: tab,
        // sort: "-starts_at",
        // from_starts_at: uitls.formatDate(new Date(), "yyyy-MM-dd"),
      };

      await this.$store
        .dispatch("reservationStore/requestUserReservations", params)
        .then((response) => {
          if (response.reservations) {
            const currentReservations = response.reservations.filter(
              (reservation) =>
                reservation.status === "requested" ||
                reservation.status === "reserved"
            );
            const historyReservations = response.reservations.filter(
              (reservation) =>
                reservation.status !== "requested" &&
                reservation.status !== "reserved"
            );
            if (isReserveList) {
              if (currentReservations.length) {
                this.reserveList = [
                  ...this.reserveList,
                  ...currentReservations,
                ];
                this.currentReservePage++;
              } else {
                this.hasMoreReserves = false;
              }
              this.loadingReserve = false;
            } else {
              if (historyReservations.length) {
                this.historyList = [
                  ...this.historyList,
                  ...historyReservations,
                ];
                this.currentHistoryPage++;
              } else {
                this.hasMoreHistory = false;
              }
              this.loadingHistory = false;
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    async handleScroll() {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const bottomOfWindow =
        Math.ceil(scrollPosition + windowHeight) >= documentHeight;

      if (bottomOfWindow) {
        if (this.tab === "reserve" && !this.loadingReserve) {
          this.loadingDialog = true;
          await this.fetchReservations(true, "reserve");
          this.loadingDialog = false;
        } else if (this.tab === "history" && !this.loadingHistory) {
          this.loadingDialog = true;
          await this.fetchReservations(false, "history");
          this.loadingDialog = false;
        }
      }
    },
  },
};
</script>
