<template>
  <v-sheet elevation="1" rounded="lg" class="pa-0 ma-2">
    <v-row no-gutters class="pa-2">
      <v-col cols="4" md="4" align-self="start">
        <v-img
          src="/title.jpg"
          aspect-ratio="1"
          height="120"
          max-width="240"
          cover
        ></v-img>
      </v-col>

      <v-col cols="8" md="8">
        <v-chip class="mx-2" size="small" color="red" variant="outlined"
          >voucher</v-chip
        >
        <p v-snip="{ lines: 1 }" class="text-h6 mx-2" style="font-weight: bold">
          {{ item.title }}
          <span>RM000</span>
        </p>

        <p
          v-snip="{ lines: 1 }"
          class="text-subtitle-2 mx-2"
          style="font-weight: normal"
        >
          Eligible: {{ item.eligible }}
        </p>
        <p
          v-snip="{ lines: 1 }"
          class="text-subtitle-2 mx-2"
          style="font-weight: normal"
        >
          Style list: All
        </p>
        <p
          v-snip="{ lines: 1 }"
          class="text-subtitle-2 mx-2"
          style="font-weight: normal"
        >
          Expired Date: No
        </p>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      no-gutters
      class="mt-0"
      v-if="showBookBtn === 'yes'"
    >
      <v-btn
        elevation="0"
        color="clear"
        width="90%"
        style="font-weight: bold"
        variant="outlined"
        class="text-green mt-4"
        height="50px"
        :ripple="false"
        :to="{ name: 'ReservationForm', params: { id: '1' } }"
        >Book This Appointment Now</v-btn
      >
      <v-col class="mb-3">
        <ShareWithFriend />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import ShareWithFriend from "@/components/ShareWithFriend.vue";

export default {
  props: ["item", "showBookBtn"],

  components: { ShareWithFriend },

  data() {
    return {
      priceList: [
        {
          title: "Cut + Color",
          eligible: "New customer only",
          desc: "Leekaja Beauty Salon is the best Korean hair salon in Malaysia",
          price: " RM100",
        },
        {
          title: "Color",
          eligible: "All",
          desc: "Leekaja Beauty Salon is the best Korean hair salon in Malaysia",
          price: "RM250",
        },
        {
          title: "Blow",
          eligible: "New customer only",
          desc: "Lea ean hair salon in Malaysia",
          price: "RM50",
        },
      ],
    };
  },
};
</script>

<style>
.fb-btn.v-btn--outlined {
  border: thin solid #cccccc;
}
</style>