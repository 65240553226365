<template>
  <v-container fluid>
    <v-sheet
      elevation="1"
      max-width="580"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto mt-6"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-check-circle"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">{{ $t("reservation.reserve_done") }}</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        {{ $t("reservation.reserve_desc_1") }}
        <b class="text-info" style="cursor: pointer" @click="viewReserveInfo">
          {{ $t("reservation.reserve_desc_2") }}
        </b>
        <br />
        {{ $t("reservation.reserve_desc_3") }}
      </p>

      <v-divider class="mb-4"></v-divider>

      <v-sheet>
        <v-row justify="start" class="ma-2">
          <p class="text-body-1" style="font-weight: bold">
            {{ $t("reservation.reserve_id") }}: {{ userReservation.id }}
          </p>
        </v-row>
        <v-row justify="start" class="ma-2">
          <p class="text-body-1" style="font-weight: bold">
            {{ $t("reservation.reserve_date") }}:
            {{ userReservation.created_at }}
          </p>
        </v-row>
      </v-sheet>

      <v-row justify="start" class="ma-2 mt-12">
        <p class="text-body-1" style="font-weight: bold">
          {{ $t("reservation.reserve_detail") }}
        </p>
      </v-row>
      <v-list :lines="false" align="start">
        <v-list-item v-for="(item, i) in bookingTitle" :key="i" :value="item">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <p class="text-body-1 text-grey" style="font-weight: normal">
            {{ item.title }}
          </p>
          <p class="text-h6" style="font-weight: normal">{{ item.subtitle }}</p>
        </v-list-item>
      </v-list>

      <v-row justify="start" class="ma-2 mt-12">
        <p class="text-body-1" style="font-weight: bold">
          {{ $t("reservation.payment_summary") }}
        </p>
      </v-row>
      <v-row justify="start" class="ma-2 mt-0">
        <v-col cols="2">
          <p style="font-weight: normal">{{ $t("reservation.totalAmount") }}</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <p>RM{{ parseInt(totalAmount, 10).toLocaleString() }}</p>
        </v-col>
      </v-row>

      <div class="text-end">
        <v-btn
          color="success"
          rounded
          variant="flat"
          width="90"
          :to="{ name: 'MyPage' }"
        >
          {{ $t("busienssPage.done") }}
        </v-btn>
      </div>
    </v-sheet>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-sheet height="190" class="text-center">
        <v-progress-circular
          :indeterminate="loadingDialog"
          :size="100"
          color="blue"
          class="mt-12"
        >
          {{ $t("homeview.loading") }}
        </v-progress-circular>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loadingDialog: false,
      bookingTitle: [],
      userReservation: {},
      totalAmount: 0,
    };
  },
  async mounted() {
    this.loadingDialog = true;
    if (this.$route.query.ur_id) {
      await this.getUserReserveDetail();
    } else {
      this.$router.push({
        name: "HomeView",
      });
    }
    this.loadingDialog = false;
  },
  methods: {
    async getUserReserveDetail() {
      const tobeSubmit = {
        user_reservation_id: this.$route.query.ur_id,
      };
      this.bookingTitle = [];
      this.totalAmount = 0;
      await this.$store
        .dispatch("reservationStore/fetchReservationDetail", tobeSubmit)
        .then((response) => {
          if (response) {
            this.userReservation = response;

            if (this.userReservation.shop) {
              this.bookingTitle.push({
                title: this.$i18n.tc("shop.shop_name"),
                icon: "mdi-store",
                subtitle: this.userReservation.shop.name,
              });
            }
            if (
              this.userReservation.items &&
              this.userReservation.items.length > 0
            ) {
              const menuItem = this.userReservation.items[0];
              if (menuItem.starts_at_1) {
                this.bookingTitle.push({
                  title: this.$i18n.tc("reservation.first_choice"),
                  icon: "mdi-calendar",
                  subtitle: menuItem.starts_at_1,
                });
              }
              if (menuItem.starts_at_2) {
                this.bookingTitle.push({
                  title: this.$i18n.tc("reservation.second_choice"),
                  icon: "mdi-calendar",
                  subtitle: menuItem.starts_at_2,
                });
              }
              if (menuItem.starts_at_3) {
                this.bookingTitle.push({
                  title: this.$i18n.tc("reservation.third_choice"),
                  icon: "mdi-calendar",
                  subtitle: menuItem.starts_at_3,
                });
              }

              this.userReservation.items.forEach((item) => {
                if (item.menu) {
                  this.totalAmount += item.menu.amount;
                }
              });
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    viewReserveInfo() {
      this.$router.push(`/bookingdetails/${this.$route.query.ur_id}`);
    },
  },
};
</script>

<style></style>