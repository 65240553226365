import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ArticleStore from "@/store/modules/ArticleStore";
import crowdStore from "@/store/modules/crowdStore";
import CheckUserEmailStore from "@/store/modules/CheckUserEmailStore";
import authStore from '@/store/modules/authStore';
import UserDetailsStore from '@/store/modules/UserDetailsStore';
import userStore from '@/store/modules/UserStore';
import shopStore from '@/store/modules/shopStore';
import staffStore from '@/store/modules/staffStore';
import troubleStore from '@/store/modules/troubleStore';
import interestStore from '@/store/modules/interestStore';
import CrowdfundingSalesOrderStore from '@/store/modules/CrowdfundingSalesOrderStore';
import ShopRegisterStore from "@/store/modules/ShopRegisterStore";
import reservationStore from "@/store/modules/reservationStore";
import SponsorStore from "@/store/modules/SponsorStore";


export default createStore({
    state: {},
    mutations: {},
    actions: {},
    strict: process.env.NODE_ENV !== 'production',
    plugins: [createPersistedState()],
    modules: {
      ArticleStore,
      ShopRegisterStore,
      crowdStore,
      CheckUserEmailStore,
      authStore,
      userStore,
      UserDetailsStore,
      shopStore,
      staffStore,
      troubleStore,
      interestStore,
      CrowdfundingSalesOrderStore,
      reservationStore,
      SponsorStore
    },
})