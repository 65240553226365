<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-app-bar-title style="font-weight: bold">
      {{ $t("searchfilter.search") }}</v-app-bar-title
    >
    <v-btn
      elevation="0"
      density="comfortable"
      icon="mdi-close"
      :ripple="false"
      @click="closeSearchOnClick"
    ></v-btn>
  </v-app-bar>

  <v-main>
    <v-sheet
      class="pa-0 ma-0 mx-auto mt-0"
      color="white"
      max-width="1200"
      rounded="lg"
      width="100%"
      elevation="0"
    >
      <!-- <v-tabs
        class="mt-4"
        v-model="tab"
        bg-color="clear"
        color="blue"
        center-active
        grow
      >
        <v-tab value="tab-1" :ripple="false"
          ><v-icon start icon="mdi-magnify" />
          <p class="text-subtitle-1">{{ $t("searchfilter.basic") }}</p></v-tab
        >
        <v-tab value="tab-2" :ripple="false"
          ><v-icon start icon="mdi-more" />
          <p class="text-subtitle-1">{{ $t("searchfilter.more") }}</p></v-tab
        >
      </v-tabs> -->

      <v-window v-model="tab" :touch="false">
        <v-window-item value="tab-1">
          <v-container fluid>
            <v-text-field
              class="ma-4"
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              v-model="searchKeyword"
              clearable
              hide-details
            >
              <v-field-label style="font-weight: normal">{{
                $t("searchfilter.keyword")
              }}</v-field-label>
            </v-text-field>

            <v-select
              prepend-inner-icon="mdi-map-marker-outline"
              transition="none"
              class="mt-6 ma-3"
              variant="outlined"
              :label="$t('shop.prefecture')"
              :items="locationTags"
              v-model="selectState"
              :item-title="(locationTags) => $t(locationTags.title)"
              return-object
            ></v-select>

            <v-select
              prepend-inner-icon="mdi-pound"
              transition="none"
              class="mt-n3 ma-3"
              :label="$t('searchfilter.tag')"
              :items="beautyServiceTags"
              :item-title="(beautyServiceTags) => $t(beautyServiceTags)"
              variant="outlined"
              chips
              multiple
              v-model="tagSelected"
            ></v-select>

            <v-select
              class="mt-n3 ma-3"
              prepend-inner-icon="mdi-clock-time-eight-outline"
              transition="none"
              :label="$t('searchfilter.available_time')"
              :items="timeList"
              variant="outlined"
              v-model="timeSelected"
            ></v-select>

            <!-- <v-row dense class="mt-2 ma-3">
              <v-col cols="6">
                <v-text-field
                  v-model="trip.start"
                  :label="$t('searchfilter.available_date')"
                  type="date"
                  variant="underlined"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  prepend-inner-icon="mdi-clock-time-eight-outline"
                  transition="none"
                  :label="$t('searchfilter.available_time')"
                  :items="timeList"
                  variant="outlined"
                  v-model="timeSelected"
                ></v-select>
              </v-col>
            </v-row> -->

            <!-- <v-row class="ma-1">
            <v-col cols="12" class="text-subtitle-2 text-grey-darken-3"
              ><p>Service</p></v-col
            >
            <v-col
              cols="16"
              sm="4"
              md="2"
              v-for="n in beautyServiceTags"
              :key="n"
            >
              <v-checkbox
                class="mt-n6"
                :label="n"
                color="green"
                value="red"
              ></v-checkbox>
            </v-col>
          </v-row> -->

            <!-- <v-row dense>
          <v-col cols="6">
            <v-select
              transition="none"
              class="mt-6 ma-4"
              label="Min Price(RM)"
              :items="priceList"
              variant="underlined"
            ></v-select>
          </v-col>

          <v-col cols="6">
            <v-select
              transition="none"
              class="mt-6 ma-4"
              label="Max Price(RM)"
              :items="priceList"
              variant="underlined"
            ></v-select>
          </v-col>
        </v-row> -->

            <v-row class="ma-1 mt-n6">
              <v-col cols="12" class="text-subtitle-2 text-grey-darken-3"
                ><p>{{ $t("searchfilter.price_range") }}</p>
                <p class="text-subtitle-1" v-if="priceRange[0] > 0 || priceRange[1] < 1000 ">
                  RM{{ priceRange[0] }} - RM{{ priceRange[1] }}
                  <span class="text-caption text-red">{{ $t('searchfilter.some_shop_not_show') }}</span> 
                </p></v-col
              >
              <v-col cols="12" class="mt-2">
                <v-range-slider
                  color="blue"
                  class="align-center"
                  :max="1000"
                  :min="0"
                  :step="10"
                  v-model="priceRange"
                  thumb-label="always"
                ></v-range-slider>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>

        <v-window-item value="tab-2">
          <v-select
            transition="none"
            class="mt-6 ma-4"
            label="Gender"
            :items="genderTags"
            variant="underlined"
          ></v-select>

          <v-select
            transition="none"
            class="mt-6 ma-4"
            label="Hair Issue"
            :items="issueTags"
            variant="underlined"
          ></v-select>

          <v-select
            transition="none"
            class="mt-6 ma-4"
            label="Hair Style"
            :items="hairLenghtTags"
            variant="underlined"
          ></v-select>

          <v-select
            transition="none"
            class="mt-6 ma-4"
            label="Tags"
            :items="otherTags"
            variant="underlined"
          ></v-select>

          <v-select
            transition="none"
            class="mt-6 ma-4"
            label="TrainStation"
            :items="locationTags"
            variant="underlined"
          ></v-select>
        </v-window-item>
      </v-window>

      <v-row justify="center" no-gutters>
        <v-btn
          prepend-icon="mdi-magnify"
          elevation="0"
          color="blue"
          width="93%"
          class="pa-0"
          height="50px"
          :ripple="false"
          rounded="pill"
          @click="startSearchOnClick"
        >
          {{ $t("searchfilter.search") }}
        </v-btn>
      </v-row>
    </v-sheet>
  </v-main>
</template>

<script>
import moment from "moment";
import Uitls from "@/uitls/Uitls";

export default {
  components: {},

  computed: {
    locationTags() {
      return Uitls.locationTags();
    },
  },

  data() {
    return {
      searchKeyword: "",
      priceRange: [0, 1000],
      tab: "tab-" + this.openPage,
      selectState: { title: "prefecture.all_area", value: 0 },
      tagSelected: [],
      timeSelected: "",
      beautyServiceTags: Uitls.GPTSummaryTags(),
      timeList: Uitls.timeList(),
      pickedDate: new Date(),
      genderTags: ["All", "Male", "Female", "Kids"],
      trip: {
        name: "",
        location: null,
        start: null,
        end: null,
      },

      priceList: [
        "Any",
        "RM10",
        "RM20",
        "RM30",
        "RM40",
        "RM50",
        "RM60",
        "RM70",
        "RM80",
        "RM90",
        "RM100",
        "RM200",
        "RM300",
        "RM400",
        "RM500",
      ],
      issueTags: ["All", "Frizzy Hair", "Dry Hair", "Thinning Hair"],

      hairLenghtTags: [
        "All",
        "Very Short",
        "Short",
        "Medium",
        "Semi Long",
        "Long",
      ],

      otherTags: [
        "All",
        "Luxury",
        "Free cut Model",
        "SNS(TikTik, Instagram)",
        "Low Budget",
        "Japan Style",
        "Korea Style",
        "Family Friendly",
        "Islam Friendly",
        "Discount",
      ],
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    startSearchOnClick() {
      const searchFilterObjects = {
        searchKeyword: this.searchKeyword,
        stateSelected: this.selectState.value,
        tagSelected: this.tagSelected,
        timeSelected: this.timeSelected,
        priceRange: this.priceRange,
      };

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },

    closeSearchOnClick() {
      this.searchKeyword = "";
      this.stateSelected = { title: "prefecture.all_area", value: 0 };
      this.tagSelected = [];
      this.timeSelected = "";

      const searchFilterObjects = {
        searchKeyword: this.searchKeyword,
        stateSelected: this.selectState.value,
        tagSelected: this.tagSelected,
        timeSelected: this.timeSelected,
        priceRange: this.priceRange
      };

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },

    dateSpecify() {
      // console.log("Btn OnCLick : " + row + " / " + col);
      // this.selectTimeBtn =  row + " / " + col;
    },

    resetDate() {
      this.pickedDate = new Date();
    },

    dateTomorrow() {
      const todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + 1);
      this.pickedDate = todayDate;
    },
  },
};
</script>

<style>
</style>