<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-container class="pa-0 mt-4">
      <HairCatalog />

      <p class="text-h5 mx-2 mt-10" style="font-weight: bold">
        <v-icon
          icon="mdi-shape-plus"
          color="deep-orange"
          size="24px"
          class="mb-1"
        ></v-icon>
        {{ $t("homeview.service") }}
      </p>

      <v-row no-gutters warp justify="left">
        <v-col v-for="item in categoryList" :key="item.name" cols="6" md="2">
          <v-card
            flat
            class="text-left ma-3"
            color="white"
            elevation="1"
            @click="chipClick(item.tag)"
          >
            <v-responsive class="pt-0">
              <v-img
                class="ma-6"
                max-height="90%"
                :src="item.image"
                aspect-ratio="1.0"
              ></v-img>
              <v-card-text>
                <p class="text-h6 text-center" style="font-weight: bold">
                  {{ item.name }}
                </p>
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>

      <p class="text-h5 mx-2 mt-10" style="font-weight: bold">
        <v-icon
          icon="mdi-book"
          color="deep-orange"
          size="24px"
          class="mb-1"
        ></v-icon>
        By Issue
      </p>

      <v-row center warp justify="left">
        <v-col v-for="item in issueList" :key="item.name" cols="6" md="2">
          <v-card
            flat
            class="text-left ma-3"
            color="white"
            elevation="1"
            @click="chipClick(item.name)"
          >
            <v-responsive class="pt-0">
              <v-img
                class="ma-6"
                max-height="90%"
                :src="item.image"
                aspect-ratio="1.0"
              ></v-img>
              <v-card-text>
                <p class="text-center text-h6">{{ item.name }}</p>
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>

      <p class="text-h5 mx-2 mt-10" style="font-weight: bold">
        <v-icon
          icon="mdi-book"
          color="deep-orange"
          size="24px"
          class="mb-1"
        ></v-icon>
        Other Categories
      </p>

      <v-row no-gutters warp justify="center">
        <v-col cols="12">
          <v-chip-group>
            <v-chip
              v-for="tagItem in tagList"
              :key="tagItem"
              variant="outlined"
              class="ma-2"
              color="primary"
              @click="chipClick(tagItem)"
            >
              #{{ tagItem }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>

      <!-- <v-row center warp justify="left">
        <v-col v-for="item in otherList" :key="item.name" cols="6" md="2">
          <v-card flat class="text-left ma-3" color="grey-lighten-3">
            <v-responsive class="pt-0">
              <v-img
                max-height="90%"
                :src="item.image"
                cover
                aspect-ratio="1.2"
              ></v-img>
              <v-card-text>
                <p class="text-center text-h6">{{ item.name }}</p>
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </v-sheet>
</template>

<script>
import HairCatalog from "@/components/HairCatalog.vue";
import SearchBar from "@/components/SearchBar.vue";
import Uitls from "@/uitls/Uitls";
import { useHead } from "@vueuse/head";

export default {
  components: { HairCatalog, SearchBar },

  setup() {
    useHead({
      meta: [
        {
          name: "description",
          content: "Search latest hair catalog in beauty 360 salon now",
        },
        {
          name: "keywords",
          content: "long hair,hair catalog",
        },
      ],
    });
  },

  data() {
    return {
      tagList: Uitls.GPTSummaryTags(),
      categoryList: [
        { name: "shopprice.color", tag: "color", image: "/hair-dye.png" },
        { name: "shopprice.prem", tag: "prem", image: "/hairdryer.png" },
        { name: "shopprice.cut", tag: "cut", image: "/baber-shop.png" },
        { name: "shopprice.facial", tag: "facial", image: "/facial.png" },
        {
          name: "shopprice.treatment",
          tag: "treatment",
          image: "/hair-dye2.png",
        },
        { name: "shopprice.eyelash", tag: "eyelash", image: "/eyelashes.png" },
        {
          name: "shopprice.head_spa",
          tag: "head spa",
          image: "/hot-stones.png",
        },
      ],
      issueList: [
        { name: "Frizzy Hair", image: "/hair-extension.png" },
        { name: "Dry Hair", image: "/skin.png" },
        { name: "Thinning Hair", image: "/hair-loss.png" },
      ],
      otherList: [
        { name: "Luxury", image: "/fuzzy.jpeg" },
        { name: "SNS(TiKTok,Instagram)", image: "/dry.jpeg" },
        { name: "Family Friendly", image: "/thinning.jpeg" },
        { name: "Islam Friendly", image: "/thinning.jpeg" },
        { name: "Low Budget", image: "/thinning.jpeg" },
        { name: "Japan Style", image: "/thinning.jpeg" },
        { name: "Korea Style", image: "/thinning.jpeg" },
        { name: "Free cut model", image: "/thinning.jpeg" },
        { name: "Disscount over 30%", image: "/thinning.jpeg" },
      ],
    };
  },

  methods: {
    chipClick(value) {
      const searchFilterObjects = {
        searchKeyword: value,
      };

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },
  },
};
</script>

<style scoped></style>