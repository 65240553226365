<!-- https://qiita.com/rubytomato@github/items/07fe07e64482f8f03ef3 -->

<template>
  <v-sheet class="pa-0 mt-0" color="white">
    <br />
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-store"
        color="blue-darken-1"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t(title) }}
    </p>

    <v-container class="pa-0 mt-4">
      <v-row no-gutters warp justify="center" class="d-flex">
        <v-col v-for="shop in shops" :key="shop" cols="6" xs="3" sm="3" md="3">
          <router-link
            :to="{
              name: 'Shop',
              params: { id: shop.shop_id, openPage: '1', scrollPage: '0' },
            }"
            style="text-decoration: none; color: inherit"
          >
            <v-card
              flat
              elevation="2"
              class="text-left ma-3"
              color="white"
              :ripple="false"
              @click="onClick(n)"
            >
              <v-responsive class="pt-0">
                <v-img
                  height="90%"
                  :lazy-src="local_shop"
                  :src="shop.shop_img_small"
                  class="fill-width"
                  cover
                  :aspect-ratio="this.isMobileDevice.value ? 1 : 16 / 9"
                  :alt="shop.shop_title"
                ></v-img>
              </v-responsive>

              <v-card-text class="mt-n4">
                <div
                  v-snip="{ lines: 2 }"
                  class="text-body-2 mb-4"
                  style="font-weight: normal"
                >
                  {{ shop.shop_title }}
                </div>

                <div class="mt-2" v-if="shop.shop_meter">
                  <v-icon
                    icon="mdi-map-marker-distance"
                    color="black"
                    size="16"
                    class="mx-0 mt-n1"
                  /><span style="font-size: 12px" class="mx-2"
                    >{{ shop.shop_meter.toFixed(1) }}km</span
                  >
                </div>

                <div class="mt-1">
                  <v-icon
                    icon="mdi-map-marker"
                    size="16"
                    color="black"
                    class="mx-0 mt-1"
                  />
                  <span
                    class="mx-2"
                    style="font-size: 12px; font-weight: normal"
                    >{{ $t(prefectureCodeToString(shop.shop_prefecture)) }}
                  </span>
                </div>

                <div class="mt-2">
                  <v-icon
                    icon="mdi-briefcase"
                    color="black"
                    size="16"
                    class="mx-0 mt-n1"
                  /><span style="font-size: 12px" class="mx-2"
                    >{{ shop.shop_opening_times }}~{{
                      shop.shop_closing_times
                    }}</span
                  >
                </div>
                <div class="mt-2">
                  <v-icon
                    icon="mdi-google"
                    color="black"
                    size="16"
                    class="mx-0 mt-n1"
                  />
                  <span style="font-size: 12px" class="mx-2 text-black">
                    <v-icon
                      icon="mdi-star"
                      color="black"
                      size="12"
                      class="mx-0"
                    />
                    {{ shop.os_rating }} ({{ shop.os_reviews }})</span
                  >
                </div>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>

    <div align="center" v-if="haveCoordinates">
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
        :to="{ name: 'GMapView' }"
      >
        {{ $t("homeview.show_more") }}
      </v-btn>
    </div>

    <div align="center" v-else>
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
        @click="showMoreOnClick"
      >
        {{ $t("homeview.show_more") }}
      </v-btn>
    </div>
  </v-sheet>
</template>

<script scoped>
import Constants from "@/uitls/Constants";
import Uitls from "@/uitls/Uitls";

export default {
  props: ["coordinates"],

  data() {
    return {
      title: "homeview.near_by",
      shops: [],
      local_shop: require("@/assets/img/logo120.png"),
      haveCoordinates: true,
    };
  },

  created() {},

  async mounted() {
    const coordinates = this.$cookies.get("coordinates");
    if (Uitls.isEmpty(coordinates)) {
      this.haveCoordinates = false;
      this.loadNormalShop();
    } else {
      this.haveCoordinates = true;
      this.loadLocationShop(coordinates);
    }
  },

  methods: {
    onClick(value) {
      // console.log("click value: " + value);
      this.$emit("shopId", "shopId");
    },

    prefectureCodeToString(code) {
      return Uitls.prefectureCodeToString(code);
    },

    showMoreOnClick() {
      this.$router.push({
        name: "SearchResult",
      });
    },

    async loadNormalShop() {
      this.title = "homeview.discover_shop";
      const params = {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        page: 1,
        per_page: 4,
        sort: "-id",
      };

      await this.$store
        .dispatch("shopStore/fetchShops", params)
        .then((response) => {
          const shopData = [];
          if (response && response.data) {
            response.data.forEach((shop, i) => {
              if (shop.status) {
                var shop_img_small = Constants.NO_IMAGE;
                if (shop.pictures.length > 0) {
                  shop_img_small = shop.pictures[0].picture_url_small;
                }

                shopData.push(Uitls.setShopDetails(shop, shop_img_small));
              }
            });
          }
          this.shops = shopData;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async loadLocationShop(coordinates) {
      this.title = "homeview.near_by";
      const params = {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        page: 1,
        per_page: 4,
      };

      await this.$store
        .dispatch("shopStore/locationSearchShop", params)
        .then((response) => {
          if (response && response.data) {
            if (response.data.length == 0) {
              this.loadNormalShop();
              return;
            }
            const shopData = [];
            response.data.forEach((shop) => {
              if (shop.status) {
                var shop_img_small = Constants.NO_IMAGE;
                if (shop.pictures.length > 0) {
                  shop_img_small = shop.pictures[0].picture_url_small;
                }

                shopData.push(Uitls.setShopDetails(shop, shop_img_small));
              }
            });
            this.shops = shopData;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  computed: {},
};
</script>

<style></style>