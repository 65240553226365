<template>
  <v-app-bar
    v-if="isNewUser == 0"
    scroll-behavior="elevate"
    color="grey-lighten-4"
  >
    <v-btn class="ma-0" icon="mdi-arrow-left" @click="$router.push('/settingpage')"></v-btn>
    <v-app-bar-title>Trouble</v-app-bar-title>
  </v-app-bar>
  <v-main>
    <v-item-group multiple v-model="itemSelect">
      <v-container fluid>
        <v-sheet
          elevation="0"
          max-width="1200"
          rounded="lg"
          width="100%"
          class="pa-0 mx-auto mt-6"
        >
          <v-row
            :align="isMobileDevice.value ? 'stretch' : 'center'"
            justify="center"
          >
            <v-col v-if="isNewUser == 1" cols="12">
              <p class="text-h6" style="font-weight: bold">
                You are almost done
              </p>
              <p class="text-caption">
                Please select the following items and continue to tell us your worries.
              </p>
            </v-col>

            <v-col v-for="item in troublesCategory" :key="item" cols="4" md="4">
              <v-item v-slot="{ isSelected, toggle }">
                <v-card
                  flat
                  :color="isSelected ? 'blue' : ''"
                  class="align-center"
                  dark
                  :max-height="isMobileDevice.value ? '100%' : '60%'"
                  :max-width="isMobileDevice.value ? '100%' : '60%'"
                  @click="toggle"
                >
                  <v-responsive class="pt-0">
                    <v-img
                      cover
                      class="fill-width text-right pa-0"
                      aspect-ratio="1"
                      :src="item.image"
                      :lazy-src="local_logo"
                    >
                      <v-btn
                        variant="text"
                        size="sm"
                        :ripple="false"
                        color="blue"
                        :icon="
                          isSelected
                            ? 'mdi-check-circle'
                            : 'mdi-check-circle-outline'
                        "
                      ></v-btn>
                    </v-img>
                  </v-responsive>

                  <v-card-text
                    class="mt-n0 text-center"
                    style="overflow-y: auto; height: 20"
                  >
                    {{ item.label }}
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>

          <v-row justify="center" no-gutters>
            <v-btn
              v-if="isNewUser == 1"
              elevation="0"
              color="blue"
              width="93%"
              class="ma-6"
              height="50px"
              :ripple="false"
              rounded="pill"
              :loading="loading"
              @click="newUserOnClick"
            >
              Next
            </v-btn>
            <v-btn
              v-else
              elevation="0"
              color="blue"
              width="93%"
              class="ma-6"
              height="50px"
              :ripple="false"
              rounded="pill"
              :loading="loading"
              @click="itemUpdateOnClick"
            >
              Submit
            </v-btn>
          </v-row>
        </v-sheet>
      </v-container>
    </v-item-group>
  </v-main>
  <VSonner position="top-center" />
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { VSonner, toast } from "vuetify-sonner";

export default {
  components: {
    VSonner,
  },

  props: ["isNewUser"],

  computed: {
    ...mapGetters({
      userInfo: "userStore/userInfo",
      userTroubleIds: "authStore/trouble_ids",
    }),
  },

  data: () => ({
    itemSelect: [],
    troublesCategory: [],
    local_logo: require("@/assets/img/logo120.png"),
  }),

  beforeMount() {
    this.getTroubleItems();
  },

  mounted() {},

  methods: {
    async getTroubleItems() {
      await this.$store
        .dispatch("troubleStore/fetchAll")
        .then((response) => {
          const troubleData = [];
          response.data.forEach((trouble) => {
            troubleData.push({
              id: trouble.id,
              image: trouble.picture_url,
              label: trouble.name,
              value: trouble.id,
            });
          });
          troubleData.reverse();
          this.troublesCategory = troubleData;

          if (!this.isNewUser == 0) {
            this.checkUserTroubleSelect();
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },

    checkUserTroubleSelect() {
      if (this.userTroubleIds.length > 0) {
        console.log("---checkUserTroubleSelect---"+this.userTroubleIds.length)
        console.log(this.userTroubleIds)
     
        for (let i = 0; i < this.troublesCategory.length; i++) {
          const element = this.troublesCategory[i].id;

          const result = this.userTroubleIds.find(({ trouble_id }) => trouble_id === element);
          if (result) {
            this.itemSelect.push(i);
          }
        }
      }
    },

    newUserOnClick() {
      this.checkSelectItem();
    },

    itemUpdateOnClick() {
      this.checkSelectItem();
    },

    async checkSelectItem() {
      if (this.itemSelect.length <= 0) {
        toast("Please select item", {
          cardProps: {
            color: "error",
            class: "my-toast",
          },
        });
        return;
      } else {
        const trouble = [];

        for (let i = 0; i < this.itemSelect.length; i++) {
          const element = this.itemSelect[i];
          trouble.push(this.troublesCategory[element].id);
        }

        await this.$store
          .dispatch("troubleStore/updateUserTrouble", {
            trouble_ids: trouble,
          })
          .then((response) => {
            console.log(response);

            this.$store.commit("authStore/SET_TROUBLE", response);

            if (this.isNewUser == 0) {
              toast("Information updated.", {
                cardProps: {
                  color: "success",
                  class: "my-toast",
                },
              });

              setTimeout(() => {
                this.$router.push({ name: "SettingPage" });
              }, 3000);
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 10px;
}
</style>