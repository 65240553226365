<!-- https://qiita.com/mml/items/7bdffe5eb5044f861cf1 -->
<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <div class="bg"></div>

    <v-card elevation="0" class="pa-4 mt-8" color="transparent">
      <h1>
        <span
          class="text-h4 text-left text-grey-darken-3"
          style="font-weight: bold"
        >
          {{ $t("homeview.find_your_salon") }}
        </span>
      </h1>

      <!-- Search -->
      <v-sheet
        height="auto"
        class="mt-12 searchbg pa-4 ma-0"
        color="white"
        rounded="lg"
      >
        <!-- AutoCount -->
        <p
          class="text-h6 text-blue"
          style="font-weight: bold"
          v-if="shopCount > 0"
        >
          <v-icon
            icon="mdi-store"
            class="mt-n1"
            color="black"
            size="small"
          ></v-icon>
          <span class="mx-1 text-black text-subtitle-2">
            {{ $t("homeview.number_ai") }}
          </span>
          <vue3-autocounter
            class="text-h5 mx-n1"
            ref="counter"
            :startAmount="0"
            :endAmount="shopCount"
            :duration="1"
            prefix=""
            suffix=""
            separator=","
            :decimals="0"
            :autoinit="true"
          >
          </vue3-autocounter>
          <span class="text-caption text-black mx-2">
            ({{ currentDate() }})
          </span>
        </p>
        <!-- location -->
        <v-btn
          class="mt-2"
          size="small"
          prepend-icon="mdi-map-marker"
          elevation="0"
          color="grey-lighten-5"
          :ripple="false"
          @click="areaSelectOnclick"
        >
          {{ createAreaCityHint() }}
        </v-btn>

        <!-- Search -->
        <v-row dense class="mt-4">
          <v-col cols="10">
            <!-- <v-text-field v-model="searchKeyword" width="100%" variant="outlined"
              @keydown.enter.prevent="searchSubmit(this.searchKeyword)" :hint="createAreaCityHint()" persistent-hint>
              <v-field-label style="font-weight: normal" class="text-caption mx-n2">
                {{ $t("homeview.search_keyword") }}
              </v-field-label>
            </v-text-field> -->
            <SearchAutocomplete
              @setAutocompleteResult="setAutocompleteResult"
              @updateAutocompleteItemList="updateAutocompleteItemList"
              :items="autocompleteItemList"
              :isAsync="true"
              :inputLabel="$t('homeview.search_keyword')"
            >
            </SearchAutocomplete>
          </v-col>

          <v-col cols="1">
            <v-btn
              class="mx-n4"
              elevation="0"
              height="4em"
              color="blue-lighten-1"
              prepend-icon="mdi-magnify"
              stacked
              :ripple="false"
              @click="searchSubmit(this.searchKeyword)"
            >
            </v-btn>
          </v-col>
        </v-row>

        <!-- Tags -->
        <v-row no-gutters warp justify="center" class="mt-2">
          <v-col>
            <v-chip-group column>
              <v-chip
                v-for="(tagItem, index) in tagList"
                :key="tagItem"
                variant="text"
                class="ma-0"
                size="small"
                color="blue"
                @click="searchTagSubmit(index)"
                style="font-weight: bold"
              >
                #{{ $t(tagItem) }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-sheet>

      <!-- Menu -->
      <v-item-group
        selected-class="bg-primary"
        align="center"
        justify="center"
        class="mt-4"
      >
        <v-row>
          <v-col
            v-for="item in menuList"
            :key="item.title"
            cols="6"
            sm="3"
            md="3"
          >
            <v-btn
              v-if="item.link == 'SearchMap'"
              rounded="lg"
              height="200"
              width="250"
              elevation="1"
              color="blue-lighten-5"
              :ripple="false"
              :to="{ name: item.link, params: { address: 'Tokyo Station' } }"
            >
              <v-row justify="center">
                <v-col cols="12">
                  <v-avatar
                    color="white"
                    size="100"
                    class="mt-2 pa-4"
                    :image="item.icon"
                  >
                    <v-icon
                      :icon="item.icon"
                      size="60"
                      color="blue-lighten-1"
                    ></v-icon>
                  </v-avatar>

                  <v-sheet
                    color="blue-lighten-1"
                    rounded="pill"
                    class="mx-auto mt-8"
                    height="40"
                    width="150"
                  >
                    <v-row justify="center">
                      <v-col style="font-weight: bold">
                        {{ $t(item.title) }}
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-btn>

            <v-btn
              v-else-if="item.link == 'SearchFilter'"
              rounded="lg"
              height="200"
              width="250"
              elevation="1"
              color="blue-lighten-5"
              :ripple="false"
              @click="openSearchFilterDialog = true"
            >
              <v-row justify="center">
                <v-col cols="12">
                  <v-avatar
                    color="white"
                    size="100"
                    class="mt-2 pa-4"
                    :image="item.icon"
                  />

                  <v-sheet
                    color="blue-lighten-1"
                    rounded="pill"
                    class="mx-auto mt-8"
                    height="40"
                    width="150"
                  >
                    <v-row justify="center">
                      <v-col style="font-weight: bold">
                        {{ $t(item.title) }}
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-btn>

            <v-btn
              v-else
              rounded="lg"
              height="200"
              width="250"
              elevation="1"
              color="blue-lighten-5"
              :ripple="false"
              :to="{ name: item.link }"
            >
              <v-row justify="center">
                <v-col cols="12">
                  <v-avatar
                    color="white"
                    size="100"
                    class="mt-2 pa-4"
                    :image="item.icon"
                  >
                  </v-avatar>

                  <v-sheet
                    color="blue-lighten-1"
                    rounded="pill"
                    class="mx-auto mt-8"
                    height="40"
                    width="150"
                  >
                    <v-row justify="center">
                      <v-col style="font-weight: bold">
                        {{ $t(item.title) }}
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
        </v-row>
      </v-item-group>
    </v-card>

    <SlideGroup parseMode="kl_recommend" class="mt-6"/>
    <SlideGroup parseMode="jb_recommend" class="mt-6"/>
    <SlideGroup parseMode="new_shop" class="mt-6"/>

    <!-- ScrollBanner -->
    <v-sheet elevation="0" class="mt-12">
      <div class="scrollContainer mt-2" ref="scrollContainer">
        <div class="scrollContent" ref="scrollContent">
          <v-card class="mx-auto ma-4" max-width="344">
            <v-img
              max-height="100%"
              :width="isMobileDevice.value ? 320 : 480"
              src="./banner_loreal.jpg"
              cover
            ></v-img>

            <v-card-title class="text-body-2">
              {{ $t("homeview.loreal_title") }}
            </v-card-title>

            <v-card-actions>
              <v-btn
                prepend-icon="mdi-magnify"
                style="font-weight: bold"
                color="blue"
                :text="$t('homeview.search')"
                variant="flat"
                :ripple="false"
                @click="getLorealTag(this.tagList)"
              ></v-btn>

              <v-spacer></v-spacer>

              <v-btn
                :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                @click="showLorealDesc = !showLorealDesc"
              ></v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="showLorealDesc">
                <v-divider></v-divider>
                <v-card-text>
                  {{ $t("homeview.loreal_desc") }}
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </div>
      </div>
    </v-sheet>

    <!-- <v-sheet class="mt-6">
      <AIBotRecommend />
    </v-sheet> -->

    <!-- Promotons -->
    <v-sheet class="mt-6">
      <CrowdfundingsList
        :pageTitle="$t('homeview.special_voucher')"
        :isCrowdfoundingDetail="false"
        :showItemCount="10"
      />
    </v-sheet>

    <v-sheet class="mt-6">
      <ArticleBanner />
    </v-sheet>

    <v-sheet class="mt-8">
      <LocationShopList />
    </v-sheet>

    <v-sheet class="mt-6">
      <HairStyleAlbum />
    </v-sheet>

    <!-- Sponsor Shop -->
    <!-- <v-sheet class="mt-0">
      <v-carousel
        hide-delimiters
        cycle
        v-if="bannerList.length > 0"
        show-arrows="hover"
        :height="isMobileDevice.value ? 280 : 450"
        width="100%"
        color="bg-grey-lighten-2"
      >
        <v-carousel-item
          v-for="item in bannerList"
          :key="item"
          eager
          transition="fade-transition"
          @click="bannerOnClick(item.banner_url)"
        >
          <v-img :src="item.banner_img" height="100%" :lazy-src="local_logo">
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-sheet> -->

    <!-- Service Category-->
    <!-- <v-sheet color="grey-lighten-4  mt-12">
      <v-sheet class="ma-2 pa-2" color="grey-lighten-4">
        <p class="text-h5 mt-4" style="font-weight: bold">
          <v-icon
            icon="mdi-shape-plus"
            color="blue-darken-1"
            size="24px"
            class="mb-1"
          ></v-icon>
          {{ $t("homeview.service") }}
        </p>
      </v-sheet>

      <v-row no-gutters warp justify="left">
        <v-col v-for="item in categoryList" :key="item.name" cols="6" md="2">
          <v-card
            flat
            class="text-left ma-3"
            color="white"
            elevation="1"
            :ripple="false"
            @click="searchSubmit(item.tag)"
          >
            <v-responsive class="pt-0">
              <v-img
                class="ma-6"
                max-height="90%"
                :src="item.image"
                aspect-ratio="1.0"
              ></v-img>
              <v-card-text>
                <p class="text-h6 text-center" style="font-weight: bold">
                  {{ $t(item.name) }}
                </p>
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet> -->

    <!-- Banner -->
    <div>
      <v-img
        alt="Beauty360 Campaign"
        class="mt-12"
        :src="checkBannerLanguage"
        @click="bannerClick"
        max-width="960"
      ></v-img>
    </div>

    <!-- LocationSelect -->
    <v-dialog
      v-model="showAreaCityDialog"
      max-width="500"
      scrollable
      persistent
    >
      <v-sheet>
        <p class="text-h5 pa-4">{{ $t("loginpage.select_area") }}</p>
        <v-select
          prepend-inner-icon="mdi-map-marker-outline"
          transition="none"
          class="mt-4 ma-4"
          variant="outlined"
          :label="$t('shop.prefecture')"
          v-model="prefecture_selected"
          item-value="id"
          :items="prefectureList"
          :item-title="(v) => (v.code ? $t('prefecture.' + v.code) : v.name)"
          @update:modelValue="updateCityList"
        ></v-select>

        <div class="mt-n8" v-if="prefecture_selected > 0">
          <v-row no-gutters warp>
            <v-col cols="1">
              <v-icon class="mx-4">mdi-size-l</v-icon>
            </v-col>
            <v-col cols="11">
              <v-select
                prepend-inner-icon="mdi-map-marker-outline"
                transition="none"
                class="mt-4 ma-4"
                variant="outlined"
                :label="$t('shop.city')"
                v-model="city_id"
                :items="cityList"
                :item-title="(v) => (v.code ? $t('city.' + v.code) : v.name)"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-row justify="center" no-gutters>
          <v-btn
            prepend-icon="mdi-map-marker"
            elevation="0"
            color="blue"
            width="93%"
            class="ma-6"
            height="50px"
            :ripple="false"
            rounded="pill"
            @click="setNewAreaOnClick()"
          >
            {{ $t("searchfilter.setArea") }}
          </v-btn>
        </v-row>
      </v-sheet>
    </v-dialog>

    <!-- HairCatalog -->
    <!-- <v-sheet class="mt-6">
      <HairCatalog />
    </v-sheet> -->

    <!--
    <v-sheet class="mt-6">
      <TopStyleList :showMoreBtn="`yes`" />
    </v-sheet>

    <v-sheet class="mt-6">
      <HairStyleCard />
    </v-sheet>


    <v-sheet class="mt-6">
      <SearchBar />
    </v-sheet> -->

    <v-sheet class="mt-12 mb-12">
      <SponsorBanner v-if="bannerList.length > 0" :itemObjects="bannerList" />
    </v-sheet>

    <SearchFilterDialog
      v-if="openSearchFilterDialog"
      :openSearchFilterDialog="openSearchFilterDialog"
      :searchFilterObjects="searchFilterObjects"
      @responseFromSearchFilterDialog="responseFromSearchFilterDialog"
      :prefectureList="prefectureList"
    ></SearchFilterDialog>
  </v-sheet>
</template>


<script>
import LocationShopList from "@/components/LocationShopList.vue";
import HairStyleCard from "@/components/HairStyleCard.vue";
import TopStyleList from "@/components/TopStyleList.vue";
import HairCatalog from "@/components/HairCatalog.vue";
import SearchBar from "@/components/SearchBar.vue";
import HairStyleAlbum from "@/components/HairStyleAlbum.vue";
import SponsorBanner from "@/components/Banner/SponsorBanner.vue";
import ArticleBanner from "@/views/Article/ArticleBanner.vue";
import LanguagePack from "../locales/LanguagePack";
import CrowdfundingsList from "@/components/CrowdfundingsList.vue";
import AIBotRecommend from "@/components/AIBotRecommend.vue";
import firebase from "@/plugins/firebase";
import utils from "@/uitls/Uitls";
import VueGeolocation from "vue-browser-geolocation";
import Uitls from "@/uitls/Uitls";
import Vue3autocounter from "vue3-autocounter";
import { event } from "vue-gtag-next";
import { useHead } from "@vueuse/head";
import { mapGetters, mapState } from "vuex";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import SearchFilterDialog from "@/components/SearchFilterDialog.vue";
import { VTreeview } from "vuetify/labs/VTreeview";
import SearchAutocomplete from "@/components/SearchAutocomplete.vue";
import SlideGroup from "@/components/SlideGroup.vue";
import Constants from "@/uitls/Constants";

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  components: {
    LocationShopList,
    HairStyleCard,
    TopStyleList,
    HairCatalog,
    SearchBar,
    HairStyleAlbum,
    SponsorBanner,
    ArticleBanner,
    CrowdfundingsList,
    AIBotRecommend,
    Vue3autocounter,
    VueBottomSheet,
    SearchFilterDialog,
    VTreeview,
    SearchAutocomplete,
    SlideGroup,
  },

  i18n: {
    sharedMessages: LanguagePack,
  },

  async created() {
    await this.getPrefectureList();
    await this.updateCityList();
  },

  async beforeMount() {
    this.loadFireBaseRemote();

    //GetShopCount
    await this.$store
      .dispatch("shopStore/fetchShops", {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        page: 1,
        per_page: 1,
      })
      .then((response) => {
        this.shopCount = this.shopTotalCount;

      });
  },

  async mounted() {
    this.setMetaInfo();
  },

  computed: {
    ...mapGetters({
      shopTotalCount: "shopStore/shopTotalCount",
    }),
    ...mapState({
      getsponsoreList: (state) => state.SponsorStore.sponsoreList,
    }),

    checkBannerLanguage() {
      switch (this.$i18n.locale) {
        case "zhCN":
          return this.banner_img_zhcn;

        default:
          return this.banner_img;
      }
    },
  },

  data() {
    return {
      bannerList: [],
      bot_logo_src: require("@/assets/img/robot_120.png"),
      local_logo: require("@/assets/img/logo120.png"),
      banner_img: require("@/assets/img/campaign.jpg"),
      banner_img_zhcn: require("@/assets/img/campaign_zhcn.jpg"),
      showLorealDesc: false,
      menuList: [
        {
          title: "homeview.search",
          icon: "/hair-salons.png",
          link: "SearchFilter",
        },
        {
          title: "homeview.new_topics",
          icon: "/magazine.png",
          link: "ArticleViewList",
        },
        { title: "homeview.map", icon: "/map.png", link: "GMapView" },
        {
          title: "homeview.voucher",
          icon: "/discounts.png",
          link: "SearchVoucherResult",
        },
      ],
      categoryList: [
        { name: "shopprice.color", tag: "color", image: "/hair-dye.png" },
        { name: "shopprice.prem", tag: "prem", image: "/hairdryer.png" },
        { name: "shopprice.cut", tag: "cut", image: "/baber-shop.png" },
        { name: "shopprice.facial", tag: "facial", image: "/facial.png" },
        {
          name: "shopprice.treatment",
          tag: "treatment",
          image: "/hair-dye2.png",
        },
        { name: "shopprice.eyelash", tag: "eyelash", image: "/eyelashes.png" },
        {
          name: "shopprice.head_spa",
          tag: "head spa",
          image: "/hot-stones.png",
        },
      ],
      shopCount: 0,
      tagList: Uitls.GPTSummaryTags(),
      selectState: {
        title: "prefecture.all_area",
        id: 0,
        name: "prefecture.all_area",
      },
      searchKeyword: null,
      prefectureList: [],
      isLoadedPrefecture: false,
      openSearchFilterDialog: false,
      searchFilterObjects: {
        searchKeyword: "",
        prefecture_v2: 0,
        priceRange: [0, 1000],
        selectedTag: [],
        selectedTime: null,
      },

      showAreaCityDialog: false,
      prefecture_selected: [],
      city_id: 0,
      cityList: [
        {
          id: 0,
          name: "all_city",
          code: "all_city",
        },
      ],
      autocompleteItemList: [],
    };
  },

  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },

    bannerOnClick(url) {
      window.open(url, "_self");
    },

    async loadFireBaseRemote() {
      this.$store
        .dispatch("SponsorStore/sponsorInfo")
        .then((data) => {
          this.bannerList = this.getsponsoreList;
        })
        .catch((e) => {
          console.error("error: ", e);
        });
    },

    setMetaInfo() {
      useHead({
        title: this.$i18n.tc("homeview.find_your_salon"),
        meta: [
          {
            name: "robots",
            content: "index,follow",
          },
          {
            name: "description",
            content: this.$i18n.tc("homeview.og_desc"),
          },
          {
            property: "og:title",
            content: this.$i18n.tc("homeview.site_title"),
          },
          {
            property: "og:description",
            content: this.$i18n.tc("homeview.og_desc"),
          },
          {
            property: "og:url",
            content: "https://beauty-360.com",
          },
        ],
      });
    },

    areaSelectOnclick() {
      if (this.isLoadedPrefecture) {
        this.showAreaCityDialog = true;
      }
    },

    createAreaCityHint() {
      if (this.prefecture_selected <= 0) {
        return this.selectState.code
          ? this.$i18n.t("prefecture." + this.selectState.code)
          : this.$i18n.t(this.selectState.name);
      }

      const cityName = this.cityList.find(
        (city) => city.id === parseInt(this.city_id)
      )?.code;
      const cityTranslation = cityName
        ? this.$i18n.t("city." + cityName)
        : "Areas & City not found";

      const prefectureName = this.prefectureList.find(
        (area) => area.id === parseInt(this.prefecture_selected)
      )?.code;
      const prefectureTranslation = prefectureName
        ? this.$i18n.t("prefecture." + prefectureName)
        : "States not found";

      return `${prefectureTranslation}, ${cityTranslation}`;
    },

    setNewAreaOnClick() {
      // console.log(this.selectState);
      // console.log(this.prefecture_selected);
      // console.log(this.city_id);

      //cookie 30min
      cookies.set("prefecture_selected", this.prefecture_selected, {
        expires: 1 / 48,
      });
      cookies.set("city_id", this.city_id, { expires: 1 / 48 });

      this.areaSelectOnclick();
      this.showAreaCityDialog = false;
    },

    searchSubmit(value) {
      let searchFilterObjects;

      if (this.prefecture_selected <= 0) {
        searchFilterObjects = {
          searchKeyword: value,
          prefecture_v2: parseInt(this.selectState.id),
          priceRange: [0, 1000],
        };
      } else {
        searchFilterObjects = {
          searchKeyword: value,
          prefecture_v2: parseInt(this.prefecture_selected),
          city_id: this.city_id,
          priceRange: [0, 1000],
        };
      }

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },

    getLorealTag(value) {
      var index = value.indexOf("utls.loreal");
      this.searchTagSubmit(index);
    },

    searchTagSubmit(tagPosition) {
      const tagKey = this.tagList[tagPosition];

      let searchFilterObjects;

      if (this.prefecture_selected <= 0) {
        searchFilterObjects = {
          searchKeyword: "",
          prefecture_v2: parseInt(this.selectState.id),
          selectedTag: [tagKey],
          priceRange: [0, 1000],
        };
      } else {
        searchFilterObjects = {
          searchKeyword: "",
          prefecture_v2: parseInt(this.prefecture_selected),
          city_id: this.city_id,
          selectedTag: [tagKey],
          priceRange: [0, 1000],
        };
      }

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },

    responseFromSearchFilterDialog(obj) {
      // console.log("responseFromSearchFilterDialog");
      this.openSearchFilterDialog = false;

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(obj) },
      });
    },

    bannerClick() {
      this.openCampaignPage();
    },

    openCampaignPage() {
      this.$router.push({
        name: "ForShopLanguageSelect",
      });
    },

    removeText(value) {
      return this.$i18n.tc(value);
    },

    async getPrefectureList() {
      const vm = this;
      vm.prefectureList = [
        {
          id: 0,
          name: "all_area",
          code: "all_area",
        },
      ];

      await vm.$store
        .dispatch("shopStore/getPrefectureByCountry", "malaysia")
        .then(async (response) => {
          if (response && response.data && response.data.length > 0) {
            response.data.forEach((v) => {
              vm.prefectureList.push({
                id: v.id,
                name: v.name,
                code: v.code,
                cityList: v.city_list ? v.city_list : [],
              });
            });
          }

          if (!cookies.get("prefecture_selected")) {
            await vm.getVueGeoLocation();
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          vm.isLoadedPrefecture = true;
        });
    },

    async getVueGeoLocation() {
      VueGeolocation.getLocation({
        enableHighAccuracy: false,
        timeout: Infinity,
        maximumAge: 0,
      })
        .then((coordinates) => {
          if (coordinates) {
            // console.log("getVueGeoLocation: " + JSON.stringify(coordinates))
            cookies.set("coordinates", coordinates);
            if (coordinates.lat && coordinates.lng) {
              this.getNearestCity(coordinates.lat, coordinates.lng);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getNearestCity(latitude, longitude) {
      try {
        const vm = this;
        await vm.$store
          .dispatch("shopStore/getNearestCity", {
            latitude,
            longitude,
          })
          .then(async (response) => {
            // console.log('getNearestCity', response)
            if (response && response.data) {
              const cityData = JSON.parse(JSON.stringify(response.data));
              // console.log('cityData', cityData)
              if (cityData && cityData.prefecture_v2) {
                const targetPrefecture = vm.prefectureList.find(
                  (item) => item.name == cityData.prefecture_v2.name
                );
                // console.log("targetPrefecture", targetPrefecture);
                if (targetPrefecture) {
                  this.selectState = targetPrefecture;
                }
              }
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (error) {
        console.error(error);
      }
    },

    async updateCityList() {
      const vm = this;
      vm.cityList = [
        {
          id: 0,
          name: "all_city",
          code: "all_city",
        },
      ];
      if (vm.prefecture_selected > 0) {
        const targetPrefecture = vm.prefectureList.find(
          (item) => item.id == vm.prefecture_selected
        );
        if (targetPrefecture && targetPrefecture.cityList) {
          vm.cityList = JSON.parse(JSON.stringify(targetPrefecture.cityList));
          vm.cityList.unshift({
            id: 0,
            name: "all_city",
            code: "all_city",
          });
        }

        if (vm.city_id > 0) {
          const targetCity = vm.cityList.find((item) => item.id == vm.city_id);
          if (!targetCity) {
            vm.city_id = 0;
          }
        }
      }
    },

    async updateAutocompleteItemList(keyword) {
      this.searchKeyword = keyword;

      const vm = this;
      if (keyword) {
        try {
          await vm.$store
            .dispatch("shopStore/getAutocompleteItemList", {
              keyword,
            })
            .then(async (response) => {
              // console.log('getAutocompleteItemList', response)
              if (response && response.data) {
                vm.autocompleteItemList = JSON.parse(
                  JSON.stringify(response.data)
                );
              }
            })
            .catch((e) => {
              console.error(e);
            });
        } catch (error) {
          console.error(error);
        }
      }
    },
    async setAutocompleteResult(target = null) {
      const vm = this;
      // console.log("target", target);
      if (target) {
        let searchFilterObjects = {};
        switch (target.type) {
          case "shop":
          case "menu":
          case "crowndfunding":
          case "crowndfundingItem":
            if (target.shop_id) {
              this.$router.push({
                name: "Shop",
                params: { id: target.shop_id },
              });
            }
            break;
          case "prefecture":
            const targetPrefecture = vm.prefectureList.find(
              (item) => item.id == target.id
            );
            // console.log('targetPrefecture', targetPrefecture)
            if (targetPrefecture) {
              searchFilterObjects["prefecture_v2"] = parseInt(
                targetPrefecture.id
              );
            }
            this.$router.push({
              name: "SearchResult",
              query: { query: JSON.stringify(searchFilterObjects) },
            });
            break;
          case "city":
            const targetPrefectureByCity = vm.prefectureList.find(
              (item) => item.id == target.prefecture_v2_id
            );
            if (targetPrefectureByCity) {
              searchFilterObjects["prefecture_v2"] = parseInt(
                targetPrefectureByCity.id
              );
              if (
                targetPrefectureByCity.cityList &&
                targetPrefectureByCity.cityList.length > 0
              ) {
                const targetCity = targetPrefectureByCity.cityList.find(
                  (city) => city.id == target.id
                );
                if (targetCity) {
                  searchFilterObjects["city_id"] = parseInt(targetCity.id);
                }
              }
            }
            this.$router.push({
              name: "SearchResult",
              query: { query: JSON.stringify(searchFilterObjects) },
            });
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-application {
  font-family:  'PingFang SC', 'Microsoft YaHei', 'sans-serif','American Typewriter';
}

.glow {
  color: white;
  text-shadow: 0 0 10px black, 0 0 20px black, 0 0 30px black, 0 0 40px black,
    0 0 70px black, 0 0 80px black, 0 0 100px black, 0 0 150px black;
}

.autocounter {
  /* font-family: American Typewriter, serif; */
  font-weight: bold;
  font-style: italic;
  font-size: 1rem;
  text-align: left;
  color: black;
}

.scrollContainer {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
}

.scrollContent {
  display: flex;
  flex-wrap: nowrap;
}

.bg {
  width: 100%;
  height: 26em;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;

  background: linear-gradient(
      0deg,
      rgba(250, 250, 0250, 0.1),
      rgba(255, 255, 255, 1)
    ),
    url("@/assets/img/bg.webp");

  background-size: cover;
  transform: scale(1);
}

.searchbg {
  background-color: white;
}
</style>


<!-- https://storage.googleapis.com/beauty360-dev/toppage/bg-blue.png -->