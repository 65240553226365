<template>
  <v-app-bar
    v-if="isNewUser == 'false'"
    scroll-behavior="elevate"
    color="grey-lighten-4"
  >
    <v-btn class="ma-0" icon="mdi-arrow-left" @click="$router.push('/settingpage')"></v-btn>
    <v-app-bar-title>{{$t('settingpage.interest')}}</v-app-bar-title>
  </v-app-bar>

  <v-app-bar
    v-else
    app
    flat
    :height="isMobileDevice.value ? 48 : 52"
    color="grey-lighten-4"
  >
    <v-toolbar-title>
      <v-img
        v-bind:src="logo_src"
        height="30"
        aspect-ratio="1"
      ></v-img
    ></v-toolbar-title>
    <v-spacer></v-spacer>
  </v-app-bar>

  <v-main>
    <v-item-group multiple v-model="itemSelect">
      <v-container fluid>
        <v-sheet
          elevation="0"
          max-width="1200"
          rounded="lg"
          width="100%"
          class="pa-0 mx-auto mt-6"
        >
          <v-row
            :align="isMobileDevice.value ? 'stretch' : 'center'"
            justify="center"
          >
            <v-col v-if="isNewUser == 'true'" cols="12">
              <p class="text-h6" style="font-weight: bold">
                {{$t('settingpage.almost_done')}}
              </p>
              <p class="text-caption">
                {{$t('settingpage.pls_select_interest')}}
              </p>
            </v-col>

            <v-col
              v-for="item in interestsCategory"
              :key="item"
              cols="4"
              md="4"
            >
              <v-item v-slot="{ isSelected, toggle }">
                <v-card
                  flat
                  :color="isSelected ? 'blue' : ''"
                  class="align-center"
                  dark
                  :max-height="isMobileDevice.value ? '100%' : '60%'"
                  :max-width="isMobileDevice.value ? '100%' : '60%'"
                  @click="toggle"
                >
                  <v-responsive class="pt-0">
                    <v-img
                      cover
                      class="fill-width text-right pa-0"
                      aspect-ratio="1"
                      :src="item.image"
                      :lazy-src="local_logo"
                    >
                      <v-btn
                        variant="text"
                        size="sm"
                        :ripple="false"
                        color="blue"
                        :icon="
                          isSelected
                            ? 'mdi-check-circle'
                            : 'mdi-check-circle-outline'
                        "
                      ></v-btn>
                    </v-img>
                  </v-responsive>

                  <v-card-text
                    class="mt-n0 text-center"
                    style="overflow-y: auto; height: 20"
                  >
                    {{ item.label }}
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>

          <v-row justify="center" no-gutters>
            <v-btn
              elevation="0"
              color="blue"
              width="93%"
              class="ma-6"
              height="50px"
              :ripple="false"
              rounded="pill"
              :loading="loading"
              @click="submitOnClick"
            >
            {{$t('settingpage.submit')}}
            </v-btn>
          </v-row>
        </v-sheet>
      </v-container>
    </v-item-group>
  </v-main>
  <VSonner position="top-center" />
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { VSonner, toast } from "vuetify-sonner";
import utils from "@/uitls/Uitls";
import Constants from "@/uitls/Constants";

export default {
  components: {
    VSonner,
  },

  props: ["isNewUser"],

  setup(props) {
    // setup() receives props as the first argument.
    // console.log("isNewUser: " + this.isNewUser);
  },

  computed: {
    ...mapGetters({
      userInfo: "userStore/userInfo",
      userInterestIds: "authStore/interest_ids",
    }),
  },

  watch: {
    interest_ids(to, from) {
      console.log(this.userInterestIds);
    },
  },

  data: () => ({
    itemSelect: [],
    interestsCategory: [],
    local_logo: require("@/assets/img/logo120.png"),
    logo_src: require("@/assets/newlogo.png"),
  }),

  beforeMount() {
    this.getInterestItems();
  },

  mounted() {
    if (this.isNewUser == "true") {
      this.addSetting();
    }
  },

  methods: {
    async addSetting() {
      const mailSetting = {
        property_code: "mail-all",
        property_name: "receive_all_mail",
        property_value: "1",
        property_type: Constants.MAIL_DELIVERY_SETTING_TYPE,
      };

      const settingMailData = {
        settings: [mailSetting],
      };

      await this.$store
        .dispatch("userStore/addSetting", settingMailData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          throw new Error(error);
        });
    },

    async getInterestItems() {
      await this.$store
        .dispatch("interestStore/fetchAll")
        .then((response) => {
          const interestData = [];
          response.data.forEach((interest) => {
            interestData.push({
              id: interest.id,
              image: interest.picture_url,
              label: interest.name,
              value: interest.id,
            });
          });
          interestData.reverse();
          this.interestsCategory = interestData;

          if (this.isNewUser == "false") {
            this.checkUserInterestSelect();
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },

    checkUserInterestSelect() {
      if (
        !utils.isEmpty(this.userInterestIds) &&
        this.userInterestIds.length > 0
      ) {
        for (let i = 0; i < this.interestsCategory.length; i++) {
          const element = this.interestsCategory[i].id;

          const result = this.userInterestIds.find(
            ({ interest_id }) => interest_id === element
          );
          if (result) {
            this.itemSelect.push(i);
          }
        }
      }
    },

    async submitOnClick() {
      if (this.itemSelect.length <= 0) {
        toast("Please select item", {
          cardProps: {
            color: "error",
            class: "my-toast",
          },
        });
        return;
      } else {
        const interest = [];

        for (let i = 0; i < this.itemSelect.length; i++) {
          const element = this.itemSelect[i];
          interest.push(this.interestsCategory[element].id);
        }

        await this.$store
          .dispatch("interestStore/updateUserInterest", {
            interest_ids: interest,
          })
          .then((response) => {
            console.log(response);
            this.$store.commit("authStore/SET_INTEREST", response);

            toast("Interest submit completed.", {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            });

            if (this.isNewUser == "false") {
              setTimeout(() => {
                this.$router.push({ name: "SettingPage" });
              }, 3000);
            } else {
              setTimeout(() => {
                this.$router.push({ name: "MyPage" });
              }, 3000);
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 10px;
}
</style>