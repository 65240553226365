<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn class="ma-0" icon="mdi-arrow-left" @click="$router.push('/settingpage')"></v-btn>
    <v-app-bar-title>{{$t('settingpage.news_letter')}}</v-app-bar-title>
  </v-app-bar>
  <v-main>
    <v-sheet
      elevation="0"
      max-width="1200"
      rounded="lg"
      width="100%"
      class="pa-0 mx-auto mt-6"
    >
      <v-checkbox
        class="mx-4"
        :label="$t('settingpage.receive_news')"
        v-model="checkbox1"
        color="blue"
        :ripple="false"
        @click="checkboxOnClick($event)"
      ></v-checkbox>
    </v-sheet>
  </v-main>
</template>

<script scope>
import Constants from "@/uitls/Constants";

export default {
  data() {
    return {
      checkbox1: false,
      property_value: "1",
    };
  },

  beforeMount() {
    this.fetchSettingsList();
  },

  methods: {
    checkboxOnClick(event, item) {
      switch (event.target.checked) {
        case true:
          this.property_value = "1";
          break;
        case false:
          this.property_value = "0";
          break;
      }

      this.updateSetting();
    },

    async fetchSettingsList() {
      const params = {
        propertyType: Constants.MAIL_DELIVERY_SETTING_TYPE,
      };

      this.$store
        .dispatch("userStore/fetchSettingsList", params)
        .then((response) => {
          let lastValue = response[Object.keys(response).pop()];
          if (lastValue.property_value == "1") {
            this.checkbox1 = true;
          } else {
            this.checkbox1 = false;
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },

    async updateSetting() {
      const mailSetting = {
        property_code: "mail-all",
        property_name: "receive_all_mail",
        property_value: this.property_value,
        property_type: Constants.MAIL_DELIVERY_SETTING_TYPE,
      };

      const settingMailData = {
        settings: [mailSetting],
      };

      await this.$store
        .dispatch("userStore/updateSetting", settingMailData)
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
  },
};
</script>