import axiosClient from '@/plugins/axiosClient';
import { USER_API } from '@/store/api'
import Constants from '@/uitls/Constants'

const state = {
  property: null,
};

const getters = {
  getData: state => {
    return state.property;
  }
};

const mutations = {
  SET_DATA: (state, data) => {
    state.property = data;
  },
};

const actions = {
  async getData({ commit }, email) {
  
    const encodedEmail = encodeURIComponent(email);
    const baseURL = `${USER_API}/email/exists?email=${encodedEmail}`;
    await axiosClient
      .get(baseURL)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          commit("SET_DATA", response.data);
        } else {
          return Promise.reject;
        }
      })
      .catch((error) => {
        // handle error
        console.error(error);
        throw error;
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}