<!-- https://maps.multisoup.co.jp/blog/5146/ -->
<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-sheet
      id="map"
      class="mt-0"
      :light="true"
      style="width: 100%; height: 480px"
    >
    <v-btn>
      button
    </v-btn>
    </v-sheet>

    <v-sheet elevation="0" class="mt-0 pa-6 mb-16">
      <div class="scrollContainer" ref="scrollContainer">
        <div class="scrollContent" ref="scrollContent">
          <div v-for="n in 12" :key="n">
            <ShopCardCupon />
          </div>
        </div>
      </div>
    </v-sheet>

    <!-- <v-row class="mt-4 pa-2 mb-16">
      <v-col>
        <v-sheet>
          <v-slide-group center-active>
            <v-slide-group-item>
              <v-list-item v-for="n in 12" :key="n">
                <ShopCardCupon />
              </v-list-item>
            </v-slide-group-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row> -->
  </v-sheet>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import { Loader } from "@googlemaps/js-api-loader";
import ShopCardCupon from "@/components/ShopCardCupon.vue";

export default {
  components: { SearchBar, ShopCardCupon },

  data: function () {
    return {
      map: null,
      google: null,
      marker: null,
      searchText: this.$route.params.address,
      shopId: null,
    };
  },

  methods: {
    /**
     * 住所検索
     *
     * @param {String} text
     * @returns なし
     */
    addressSearch() {
      const vm = this;
      const geocoder = new this.google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode(
          { address: this.searchText },
          function (results, status) {
            if (vm.marker) {
              vm.marker.setMap(null);
            }
            if (status === vm.google.maps.GeocoderStatus.OK) {
              vm.marker = new vm.google.maps.Marker({
                map: vm.map,
                position: results[0].geometry.location,
                draggable: false,
              });
              vm.map.setCenter(results[0].geometry.location);
            }
          }
        );
      }
    },

    /**
     * csvファイル読み込み
     */
    importCsvFile(e) {
      const vm = this;
      let file = e;

      // こちらにバリデーションを考えて、実装してみてください。

      // 読み込みファイル情報セット
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function () {
        const csvData = reader.result;
        const readCsvJson = vm.$papa.parse(csvData);
        vm.dropLocationPoints(readCsvJson.data);
      };
    },

    /**
     * 位置情報の場所をプロットする
     * @param {Array} latLng配列
     */
    dropLocationPoints(list) {
      const vm = this;
      this.removeLocationPoints();
      list.forEach((item) => {
        let lat = Number(item[this.CSV_LAT]);
        let lng = Number(item[this.CSV_LNG]);
        if (lat && lng) {
          vm.drawCircleSymbol(lat, lng);
          vm.locationList.push(location);
        }
      });
    },

    /**
     * Symbol(circle)を地図上に描画する。
     * @param lat
     * @param lng
     * @return {google.maps.LatLng} latlng
     */
    drawCircleSymbol(lat, lng) {
      let options = {
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 0.05,
        fillColor: "green",
        fillOpacity: 0.2,
        center: new this.google.maps.LatLng(lat, lng),
        radius: Math.sqrt(20),
      };
      const circle = new this.google.maps.Circle(options);
      circle.setMap(this.map);
      this.locationPoints.push(circle);
      return circle;
    },

    /**
     * 位置情報をプロットしたすべての場所を削除する
     */
    removeLocationPoints() {
      this.locationPoints.forEach((symbol) => {
        symbol.setMap(null);
      });
      this.locationPoints = [];
    },
  },

  mounted: function () {
    new Loader({
      apiKey: "AIzaSyAvTxz-6HZtoMMdBkFKXOcJtMsiFM11mzc",
      version: "frozen",
      libraries: ["places", "drawing", "geometry", "visualization"],
      language: "en",
    })
      .load()
      .then((google) => {
        this.google = google;

        // 地図の初期化
        this.map = new google.maps.Map(document.getElementById("map"), {
          // 初期表示設定
          zoom: 17,
          center: { lat: 35.692195, lng: 139.759854 }, // マルティスープ本社
          scaleControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          zoomControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
        });

        // こちらにレスポンスとして受け取ったgoogleやthis.mapを使用すれば、
        // 通常通りvueでもJavaScriptAPIを利用できます。

        if (this.searchText.length === "null") {
          console.log("Empty address");
        } else {
          this.addressSearch();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },

  created() {
    console.log("created");
  },
};
</script>

<style scoped>
.search-form-wrapper {
  position: absolute;
  bottom: 20px;
  right: 10px;
  border: solid 4px #757575;
  border-radius: 30px;
  cursor: pointer;
}

.search-form-address {
  max-width: 400px;
  border: none;
  outline: true;
}

.scrollContent {
  display: flex;
  flex-wrap: nowrap;
}
.scrollContainer {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
}
</style>