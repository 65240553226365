<template>
  <div class="selectMenu">
    <div class="d-flex">
      <div class="mainTitle">Select a staff</div>
    </div>

    <v-card class="card-frame__section card-frame__section-first">
      <div v-if="showCardOne" class="card-frame__body">
        <div class="item-article">
          <v-btn v-show="scrollLeftBtn" @click="clickScrollLeftBtn()" color="nobitel" fab small absolute left top class="scroll-btn__left">
            <v-icon color="white">mdi-chevron-left</v-icon>
          </v-btn>

          <ul id="scrollContainer" class="staff-scroll" v-on:scroll="handleScrollStaff" ref="staffCardItems">
            <li v-for="(staff, index) in staffList" :key="index" id="staffCardItem" class="staff-card-item item-article__item">
              <div @click="clickEventStaffCard(staff)" :class="{'card-checkbox__block': true, 'card-checkbox__block-selected': reserveData.staff && reserveData.staff.id == staff.id}">
                <CardMenuStaffWithOption :staffDetailData="staff" :isBorder="false"></CardMenuStaffWithOption>
                <v-checkbox v-model="reserveData.staff" :disable="true" :value="staff" on-icon="mdi-check-circle" off-icon="" style="pointer-events: none" dark :ripple="false" class="card-checkbox"></v-checkbox>
              </div>
            </li>
          </ul>

          <v-btn v-show="scrollRightBtn" @click="clickScrollRightBtn()" color="nobitel" fab small absolute right top class="scroll-btn__right">
            <v-icon color="white">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<style></style>

<script>
import CardMenuStaffWithOption from "@/components/CardMenuStaffWithOption.vue";
export default {
  components: {
    CardMenuStaffWithOption,
  },
  props: {
    editData: {
      type: Object,
      default: () => {},
    },
    staffs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reserveData: {
        shop: null,
        course: null,
        staff: null,
      },
      staffList: [],
      showCardOne: true,
      scrollRightBtn: false,
      scrollLeftBtn: false,
    }
  },
  watch: {
    editData() {
      const vm = this
      vm.handleReserveData()
    },
    staffs() {
      const vm = this
      vm.handleReserveData()
    },
  },

  methods: {
    handleReserveData() {
      const vm = this
      vm.reserveData.staff = null
      vm.showCardOne = true
      if (vm.staffs.length > 0) {
        vm.staffList = JSON.parse(JSON.stringify(vm.staffs))
        vm.reserveData.staff = vm.staffList[0]
        if (vm.editData && vm.editData.staff) {
          vm.reserveData.staff = vm.editData.staff
        }
        vm.submitStaff()
        vm.$nextTick(() => {
          vm.handleScrollStaff()
        })
      }
    },
    clickEventStaffCard(staff) {
      const vm = this
      vm.reserveData.staff = staff
      vm.submitStaff()
    },

    clickScrollLeftBtn() {
      document.getElementById('scrollContainer').scrollLeft -=
        this.$refs.staffCardItems.clientWidth / 1.9
    },
    clickScrollRightBtn() {
      document.getElementById('scrollContainer').scrollLeft +=
        this.$refs.staffCardItems.clientWidth / 1.9
    },
    handleScrollStaff() {
      const element = this.$refs.staffCardItems
      if (element) {
        this.width = window.innerWidth
        const parentRight = element.getBoundingClientRect().right
        const parentLeft = element.getBoundingClientRect().left
        const firstChildLeft = element.firstChild.getBoundingClientRect().left
        const lastChildRight = element.lastChild.getBoundingClientRect().right
        if (parentRight === lastChildRight || parentRight > lastChildRight) {
          this.scrollRightBtn = false
        } else {
          this.scrollRightBtn = true
        }
        if (parentLeft === firstChildLeft || parentLeft < firstChildLeft) {
          this.scrollLeftBtn = false
        } else {
          this.scrollLeftBtn = true
        }
      }
    },

    submitStaff() {
      const vm = this
      if (vm.reserveData.staff) {
        vm.$emit('submitStaff', vm.reserveData)
      }
    },
  },
}
</script>
