<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <br />
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-newspaper"
        color="light-blue"
        size="24px"
        class="mb-1"
      ></v-icon>
      Thanks for your purchase
    </p>
    <v-btn color="black" @click="backToHome">Back to Home</v-btn>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      crowdfundingItemId: 0,
      shopId: 0,
    };
  },

  async mounted() {
    window.scrollTo(0, 0);
    if (this.$route.query.voucher && this.$route.query.shop) {
      this.crowdfundingItemId = this.$route.query.voucher;
      this.shopId = this.$route.query.shop;
      this.viewVoucherDetail();
    } else {
      this.backToHome();
    }
  },
  methods: {
    backToHome() {
      this.$router.push({ name: "HomeView" });
    },
    viewVoucherDetail() {
      if (this.crowdfundingItemId > 0) {
        this.$router.push({
          name: "UseVoucher",
          params: {
            crowdfundingItemId: this.crowdfundingItemId,
            shopId: this.shopId,
          },
        });
      } else {
        this.backToHome();
      }
    },
  },
};
</script>