<template>
  <v-footer color="white">
    <v-row justify="center" no-gutters >
      <!-- <v-btn
        v-for="link in categoryList"
        :key="link"
        color="black"
        variant="text"
        class="mx-2"
        rounded="xl"
      >
        {{ link }}
      </v-btn> -->
      <v-col class="text-center mt-4" cols="12">
        © {{ new Date().getFullYear() }} —
        <strong>Beauty 360™. All rights reserved.</strong>
      </v-col>
    </v-row>
 </v-footer>
</template>

<script>
export default {
  data() {
    return {
      categoryList: [
        "About Us",
        "Contact",
        "Get Listed",
        "FAQ",
        "CRM System",
        "Policy",
      ],
    };
  },
};
</script>

<style scoped>
.footer {
  background: grey-lighten-4;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 54px;
  padding: 0 30px 20px;
  
}
</style>