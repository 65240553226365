<template>
  <v-sheet id="shop-reserve" class="pa-0 ma-0 mx-auto" color="blue" width="100%" elevation="0"
    v-if="shopData.name != null">
    <v-stepper v-model="e1" style="width: 100%" elevation="0" :prev-text="$t('busienssPage.prev')"
      :next-text="$t('busienssPage.next')">
      <v-stepper-header>
        <v-stepper-item value="1" class="text-caption">
          {{ $t("reservation.select_date") }}
        </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="2" class="text-caption">
          {{ $t("reservation.select_service") }}
        </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="3" class="text-caption">
          {{ $t(appointmentSetting && appointmentSetting.reserve_approval_type === 0 ? "busienssPage.done" : "busienssPage.TemporaryReservation") }}
        </v-stepper-item>
      </v-stepper-header>

      <v-stepper-actions class="mt-6" @click:prev="prevOnCLick" @click:next="nextOnCLick"></v-stepper-actions>

      <v-stepper-window>
        <v-stepper-window-item value="1">
          <p class="text-h5">{{ shopData.name }}</p>
          <p class="text-caption">
            {{ shopData.detail.address }}
          </p>

          <ReserveSelectStaffModal class="mt-12 pa-0" v-if="appointmentSetting &&
            appointmentSetting.nominated_type === 1 &&
            appointmentSetting.reservation_schedule_type === 1
            " :editData="{}" :staffs="staffs" @submitStaff="submitStaff">
          </ReserveSelectStaffModal>

          <div id="ReserveScheduleTypeOne"
            v-if="appointmentSetting && appointmentSetting.reservation_schedule_type === 1">
            <div id="scheduleTypeOneSelectDateTime">
              <div class="mt-4">
                <div class="section-title d-flex mb-7">
                  <div class="section-title__title">
                    {{ $t("reservation.select_booking_date") }}
                  </div>
                </div>
                <div class="reserve-section__input">
                  <v-row class="sub-body text-box__label">
                    <label class="label__require">
                      {{ $t("reservation.required") }}
                    </label>
                    <p style="font-weight: bold">
                      {{ $t("reservation.first_choice") }}
                    </p>
                  </v-row>
                  <v-row>
                    <v-col class="text-box__hint pa-2" cols="5">
                      <v-text-field v-model="reserveDataScheduleType1.bookingFirstDate" class="ml-1 pa-0" type="date"
                        variant="outlined" required></v-text-field>
                    </v-col>
                    <v-col class="text-box__hint pa-2" cols="3.5">
                      <v-select class="sub-body" return-object v-model="reserveDataScheduleType1.first_time"
                        :rules="[requiredDate]" :label="'HH'" item-text="text" item-value="started_at" outlined
                        :items="hours" :disabled="!reserveDataScheduleType1.bookingFirstDate"></v-select>
                    </v-col>
                    <v-col class="text-box__hint pa-2" cols="3.5">
                      <v-select class="sub-body" return-object v-model="reserveDataScheduleType1.first_minutes"
                        :rules="[requiredDate]" :label="'MM'" hint="" persistent-hint item-text="text"
                        item-value="started_at" outlined :items="minutes" :disabled="!reserveDataScheduleType1.bookingFirstDate || !reserveDataScheduleType1.first_time"></v-select>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="appointmentSetting.reservation_time_limit >= 2">
                  <div class="reserve-section__input">
                    <v-row class="sub-body text-box__label">
                      <label class="label__require">
                        {{ $t("reservation.required") }}
                      </label>
                      <p style="font-weight: bold">
                        {{ $t("reservation.second_choice") }}
                      </p>
                    </v-row>
                    <v-row>
                      <v-col class="text-box__hint pa-2" cols="5">
                        <v-text-field v-model="reserveDataScheduleType1.bookingSecondDate" class="ml-1 pa-0" type="date"
                          variant="outlined" :disabled="!reserveDataScheduleType1.first_minutes"
                          required></v-text-field>
                      </v-col>
                      <v-col class="text-box__hint pa-2" cols="3.5">
                        <v-select class="sub-body" return-object v-model="reserveDataScheduleType1.second_time"
                          :rules="[requiredDate]" :label="'HH'" hint="" persistent-hint item-text="text"
                          item-value="started_at" :items="hours" outlined
                          :disabled="!reserveDataScheduleType1.bookingSecondDate"></v-select>
                      </v-col>
                      <v-col class="text-box__hint pa-2" cols="3.5">
                        <v-select class="sub-body" return-object v-model="reserveDataScheduleType1.second_minutes"
                          :rules="[requiredDate]" :label="'MM'" hint="" persistent-hint item-text="text"
                          item-value="started_at" outlined :items="minutes"
                          :disabled="!reserveDataScheduleType1.bookingSecondDate || !reserveDataScheduleType1.second_time"></v-select>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div v-if="appointmentSetting.reservation_time_limit >= 3">
                  <div class="reserve-section__input">
                    <v-row class="sub-body text-box__label">
                      <label class="label__require">
                        {{ $t("reservation.required") }}
                      </label>
                      <p style="font-weight: bold">
                        {{ $t("reservation.third_choice") }}
                      </p>
                    </v-row>
                    <v-row>
                      <v-col class="text-box__hint pa-2" cols="5">
                        <v-text-field v-model="reserveDataScheduleType1.bookingThirdDate" class="ml-1 pa-0" type="date"
                          variant="outlined" :disabled="!reserveDataScheduleType1.second_minutes"
                          required></v-text-field>
                      </v-col>
                      <v-col class="text-box__hint pa-2" cols="3.5">
                        <v-select return-object v-model="reserveDataScheduleType1.third_time" :rules="[requiredDate]"
                          :label="'HH'" hint="" persistent-hint item-text="text" item-value="started_at" :items="hours"
                          outlined :disabled="!reserveDataScheduleType1.bookingThirdDate"></v-select>
                      </v-col>
                      <v-col class="text-box__hint pa-2" cols="3.5">
                        <v-select return-object v-model="reserveDataScheduleType1.third_minutes" :rules="[requiredDate]"
                          :label="'MM'" hint="" persistent-hint item-text="text" item-value="started_at"
                          :items="minutes" outlined
                          :disabled="!reserveDataScheduleType1.bookingThirdDate || !reserveDataScheduleType1.third_time"></v-select>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <v-text-field
            v-if="
            appointmentSetting &&
            appointmentSetting.reservation_schedule_type === 2"
            v-model="bookingDate" class="mt-12 pa-0" :label="$t('reservation.select_booking_date')" type="date"
            variant="outlined" required></v-text-field>

          <v-table class="my-table" v-if="checkHaveBookingDate()">
            <thead>
              <tr>
                <th class="text-left">
                  <p class="text-body-1" style="font-weight: bold">
                    {{ format_date(bookingDate) }}
                  </p>
                </th>
                <th class="text-left">
                  {{ $t("reservation.no_preferred_staff") }}
                </th>
                <th class="text-left" v-for="(staff, index) in staffs" v-bind:key="index">
                  <p class="text-subtitle-2">
                    {{ staff.name ?? staff.display_name ?? staff.family_name }}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(nomitatedStaffs, time) in calendarReservationList" v-bind:key="time">
                <td style="background-color: white">
                  <p class="text-subtitle-2">{{ time }}</p>
                </td>

                <td>
                  <v-btn-toggle mandatory v-model="selectTimeBtn" color="blue" @click="btnToggleChange">
                    <v-btn :ripple="false" size="small" retain-focus-on-click :value="time + '-' + 0"
                      @click="btnOnClick(time, 0)">
                      <v-icon> mdi-check</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </td>

                <td v-for="(staff, col) in staffs" v-bind:key="col">
                  <v-btn-toggle v-if="!nomitatedStaffs.includes(staff.id)" mandatory v-model="selectTimeBtn"
                    color="blue" @click="btnToggleChange">
                    <v-btn :ripple="false" size="small" retain-focus-on-click :value="time + '-' + staff.id"
                      @click="btnOnClick(time, col)">
                      <v-icon> mdi-check</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <div v-else class="no-select-reserve">-</div>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-stepper-window-item>

        <v-stepper-window-item value="2">
          <v-sheet>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="text-h5">{{ $t("reservation.select_service") }}</p>
                <p class="text-caption">
                  {{ $t("reservation.select_service_desc") }}
                </p>
              </v-col>
              <v-col cols="12">
                <p
                  v-if="menuSelect.length > 0"
                  class="text-h5 mt-3 text-green"
                  style="font-weight: bold"
                >
                  {{ $t("reservation.totalAmount") }}: RM{{ calculateAmount() }}
                </p>
              </v-col>
            </v-row>

            <v-sheet class="pa-2 text-center mt-4" rounded border :ripple="false" @click="isSelectedBookOnlyOnClick()">
              <div align="left" class="mx-2">
                <p style="font-weight: bold">
                  {{ $t("reservation.book_only") }}
                </p>
                <p class="text-body-2 text-grey">
                  {{ $t("reservation.book_only_desc") }}
                </p>
              </div>
            </v-sheet>

            <v-item-group multiple class="mt-6" v-model="menuSelect">
              <v-row>
                <v-col v-for="item in shopData.active_menus" :key="item.id" cols="12" md="3" sm="1">
                  <v-item v-slot="{ isSelected, toggle }">
                    <v-sheet class="pa-2 text-center" rounded border :color="isSelected ? 'light-green-lighten-1' : ''"
                      :ripple="false" @click="toggle">
                      <div align="left" class="mx-2">
                        <p style="font-weight: bold">
                          {{ item.title }}
                        </p>
                        <p class="text-body-2 text-grey">
                          {{ item.required_minutes }} mins
                        </p>
                        <!-- <p v-snip="{ lines: 2 }" class="text-body-2 text-grey">
                          {{ item.description }}
                        </p> -->
                        <p class="mt-2">{{ getMenuAmount(item) }}</p>
                      </div>
                    </v-sheet>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-sheet>
        </v-stepper-window-item>

        <v-stepper-window-item value="3">
          <p class="text-h5">{{ $t("reservation.check_booking") }}</p>
          <p class="text-caption">
            {{ $t("reservation.check_booking_desc") }}
          </p>
          <v-form @submit.prevent>
            <v-card elevation="0" color="white">
              <v-col class="mt-0">
                <v-text-field required prepend-icon="mdi-account" v-model="userName"
                  :rules="[(v) => !!v || 'Field is required']" :label="$t('settingpage.your_name')"
                  :placeholder="$t('settingpage.your_name')" variant="underlined"></v-text-field>

                <v-text-field class="mt-2" required :label="$t('settingpage.your_contact_number')"
                  :placeholder="$t('settingpage.your_contact_number')" prepend-icon="mdi-phone" v-model="userPhone"
                  variant="underlined" :rules="[(v) => !!v || 'Field is required']"></v-text-field>

                <v-text-field class="mt-2" required :label="$t('settingpage.your_current_email')"
                  :placeholder="$t('settingpage.your_current_email')" prepend-icon="mdi-email" v-model="userEmail"
                  variant="underlined" :rules="emailRules">
                </v-text-field>

                <v-textarea
                  class="mt-6"
                  prepend-icon="mdi-comment"
                  :label="$t('reservationdetails.reserveMemoTitleOptional')"
                  v-model="comment"
                  outlined
                  clearable
                ></v-textarea>

                <div v-if="e1 == 2">
                  <p class="text-h5 mt-3 text-green" style="font-weight: bold">
                    {{ $t("reservation.totalAmount") }}: RM{{ calculateAmount() }}
                  </p>

                  <v-row no-gutters>
                    <v-col v-for="item in menuSelectedItems" :key="item.id" cols="12" sm="4">
                      <v-sheet class="ma-2 pa-2 text-center" rounded border :ripple="false">
                        <div align="left" class="pa-2">
                          <p style="font-weight: bold">
                            {{ item.title }}
                          </p>
                          <p class="text-body-2 text-grey" v-if='item.required_minutes > 0'>
                            {{ item.required_minutes }} mins
                          </p>
                          <!-- <p
                            v-snip="{ lines: 2 }"
                            class="text-body-2 text-grey"
                          >
                            {{ item.description }}
                          </p> -->
                          <p class="mt-2">{{ getMenuAmount(item) }}</p>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-card>

            <v-row justify="center" no-gutters>
              <p class="text-caption">
                {{ $t("loginpage.agree1") }}
                <a :href="'/termsconditions'" class="text-decoration-none text-info">
                  {{ $t("loginpage.tou") }}
                </a>
                {{ $t("loginpage.agree2") }}
                <a :href="'/privacypolicy'" class="text-decoration-none text-info">
                  {{ $t("loginpage.privacy") }}
                </a>
                {{ $t("loginpage.statement") }}.
              </p>

              <v-btn color="green" width="90%" class="mb-0 white--text" height="50px" type="submit" @click="validate"
                :ripple="false">{{ $t("reservation.book_now") }}</v-btn>
            </v-row>
          </v-form>
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>
  </v-sheet>
  <VSonner position="top-center" />

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular :indeterminate="loadingDialog" :size="100" color="blue" class="mt-12">{{
      $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>

</template>

<style scoped>
.no-select-reserve {
  max-width: 50px;
  text-align: center;
}
</style>

<script>
import "../assets/reserve-stepper.css";
import "form-wizard-vue3/dist/form-wizard-vue3.css";
import ReservationForm from "../views/ReservationForm";
import ReserveSelectStaffModal from "@/components/ReserveSelectStaffModal.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { mapGetters } from "vuex";
import { useHead } from "@vueuse/head";
import utils from "@/uitls/Uitls.js";
import { VSonner, toast } from "vuetify-sonner";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  components: {
    ReservationForm,
    VSonner,
    ReserveSelectStaffModal,
  },

  props: ["id"],

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      item: "authStore/item",
      userInfo: "userStore/userInfo",
    }),
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });
  },

  data() {
    return {
      loadingDialog: false,
      shopData: {
        id: this.id,
      },
      isSelectedBookOnly: false,
      appointmentSetting: null,
      local_logo: require("@/assets/img/logo120.png"),
      bookingDate: "",
      selectTimeBtn: "",
      e1: 0,
      staffs: [],
      calendarReservationList: [],
      menuSelect: [],
      menuSelectedItems: [],
      comment: "",
      userName: cookies.get("userName"),
      userEmail: cookies.get("userEmail"),
      userPhone: cookies.get("userPhone"),
      reserveDataScheduleType1: {
        staff: null,
        firstReserve: "1",
        bookingFirstDate: null,
        first_time: "",
        first_minutes: "",
        bookingSecondDate: null,
        second_time: "",
        second_minutes: "",
        bookingThirdDate: null,
        third_time: "",
        third_minutes: "",
      },
      hours: [],
      minutes: ["00", "30"],
      requiredDate: (value) => !!value || "Required.",
    };
  },

  watch: {
    async bookingDate(to, from) {
      const vm = this;

      const paramsForReservation = {
        sort: "reserved_at",
        status: vm.searchStatuses ? vm.searchStatuses.join(",") : null,
        shop_id: vm.id,
        starts_at: to,
      };

      try {
        vm.calendarReservationList = await vm.$store.dispatch(
          "reservationStore/fetchReservationListForCalender",
          paramsForReservation
        );
      } catch (e) {
        console.error(e);
      }
    },
  },

  async created() {
    this.parseShop();
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },

    async parseShop() {
      const vm = this;
      vm.shopData = await vm.$store.dispatch("shopStore/fetchShop", vm.id);

      if (vm.userInfo) {
        vm.userName = vm.userInfo.name
        vm.userEmail = vm.userInfo.email
        if (vm.userInfo.profile) {
          vm.userPhone = vm.userInfo.profile.phone
        }
      }

      if (vm.shopData == null) {
        vm.$router.push("/404");
        return;
      }

      if (
        vm.shopData.active_appointment_settings &&
        vm.shopData.active_appointment_settings.length > 0
      ) {
        vm.appointmentSetting = vm.shopData.active_appointment_settings[0];
        if (vm.appointmentSetting.reservation_start_time) {

          let startHour = 10
          let endHour = 22
          const splitStartHour = vm.appointmentSetting.reservation_start_time.split(":")
          const splitEndHour = vm.appointmentSetting.reservation_end_time.split(":")
          if (splitStartHour.length > 0) {
            startHour = parseInt(splitStartHour[0], 10);
          }
          if (splitEndHour.length > 0) {
            endHour = parseInt(splitEndHour[0], 10);
          }
          for (let hour = startHour; hour <= endHour; hour++) {
            vm.hours.push(hour);
          }
        }
        if (vm.appointmentSetting.reservation_time_unit) {
          if (vm.appointmentSetting.reservation_time_unit == 60) {
            vm.minutes = ["00"];
          } else {
            vm.minutes = ["00", "30"];
          }
        }
      }

      this.getStaffList();
    },

    prefectureCodeToString(code) {
      return utils.prefectureCodeToString(code);
    },

    checkHaveBookingDate() {
      if (this.bookingDate) {
        return true;
      } else {
        return false;
      }
    },

    prevOnCLick() {
      this.e1--;
    },

    nextOnCLick() {
      const vm = this;
      if (this.e1 == 0) {
        if (vm.appointmentSetting.reservation_schedule_type === 1) {
          const reserveData = vm.reserveDataScheduleType1;
          const hasFirstDateTime =
            reserveData.bookingFirstDate &&
            reserveData.first_time &&
            reserveData.first_minutes;
          const hasSecondDateTime =
            reserveData.bookingSecondDate &&
            reserveData.second_time &&
            reserveData.second_minutes;
          const hasThirdDateTime =
            reserveData.bookingThirdDate &&
            reserveData.third_time &&
            reserveData.third_minutes;
          if (
            vm.appointmentSetting.reservation_time_limit >= 1 &&
            !hasFirstDateTime
          ) {
            toast(this.$i18n.tc("reservation.first_choice"), {
              cardProps: {
                color: "warning",
                class: "my-toast",
              },
            });
            return;
          }
          if (
            vm.appointmentSetting.reservation_time_limit >= 2 &&
            !hasSecondDateTime
          ) {
            toast(this.$i18n.tc("reservation.second_choice"), {
              cardProps: {
                color: "warning",
                class: "my-toast",
              },
            });
            return;
          }
          if (
            vm.appointmentSetting.reservation_time_limit >= 3 &&
            !hasThirdDateTime
          ) {
            toast(this.$i18n.tc("reservation.third_choice"), {
              cardProps: {
                color: "warning",
                class: "my-toast",
              },
            });
            return;
          }
          vm.e1++;
        } else if (vm.appointmentSetting.reservation_schedule_type === 2) {
          if (utils.isEmpty(this.selectTimeBtn)) {
            toast(this.$i18n.tc("reservation.select_booking_date"), {
              cardProps: {
                color: "error",
                class: "my-toast",
              },
            });
          } else {
            this.e1++;
          }
        }
      } else if (this.e1 == 1) {
        if (utils.isEmpty(this.menuSelect)) {
          toast(this.$i18n.tc("reservation.select_service"), {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        } else {
          this.getselectMenuName();
          this.e1++;
        }
      }
    },

    isSelectedBookOnlyOnClick() {
      this.menuSelect = ['-1'];
      this.menuSelectedItems = [
        {
          id: 0,
          title: this.$i18n.t('reservation.book_only'),
          required_minutes: 0,
          amount: 0,
        }
      ];
      this.e1++;
    },

    calculateAmount() {
      var totalAmount = 0;
      if (this.menuSelect.length > 0) {
        this.menuSelect.forEach((v) => {
          if (v >= 0 && this.shopData.active_menus[v]) {
            const element = this.shopData.active_menus[v];
            totalAmount += element.amount;
          }
        })
      }
      // console.log('menuSelect', this.menuSelect);
      // for (let index = 0; index < this.menuSelect.length; index++) {
      //   const value = this.menuSelect[index];
      //   console.log('value', value);
      //   const element = this.shopData.active_menus[value];
      //   console.log('element', element);
      //   // const element = this.appointmentSetting.active_menus[value];
      //   totalAmount += element.amount;
      // }
      return totalAmount;
    },

    getselectMenuName() {
      this.menuSelectedItems = [];

      if (this.menuSelect.length > 0) {
        this.menuSelect.forEach((v) => {
          if (v >= 0 && this.shopData.active_menus[v]) {
            const element = this.shopData.active_menus[v];
            this.menuSelectedItems.push(element)
          }
        })
      }

      // for (let index = 0; index < this.menuSelect.length; index++) {
      //   const value = this.menuSelect[index];
      //   const element = this.shopData.active_menus[value];
      //   // const element = this.appointmentSetting.active_menus[value];
      //   this.menuSelectedItems.push(element);
      // }
    },

    validate(e) {
      if (this.userName && this.userEmail && this.userPhone) {
        // TODO

        this.confirmBooking();
        // this.$router.push({ name: "ReservationSubmitCompleted" });
        return true;
      } else {
        toast(this.$i18n.tc("contactus.value_required"), {
          cardProps: {
            color: "error",
            class: "my-toast",
          },
        });
      }
    },

    btnOnClick(row, col) {
      // console.log("Btn OnCLick : " + row + " / " + col);
      // this.selectTimeBtn =  row + " / " + col;
    },

    btnToggleChange(event) {
      console.log("btnToggleChange  : " + event.target.tagName);
    },

    async getStaffList() {
      const tmpStaffs = await this.$store.dispatch("staffStore/fetchStaffs", {
        shop_id: this.id,
        sort: "name",
        per_page: 2000,
      });
      if (!tmpStaffs) {
        return;
      }
      this.staffs = tmpStaffs;
    },

    async confirmBooking() {
      const vm = this;

      // 選択した情報をセット - メニュー
      let menuIds = [];
      // for (let index = 0; index < this.menuSelect.length; index++) {
      //   const value = this.menuSelect[index];
      //   const element = this.shopData.active_menus[value];
      //   // const element = this.appointmentSetting.active_menus[value];
      //   menuIds.push(element.id);
      // }
      if (this.menuSelect.length > 0) {
        this.menuSelect.forEach((v) => {
          if (v >= 0 && this.shopData.active_menus[v]) {
            const element = this.shopData.active_menus[v];
            menuIds.push(element.id)
          } else if (v == -1) {
            menuIds.push(0)
          }
        })
      }

      // 選択した情報をセット - 時間、スタッフ
      const [selectedTime, staffId] = vm.selectTimeBtn.split("-");
      const reserveDate = vm.bookingDate + " " + selectedTime + ":00";

      // type - 1
      const reserveData = vm.reserveDataScheduleType1;
      const hasFirstDateTime =
        reserveData.bookingFirstDate &&
        reserveData.first_time &&
        reserveData.first_minutes;
      const hasSecondDateTime =
        reserveData.bookingSecondDate &&
        reserveData.second_time &&
        reserveData.second_minutes;
      const hasThirdDateTime =
        reserveData.bookingThirdDate &&
        reserveData.third_time &&
        reserveData.third_minutes;
      const hasNomitationStaff = !!reserveData.staff;
      const firstDateTime =
        reserveData.bookingFirstDate +
        " " +
        reserveData.first_time +
        ":" +
        reserveData.first_minutes;
      const bookingAtOrStartsAt1 = hasFirstDateTime
        ? firstDateTime
        : reserveDate;

      const params = {
        menu_ids: menuIds,
        provider_code: vm.shopData.product.provider_code,
        staff_id: hasNomitationStaff ? reserveData.staff.id : staffId,
        user_id: vm.userInfo.id,
        shop_id: vm.shopData.id,
        booking_at: bookingAtOrStartsAt1,
        // desired_staff_type: "No desired",
        request_memo: vm.comment,
        provider_customer_status: 0,
        // reservation_schedule_type == 1
        starts_at_1: bookingAtOrStartsAt1,
        starts_at_2: hasSecondDateTime
          ? reserveData.bookingSecondDate +
          " " +
          reserveData.second_time +
          ":" +
          reserveData.second_minutes
          : null,
        starts_at_3: hasThirdDateTime
          ? reserveData.bookingThirdDate +
          " " +
          reserveData.third_time +
          ":" +
          reserveData.third_minutes
          : null,
      };
      this.loadingDialog = true
      await this.$store
        .dispatch("reservationStore/storeReservationV2", params)
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "ReservationSubmitCompleted",
              query: { ur_id: response.id },
            });
          } else {
            this.$router.push({
              name: "HomeView",
            });
          }
        })
        .catch((err) => {
          alert("Reservation Error. Return to Toppage");
          console.error(err.response);
          setTimeout(() => {
            this.$router.push({
              name: "HomeView",
            });
          }, 1000);
        })
        .finally(() => {
          this.loadingDialog = false
        })
    },
    // reservation_schedule_type == 1
    submitStaff(item) {
      const vm = this;
      if (item.staff) {
        vm.reserveDataScheduleType1.staff = item.staff;
      }
    },
    formatDateJp(date) {
      if (!date) return null;
      if (this.$i18n.getLocaleCookie() === "jp") {
        return this.$utils.jpDate(date);
      } else if (this.$i18n.getLocaleCookie() === "en-my") {
        return this.$utils.enDate(date);
      }
    },

    getMenuAmount(menuData) {
      let result = this.$i18n.t('reservation.freeOfCharge')
      if (menuData.amount && parseFloat(menuData.amount)) {
        result = 'RM' + menuData.amount
      }
      return result
    },
  },
};
</script>


<style scoped>
/* ヘッダ(th)の固定 */
.my-table>>>th:nth-child() {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9999;
}

/*  行（td）の固定 */
.my-table>>>tr td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9999;
}

::v-deep v-window-item {
  background: #8fc31f;
}
</style>