<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters>
        <v-col cols="2" align-self="start">
          <v-img
            class="bg-black ma-4"
            :aspect-ratio="1"
            src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
          ></v-img>
        </v-col>

        <v-col cols="10">
          <p class="mx-4 mt-4 text-red" style="font-weight: normal">
            Today's Appointment
          </p>

          <p class="mx-4 mt-2 text-black text-h4" style="font-weight: bold">
            {{ currentDate() }} 13:00~
          </p>

          <!-- <v-divider></v-divider> -->

          <v-row class="pa-4">
            <v-col cols="6">
              <div class="text-h5">Gold Salon</div>

              <div class="mt-0">
                <v-icon
                  icon="mdi-map-marker"
                  size="16"
                  color="black"
                  class="mx-0 mt-n1"
                />
                <span class="mx-2" style="font-size: 12px"
                  >KL Pavilion Mall</span
                >
              </div>
              <div class="mt-1">
                <v-icon
                  icon="mdi-phone"
                  color="black"
                  size="16"
                  class="mx-0 mt-n1"
                /><span style="font-size: 12px" class="mx-2">03-2937-2947</span>
              </div>
              <div class="mt-1">
                <v-icon
                  icon="mdi-briefcase"
                  color="black"
                  size="16"
                  class="mx-0 mt-n1"
                /><span style="font-size: 12px" class="mx-2"
                  >10:00am ~ 9:00pm (Mon off)</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    ~~~ Copy my page reservation !!! ~~~

    <v-list lines="one" class="mt-4">
      <v-list-subheader>Future Reservation</v-list-subheader>

      <v-list-item v-for="item in futureList" :key="item.shopName">
        <template v-slot:prepend>
          <p class="text-h5 mx-2" style="font-weight: bold">{{ item.date }}</p>
        </template>

        <v-list-item-title>{{ item.shopName }}</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <v-list lines="one" class="mt-4">
      <v-list-subheader>Passed Reservation</v-list-subheader>

      <v-list-item v-for="item in futureList" :key="item.shopName">
        <template v-slot:prepend>
          <p class="text-h5 mx-2" style="font-weight: bold">{{ item.date }}</p>
        </template>

        <v-list-item-title>{{ item.shopName }}</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>


  </v-container>
</template>

<script>
export default {
  data() {
    return {
      futureList: [
        {
          shopName: "Premium Salon",
          date: "15/4/2023 13:00~",
          shopImage: "/salon.jpeg",
        },
        {
          shopName: "Earth Beauty",
          date: "23/5/2023 14:00~",
          shopImage: "/salon.jpeg",
        },
        {
          shopName: "Asahi Salon",
          date: "1/8/2023 11:00~",
          shopImage: "/salon.jpeg",
        },
      ],

      categoryList: [
        { name: "Nail", avatar: "/nail.jpg" },
        { name: "Body care", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Aromatherapy", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
      ],
    };
  },

  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },
  },
};
</script>

<style>
</style>