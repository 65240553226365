// https://github.com/fgr-araujo/vue-breadcrumbs/blob/6c743c5d248a816c36055cd5ae18939d0760fb33/src/router/index.js
// https://medium.com/@fagnersaraujo/automated-breadcrumbs-with-vuejs-7e1051de8028

<template>
  <v-sheet class="breadcrumb pa-0 ma-0 mx-auto" height="100%">
    <v-breadcrumbs :items="breadCrumbsProps.data" divider="-" density="compact">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :href="item.to"
          class="text-body-2"
          :disabled="item.disabled"
          exact
        >
          <div class="mt-0" v-snip="{ lines: 2 }">
            {{ $t(item.name) }}
          </div>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-sheet>
</template>

<script>
export default {
  props: {
    breadCrumbsProps: Object,
  },

  computed: {
    breadCrumbs() {
      if (typeof this.$route.meta.breadCrumb === "function") {
        return this.$route.meta.breadCrumb.call(this, this.$route);
      }
      return this.$route.meta.breadCrumb;
    },
  },
};
</script>

<style scoped>
</style>