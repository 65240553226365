import { CROWDFUNDING_API } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';

const state = {
  crowdDetail: null,
};

const getters = {
  crowdDetail: (state) => state.crowdDetail,
};

const mutations = {
  SET_DATA: (state, data) => {
    state.crowdDetail = data;
  },
};

const actions = {
  async fetchCrowds({ commit }, params = {}) {

    try {
      const response = await axiosClient.get(`${CROWDFUNDING_API}`, { params })
      return response.data
    }catch (error) {
      return null
    }

    /*
    let url = CROWDFUNDING_API;
    await axiosClient
      .get(url)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          console.log("%o", response);
          commit("SET_DATA", response.data);
          return response.data
        } else {
          return Promise.reject;
        }
      })
      .catch((error) => {
        // handle error
        console.error(error);
        throw error;
      });
      */
  },

  async fetchCrowd({ commit }, crowdfundingId) {
    try {
      const response = await axiosClient.get(`${CROWDFUNDING_API}/${crowdfundingId}`)
      commit('SET_DATA', response.data);
      return response.data
    } catch (error) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
