<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn class="ma-0" icon="mdi-arrow-left" @click="$router.push('/settingpage')"></v-btn>
    <v-app-bar-title>{{$t('settingpage.new_password')}}</v-app-bar-title>
  </v-app-bar>
  <v-main>
    <v-container fluid>
      <v-sheet
        elevation="0"
        max-width="1200"
        rounded="lg"
        width="100%"
        class="pa-0 mx-auto mt-6"
      >
        <v-text-field
          :label="$t('settingpage.new_password')"
          variant="underlined"
          v-model="passwordNew"
          prepend-icon="mdi-lock"
          clearable
          :hint="$t('settingpage.password_alert')"
          persistent-hint
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          counter
          @click:append="showPassword = !showPassword"
          :error-messages="v$.passwordNew.$errors.map((e) => e.$message)"
          @input="v$.passwordNew.$touch"
          @blur="v$.passwordNew.$touch"
        ></v-text-field>

        <v-row justify="center" no-gutters>
          <v-btn
            elevation="0"
            color="blue"
            width="93%"
            class="ma-6"
            height="50px"
            :ripple="false"
            rounded="pill"
            :loading="loading"
            @click="checkUserPassword"
          >
          {{$t("settingpage.submit")}}
          </v-btn>
        </v-row>
      </v-sheet>
    </v-container>
  </v-main>
  <VSonner position="top-center" />
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import { VSonner, toast } from "vuetify-sonner";

export default {
  components: {
    VSonner,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  computed: {
    ...mapGetters({
      userInfo: "userStore/userInfo",
    }),
  },

  data: () => ({
    passwordNew: null,
    showPassword: false,
    loading: false,
    userPost: {
      id: null,
    },
  }),

  validations() {
    return {
      passwordNew: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          return containsUppercase && containsLowercase && containsNumber;
        },
        minLength: minLength(6),
      },
    };
  },

  mounted() {
    this.userPost.id = this.userInfo.id;
  },

  methods: {
    async checkUserPassword() {
      if (!this.passwordNew) return;

      this.loading = true;

      const dataUpdate = {};
      dataUpdate.user = this.userPost;
      dataUpdate.password = this.passwordNew;

      await this.$store
        .dispatch("userStore/updateUserInfo", dataUpdate)
        .then((data) => {
          this.loading = false;
          toast("Password changed.", {
            cardProps: {
              color: "success",
              class: "my-toast",
            },
          });

          setTimeout(() => {
            this.$router.push({ name: "SettingPage" });
          }, 3000);
        })
        .catch((e) => {
          this.loading = false;
          console.error("error: ", e);
          toast("Password change faild, please try again.", {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },
  },
};
</script>