<template>
  <v-sheet class="pa-0 ma-0" color="grey-lighten-4">
    <v-card
    class="mx-auto"
    color="yellow"
    :title="$t('shop.comingSoon')"
    :subtitle="$t('shop.underConstruction')"
    prepend-icon="mdi-alert"
   ></v-card>
    <br />
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon icon="mdi-star" color="blue-darken-1" size="24px" class="mb-1"></v-icon>
      {{ $t("homeview.popular_hair_style")}}
    </p>
    <!-- <v-divider></v-divider> -->

    <v-container class="pa-0 mt-4">
      <v-row no-gutters center warp justify="center" class="d-flex">
        <v-col v-for="n in 4" :key="n" cols="6" xs="3" sm="3">
          <v-card flat class="text-left ma-3" color="white">
            <v-responsive class="pt-0">
              <v-img
                max-height="90%"
                src="./girl.jpg"
                class="fill-width"
                cover
                aspect-ratio="1"
              ></v-img>
            </v-responsive>

            <v-card-text class="mt-n4">
              <div class="text-subtitle-1" style="font-weight: normal">
                Balayage Ash Color Shiny Noble Front Falling Short
              </div>

              <div class="mt-2 text-body-2" style="font-weight: normal">
                <v-icon
                  icon="mdi-store"
                  size="16"
                  color="black"
                  class="mt-n1"
                />
                PATIONN 表参道
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div align="center">
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
        >{{ $t("homeview.show_more") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      shopList: [
        { name: "Earth Beauty", review: "★★★★★", avatar: "/salon.jpeg" },
        { name: "Asahi Salon", review: "★★★", avatar: "/salon.jpeg" },
        { name: "Premium Salon", review: "★★★★", avatar: "/salon.jpeg" },
        { name: "Nail Salon", review: "★★★★★", avatar: "/salon.jpeg" },
      ],

      categoryList: [
        { name: "Nail", avatar: "/nail.jpg" },
        { name: "Body care", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Aromatherapy", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
      ],
    };
  },

  computed: {},
};
</script>

<style>
</style>