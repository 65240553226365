<template>
  <v-container fluid>
    <v-sheet
      elevation="1"
      max-width="580"
      rounded="lg"
      width="100%"
      class="pa-8 text-center mx-auto mt-4"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-lock-reset"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">{{ $t("loginpage.pass_forget") }}</h2>

      <p class="mb-4 text-body-1 text-grey">
        {{ $t("loginpage.your_password_desc") }}
      </p>

      <form>
        <v-text-field
          hide-details="auto"
          v-model="email"
          variant="underlined"
          type="email"
          :label="$t('loginpage.email')"
          :readonly="loading"
          clearable
          :placeholder="$t('loginpage.your_email')"
          prepend-icon="mdi-email"
          :error-messages="v$.email.$errors.map((e) => e.$message)"
          @input="v$.email.$touch"
          @blur="v$.email.$touch"
        ></v-text-field>

        <v-btn
          class="mt-4"
          :ripple="false"
          color="success"
          rounded
          variant="flat"
          width="90"
          block
          height="40"
          :loading="loading"
          @click="checkUserEmail"
        >
          {{ $t("loginpage.reset") }}
        </v-btn>
      </form>
    </v-sheet>
  </v-container>
  <v-dialog v-model="showDialog" width="auto">
    <v-card>
      <v-card-item>
        <template v-slot:title>
          <v-icon
            icon="mdi-email-fast"
            size="30"
            color="blue"
            class="mx-2"
          ></v-icon>
          <v-card-text>{{ $t("loginpage.reset_desc") }}</v-card-text>
        </template>
      </v-card-item>

      <v-card-actions>
        <v-btn block :to="{ name: 'HomeView' }">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <VSonner position="top-center" />
</template>

<script scoped>
import { VSonner, toast } from "vuetify-sonner";
import { mapState, mapActions, mapGetters } from "vuex";
import firebase from "@/plugins/firebase";

import { useVuelidate } from "@vuelidate/core";
import {
  email,
  required,
  sameAs,
  helpers,
  minLength,
  numeric,
  maxLength,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    VSonner,
  },

  computed: {
    ...mapState({
      checkEmail: (state) => state.CheckUserEmailStore.property,
    }),
  },

  data() {
    return {
      showDialog: false,
      email: null,
      checkEmailProperty: null,
      loading: false,
    };
  },

  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },

  methods: {
    ...mapActions({
      parseData: "CheckUserEmailStore/getData",
    }),

    async checkUserEmail() {
      if (this.validateEmail(this.email)) {
        this.loading = true;
        await this.parseData(this.email)
          .then(
            () => {
              this.loading = false;
              this.checkEmailProperty = this.checkEmail;
              if (this.checkEmailProperty.result === 1) {
                this.resetPassword();
              } else {
                toast(this.$i18n.tc("loginpage.mail_not_register"), {
                  cardProps: {
                    color: "warning",
                    class: "my-toast",
                  },
                });
              }
            },
            (error) => {
              console.error("Nothing from server." + error);
            }
          )
          .catch((e) => {
            console.error("error: ", e);
          });
      } else {
        toast(this.$i18n.tc("loginpage.pls_valid_mail"), {
          cardProps: {
            color: "error",
            class: "my-toast",
          },
        });
      }
    },

    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return true;
      } else {
        return false;
      }
    },

    resetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then((res) => {
          this.showDialog = true;
        })
        .catch((error) => {
          console.info(error);
        });
    },
  },
};
</script>

<style scoped>
</style>