<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-img
      :src="headerImage"
      class="align-center"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
      max-height="400px"
      aspect-ratio="16/9"
      cover
    >
      <p class="text-h4 mt-4 font-weight-bold text-center text-white">
        {{ $t(checkText(area)) }}
      </p>
      <p class="text-body-2 text-white text-center mt-2 pa-4">
        {{ $t("areaview." + area + "_desc") }}
      </p>
    </v-img>

    <v-sheet class="mt-6">
      <ArticleBanner />
    </v-sheet>

    <v-sheet class="mt-12">
      <LocationShopList @shopId="callBackFromShopCard" />
    </v-sheet>

    <!-- AreaCard -->
    <AreaCards @callBackFromAreaCard="callBackFromAreaCard" />

    <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" class="mt-0" />
  </v-sheet>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import utils from "@/uitls/Uitls";
import ArticleBanner from "@/views/Article/ArticleBanner.vue";
import LocationShopList from "@/components/LocationShopList.vue";
import AreaCards from "@/components/AreaCards.vue";

export default {
  props: ["area"],

  components: {
    Breadcrumbs,
    ArticleBanner,
    LocationShopList,
    AreaCards,
  },

  computed: {
    breadCrumbsProps: function () {
      console.log(this.area);
      if (this.area == "all_area") {
        return {
          data: [
            { name: "mypage.topPage", to: "/" },
            { name: "prefecture.all_area", to: "/area/all_area" },
          ],
        };
      } else {
        return {
          data: [
            { name: "mypage.topPage", to: "/" },
            { name: "prefecture.all_area", to: "/area/all_area" },
            {
              name: "prefecture.kl",
              to: "/area/" + this.area,
            },
          ],
        };
      }
    },

    headerImage: function () {
      switch (this.area) {
        case "all_area":
          return require("@/assets/img/allarea.jpg");
        case "kl":
          return require("@/assets/img/kl.jpg");
        default:
          return require("@/assets/img/allarea.jpg");
      }
    },
  },

  data() {
    return {};
  },

  methods: {
    callBackFromAreaCard(item) {
      if (item && item.title) {
        const areaText = item.title.replace("prefecture.", "");
        this.$router.push({
          name: "AreaView",
          params: { area: areaText },
        });
      } else {
        this.$router.push({
          name: "AreaView",
          params: { area: 'all_area' },
        });
      }
    },

    checkText(value) {
      if (value.includes("prefecture.")) {
        return value;
      } else {
        return "prefecture." + value;
      }
    },
  },
};
</script>

<style scoped>
</style>