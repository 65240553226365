//https://pystyle.info/vue-internationalization-plugin-vue-i18/
//https://thilog.com/vueslsapp-vuei18n/
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
// https://tcd-theme.com/2021/11/javascript-cookie.html
import { createI18n } from 'vue-i18n'
import en from "@/locales/en.json";
import zhCN from "@/locales/zhCN.json";
import ms from "@/locales/ms.json";

let langBrowser = navigator.language.split("-")[0];
if (getCookie("locale") != null) {
  langBrowser = getCookie("locale");
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const i18n = new createI18n({
  locale: langBrowser,
  fallbackLocale: 'en',
  globalInjection: true,
  reloadOnLanguageChange: false,
  messages: { en, zhCN, ms },
})


export default i18n