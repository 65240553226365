<template>
  <v-dialog class="staff-dialog" :value="dialog" @click:outside="close()" max-width="400px" scrollable>
    <template v-slot:default="">
      <v-card v-if="staff" class="staff-dialog__card">
        <v-card-title class="justify-end px-0">
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="staff-dialog__card--content shop-page px-0">
          <div class="order-noalter-container">
            <div class="item">
              <div ref="leftProfileHeight" id="leftProfileHeight" class="content-block">
                <div v-if="staffPictures && staffPictures.length > 0" class="shop-page__image--wrapper mb-5">
                  <div v-if="staff.authority_label" class="manager-label">
                    <div class="manager-label__border"></div>
                    <div class="manager-label__word">{{ staff.authority_label }}</div>
                  </div>
                  <ImageExpandModal :imgList="staffPictures" :expand="false"></ImageExpandModal>
                </div>
                <div v-else class="shop-page__image--wrapper mb-5">
                  <div v-if="staff.authority_label" class="manager-label">
                    <div class="manager-label__border"></div>
                    <div class="manager-label__word">{{ staff.authority_label }}</div>
                  </div>
                  <ImageExpandModal :isStaff="true"></ImageExpandModal>
                </div>
                <div class="product-info__container">
                  <img :src="shop.product ? shop.product.picture_path : ''" class="product-info__image py-1">
                  <div class="staff-info__name title-font mb-3">{{ staff.name && staff.family_name ? staff.family_name + staff.name : staff.name }}</div>
                  <div class="sub-body">
                    <p class="pb-3">{{ staff.family_name_furigana }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped>
.product-info__container {
  width: 100%;
}

.product-info__image {
  width: 50px !important;
  margin-top: -10px;
}

.staff-info__name {
  margin-top: 4px;
  font-size: 2.4rem;
  font-weight: 800;
}

.shop-page__image--wrapper {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.manager-label {
  top: 0;
  left: 0;
  width: 110px;
  height: 70px;
  position: absolute;
  overflow: hidden;
  z-index: 10;
}

.manager-label__border {
  border-style: solid;
  border-width: 70px 110px 0 0;
  border-color: lightgreen transparent transparent transparent;
}

.manager-label__word {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #fbfbfb;
  font-size: 2rem;
  font-weight: bold;
}

html {
  overflow: hidden;
}

.staff-dialog__card {
  padding: 0 28px 28px 28px;
  max-width: 800px;
  overflow-x: hidden;
}

.staff-dialog__card--content::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}

.order-noalter-container {
  margin-top: 20px;
}

.order-noalter-container .item {
  padding-bottom: 0 !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .staff-dialog__card {
    padding: 0 20px;
  }
  
  .order-noalter-container {
    flex-direction: column;
  }

  .order-noalter-container .left-item {
    order: 2;
    width: 100%;
    padding-right: unset;
  }

  .order-noalter-container .right-item {
    order: 1;
    width: 100%;
  }

  .order-noalter-container .item {
    padding: 0 !important;
  }

  .shop-page__image--wrapper {
    max-width: 450px !important;
    margin: auto !important;
    position: relative;
  }

  .shop-page .content-block {
    padding: unset !important;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}
</style>
<script>
import ImageExpandModal from "@/components/ImageExpandModal.vue";
// import StaffInfo from '~/components/staff/StaffInfo'
export default {
  components: {
    ImageExpandModal,
    // StaffInfo,
  },
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
    shop: {
      type: Object,
      default() {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      staffRank: {
        // advance:
        //   parseInt(this.staff.ficks_rank_id) ===
        //   this.$utils.staffRank['アドバンス'].id
        //     ? 1
        //     : 0,
        // specialist:
        //   parseInt(this.staff.ficks_rank_id) ===
        //   this.$utils.staffRank['スペシャリスト'].id
        //     ? 1
        //     : 0,
        // expert:
        //   parseInt(this.staff.ficks_rank_id) ===
        //   this.$utils.staffRank['エキスパート'].id
        //     ? 1
        //     : 0,
        // master:
        //   parseInt(this.staff.ficks_rank_id) ===
        //   this.$utils.staffRank['マスター'].id
        //     ? 1
        //     : 0,
        // grand:
        //   parseInt(this.staff.ficks_rank_id) ===
        //   this.$utils.staffRank['グランドトレーナー'].id
        //     ? 1
        //     : 0,
      },
      staffOption: {
        face_course: this.staff.face_course === 1 ? 1 : 0,
        leg_course: this.staff.leg_course === 1 ? 1 : 0,
      },
      staffAward: this.staff.award ? this.staff.award.split(',') : [],
      techniques: this.staff.technique ? this.staff.technique.split(',') : [],
      staffTechnique: null,
      staffHobby: this.staff.hobby ? this.staff.hobby.split(',') : [],
      staffSport: this.staff.sports ? this.staff.sports : [],
      staffBirthPlace: this.staff.birthplace ? this.staff.birthplace : null,
      staffSelfIntro: this.staff.description ? this.staff.description : null,
      //   mdiClose,
      staffPictures: [],
    }
  },
  computed: {
    showDialog() {
      const $html = document.querySelector('html')
      $html.style.overflow('hidden')
      return this.dialog
    },
  },
  async beforeMount() {
    await this.fetchStaffPictures()
  },
  mounted() {
    this.staffTechnique = this.techniques.filter((technique) =>
      this.$utils.techniqueData.includes(technique)
    )
  },
  methods: {
    close() {
      this.$emit('close')
    },
    // Staff Pictures 処理
    async fetchStaffPictures() {
      const vm = this
      await vm.$store
        .dispatch('staffStore/fetchStaffPictures', {
          page: 1,
          per_page: 20,
          sort: '-id',
          staff_id: vm.staff.id,
        })
        .then((response) => {
          if (vm.staff.picture_url) {
            vm.staffPictures.push(vm.staff.picture_url)
          }
          if (response.data) {
            for (let i = 0; i < response.data.length; i++) {
              vm.staffPictures.push(response.data[i].picture_url)
            }
          }
        })
    },
  },
}
</script>
