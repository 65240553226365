<template>
  <div :class="{ 'card-staff': true, 'card-staff--border': isBorder }">
    <button class="card-wrap">
      <div class="card-staff__header">
        <div class="card-staff__block">
          <div style="position: relative">
            <!-- <div v-if="staffDetailData.id !== 0 && staffDetailData.authority_label" class="manager-label">
              <div class="manager-label__border"></div>
              <div class="manager-label__word">{{ staffDetailData.authority_label }}</div>
            </div> -->
            <img v-if="staffDetailData.picture_url_small" :src="staffDetailData.picture_url_small"
              class="card-staff__img">
            <img v-else :src="defaultStaffImg" style="width: 110%; height: 153px;" class="card-staff__img">
            <!-- <div class="card-staff__rank">
              <div :class="$utils.staffRank[staffDetailData.rank] ? $utils.staffRank[staffDetailData.rank].class : 'no-active'">
                {{ $utils.staffRank[staffDetailData.rank] ? $utils.staffRank[staffDetailData.rank].title : staffDetailData.rank }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="card-staff__content">
        <div class="card-staff__content-wrapper">
          <div class="left">
            <div class="card-staff__staff">
              {{ staffDetailData.displayName }}
            </div>
          </div>
          <!-- <div v-if="shop && staffDetailData.id !== 0" @click="showStaffDetailModal()" class="right">
            <v-icon>mdi-information-outline</v-icon>
          </div> -->
        </div>
      </div>
    </button>
    <!-- <StaffDetailBookingModal v-if="shop" :dialog="isStaffDetailModalShow" :staff="staffDetailData" :shop="shop" @close="isStaffDetailModalShow = false">
    </StaffDetailBookingModal> -->
  </div>
</template>

<style scoped>
/* Define CSS Variables */
:root {
  --bg-white: #ffffff;
  /* Example: change as needed */
  --border-gray-lighten: #cccccc;
  /* Example: change as needed */
  --margin-m: 16px;
  /* Example: change as needed */
  --white: #ffffff;
  /* Likely redundant, but defined if different */
  --transition-normal: all 0.3s ease;
  /* Example: change as needed */
  --brand-color: #ff5722;
  /* Example: change as needed */
  --advance-color: #f0ad4e;
  /* Example: change as needed */
  --specialist-color: #5bc0de;
  /* Example: change as needed */
  --expert-color: #5cb85c;
  /* Example: change as needed */
  --master-color: #0275d8;
  /* Example: change as needed */
  --grand-color: #d9534f;
  /* Example: change as needed */
  --radius-m: 8px;
  /* Example: change as needed */
  --margin-s: 8px;
  /* Example: change as needed */
  --white-darken: #e0e0e0;
  /* Example: change as needed */
  --font-s: 12px;
  /* Example: change as needed */
}

.card-staff {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  background: var(--bg-white);
  border: unset;
  border-radius: 7px;
  box-sizing: border-box;
  transition: var(--transition-normal);
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.card-staff--border {
  border: 1px solid var(--border-gray-lighten);
}

.card-staff__header {
  position: relative;
}

.card-staff__content {
  padding: var(--margin-m);
  margin-bottom: var(--margin-m);
  background: var(--white);
  position: relative;
}

.card-staff__content:last-child {
  margin-bottom: 0;
}

.card-staff__content-wrapper {
  display: flex;
  justify-content: space-between;
}

.card-staff__img {
  display: block;
  max-width: 100%;
  object-fit: cover;
}

.card-staff__button {
  display: block;
  height: auto;
  position: absolute;
  right: 4.8%;
  bottom: -18px;
}

.card-staff:hover .card-staff__content {
  color: var(--brand-color);
}

.card-staff__block {
  position: relative;
}

.card-staff__rank {
  display: block;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-staff__staff {
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  padding-left: 4px;
}

.card-staff__info {
  text-align: left;
  font-weight: 500;
  font-size: 10px;
}

.card-staff__checkbox {
  margin-top: 20px;
  margin-right: -25px;
}

.card-staff__checkbox::before {
  display: none !important;
}

.card-staff__checkbox:hover {
  background-color: unset !important;
  color: unset !important;
}

.card-staff__checkbox .v-icon {
  background-color: green !important;
  border-radius: 40px !important;
  height: 20px !important;
  width: 20px !important;
}

.rank-chip {
  height: auto !important;
  font-size: 12px !important;
  font-weight: bold;
  margin: unset !important;
  color: var(--white);
  padding: 2px 6px;
}

.rank-chip__advance--active {
  background-color: var(--advance-color) !important;
}

.rank-chip__specialist--active {
  background-color: var(--specialist-color) !important;
}

.rank-chip__expert--active {
  background-color: var(--expert-color) !important;
}

.rank-chip__master--active {
  background-color: var(--master-color) !important;
}

.rank-chip__grand--active {
  background-color: var(--grand-color) !important;
}

.no-active {
  display: none;
}

.manager-label {
  top: 0;
  left: 0;
  width: 70px;
  height: 60px;
  position: absolute;
  border-top-left-radius: var(--radius-m);
  overflow: hidden;
}

.manager-label__border {
  border-style: solid;
  border-width: 60px 70px 0 0;
  border-color: var(--brand-color) transparent transparent transparent;
}

.manager-label__word {
  position: absolute;
  top: 4px;
  left: var(--margin-s);
  color: var(--white-darken);
  font-size: var(--font-s);
  font-weight: bold;
}

.mdi-checkbox-blank-outline::before {
  content: '' !important;
}
</style>

<script>
import { mapState } from 'vuex'
import StaffDetailBookingModal from "@/components/StaffDetailBookingModal.vue";
export default {
  components: {
    StaffDetailBookingModal,
    mapState,
  },
  props: {
    to: {
      type: String,
      default: '',
    },
    staffDetailData: {
      type: Object,
      default() {
        return { id: 0, displayName: '指定なし' }
      },
    },
    shop: {
      type: Object,
      default() { },
    },
    isBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isStaffDetailModalShow: false,
      defaultStaffImg: require("@/assets/img/staff-dummy.png"),
    }
  },
  methods: {
    showStaffDetailModal() {
      this.isStaffDetailModalShow = true
    },
  },
}
</script>
