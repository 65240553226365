<template>
  <v-sheet color="white">
    <br />
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-newspaper"
        color="blue-darken-1"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t("homeview.new_topics") }}
    </p>

    <v-container>
      <v-row>
        <v-col
          v-for="item in list"
          :key="item.id"
          cols="12"
          md="6"
          xs="12"
          sm="12"
        >
          <ArticleTableCellCard
            :articleObjects="item"
            @articleIDCallBack="articleIDCallBack"
          ></ArticleTableCellCard>
        </v-col>
      </v-row>
    </v-container>

    <div align="center">
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
        :to="{ name: 'ArticleViewList' }"
        >{{ $t("homeview.show_more") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import Constants from "@/uitls/Constants";
import ArticleTableCellCard from "@/components/ArticleTableCellCard.vue";

export default {
  components: {
    ArticleTableCellCard,
  },

  data() {
    return {
      list: undefined,
      no_IMAGE: Constants.NO_IMAGE,
    };
  },

  async mounted() {
    this.list = await this.$store.dispatch(
      "ArticleStore/getWordPressTop8Article"
    );

    await this.getWordPressCategories({
      per_page: 100,
    });
  },

  methods: {
    articleIDCallBack(articleID) {
      this.$router.push({
        name: "ArticleViewDetail",
        params: { id: articleID },
      });
    },

    getWordPressCategories(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("ArticleStore/getWordPressCategories", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            throw new Error(error);
          });
      });
    },
  },
};
</script>

<style scoped>
.text-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
</style>

