
// import { initializeApp, getApps, getApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/remote-config';


const firebaseConfig = {
  apiKey: "AIzaSyBDCACnVZrZwKqUxIFQBA9yEiznb_VVpCU",
  authDomain: "mys-beauty-crm.firebaseapp.com",
  projectId: "mys-beauty-crm",
  storageBucket: "buckets/mys-beauty-crm.appspot.com",
  messagingSenderId: "446871938156",
  appId: "1:446871938156:web:30629bcd332c6405932449",
  measurementId: "G-MCBPYFS2CB"
};

// const firebase = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
// export const firebaseAnalytics = getAnalytics(firebase);

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : ''

export default firebase

