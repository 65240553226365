<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn
      class="ma-0"
      icon="mdi-arrow-left"
      @click="$router.push('/mypage')"
    ></v-btn>
    <v-app-bar-title>{{ $t("mypage.voucherHistory") }}</v-app-bar-title>
  </v-app-bar>

  <v-main>
    <v-sheet
      elevation="0"
      max-width="1200"
      rounded="lg"
      width="100%"
      class="pa-4 mx-auto mt-6"
      v-if="dataList.length > 0"
    >
      <v-card
        class="event-card mb-4"
        height="140"
        @click="cardOnClick(item)"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <v-layout row>
          <v-img
            width="140"
            max-width="140"
            cover
            :lazy-src="local_logo"
            aspect-ratio="1"
            :src="getInfo(item, 'image')"
          >
            <div class="date-ribbon">
              <p class="text-body-2" font-weight: bold>OFF</p>
              <p class="text-body-1" style="font-weight: bold">
                {{ getInfo(item, "discount_rate") }}
              </p>
            </div>
          </v-img>

          <v-layout column justify-space-between class="flex-grow-1">
            <v-row class="mx-0 mt-n4">
              <v-col cols="12">
                <div
                  class="text-body-2 mt-2"
                  v-snip="{ lines: 2 }"
                  style="color: rgb(51, 51, 51)"
                >
                  {{ getInfo(item, "name") }}
                </div>

                <p class="text-caption text-grey mt-0">
                  {{ getInfo(item, "shop") }}
                </p>
                <p class="text-caption text-grey mt-n1">
                  {{ getInfo(item, "prefecture") }}
                </p>

                <p class="mt-0 text-body-2" style="color: rgb(160, 160, 160)">
                  <strike> {{ getInfo(item, "original_price") }} </strike>&nbsp;
                  <span class="text-h6" style="color: #fe453e"
                    ><strong>{{ getInfo(item, "price") }}</strong></span
                  >
                </p>
              </v-col>
            </v-row>
          </v-layout>
        </v-layout>
      </v-card>
    </v-sheet>

    <!-- Without data -->
    <v-sheet
      elevation="0"
      max-width="1200"
      rounded="lg"
      width="100%"
      class="pa-0 mx-auto mt-6"
      v-if="dataList.length == 0"
    >
      <div>
        <v-sheet
          elevation="0"
          max-width="600"
          rounded="lg"
          width="100%"
          class="pa-4 text-center mx-auto"
        >
          <v-icon
            class="mb-5"
            color="blue"
            icon="mdi-sale-outline"
            size="112"
          ></v-icon>

          <h2 class="text-h5 mb-6">{{ $t("settingpage.noVoucherHistory") }}</h2>

          <p class="mb-4 text-medium-emphasis text-body-2">
            {{ $t("settingpage.like_shop_desc") }}
            <br />

            <a :href="'/hairsalon/searchresult'" class="text-decoration-none text-info">{{
              $t("settingpage.find_shop")
            }}</a>
          </p>
        </v-sheet>
      </div>
    </v-sheet>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-sheet height="190" class="text-center">
        <v-progress-circular
          :indeterminate="loadingDialog"
          :size="100"
          color="blue"
          class="mt-12"
          >{{ $t("homeview.loading") }}
        </v-progress-circular>
      </v-sheet>
    </v-dialog>
  </v-main>
</template>

<script scoped>
import { mapGetters } from "vuex";
import Constants from "@/uitls/Constants";
import utils from "@/uitls/Uitls";

export default {
  components: {},

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
    }),
  },

  watch: {
    isAuthenticated(to, from) {
      if (this.isAuthenticated) {
      }
    },
  },

  data() {
    return {
      loadingDialog: true,
      dataList: [],
    };
  },

  beforeMount() {
    if (!this.isAuthenticated) {
      this.$store
        .dispatch("authStore/checkUserLoginState")
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  },

  async mounted() {
    if (this.userInfo) {
      this.getUserVoucherList();
    }
  },

  methods: {
    async getUserVoucherList() {
      const params = {
        sort: "-used_at",
        user_id: this.userInfo.id,
      };

      await this.$store
        .dispatch("userStore/getUserVoucherList", params)
        .then((response) => {
          if (response) {
            this.dataList = JSON.parse(JSON.stringify(response));
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },

    getInfo(item, type) {
      let result = null;

      switch (type) {
        case "image":
          if (item.crowdfunding_item) {
            result = new URL(
              Constants.IMAGE_URL + item.crowdfunding_item.picture_path
            ).href;
          }
          break;
        case "discount_rate":
          if (item.crowdfunding_item) {
            result =
              ((item.crowdfunding_item.original_price -
                item.crowdfunding_item.price) /
                item.crowdfunding_item.original_price) *
              100;
            result = parseFloat(result.toFixed(0)) + "%";
          }
          break;
        case "name":
          if (item.crowdfunding_item) {
            result = item.crowdfunding_item.name;
          }
          break;
        case "shop":
          if (
            item.crowdfunding_item &&
            item.crowdfunding_item.crowdfunding &&
            item.crowdfunding_item.crowdfunding.shop
          ) {
            result = item.crowdfunding_item.crowdfunding.shop.name;
          }
          break;
        case "prefecture":
          if (
            item.crowdfunding_item &&
            item.crowdfunding_item.crowdfunding &&
            item.crowdfunding_item.crowdfunding.shop
          ) {
            result = this.$i18n.t(
              utils.prefectureCodeToString(
                item.crowdfunding_item.crowdfunding.shop.prefecture
              )
            );
          }
          break;
        case "original_price":
          if (item.crowdfunding_item) {
            let currency = "RM";
            if (
              item.crowdfunding_item.company &&
              item.crowdfunding_item.company.currency
            ) {
              if (item.crowdfunding_item.company.currency == "myr") {
                currency = "RM";
              } else if (item.crowdfunding_item.company.currency == "jpy") {
                currency = "Yen";
              }
            }
            result = currency + " " + item.crowdfunding_item.original_price;
          }
          break;
        case "price":
          if (item.crowdfunding_item) {
            let currency = "RM";
            if (
              item.crowdfunding_item.company &&
              item.crowdfunding_item.company.currency
            ) {
              if (item.crowdfunding_item.company.currency == "myr") {
                currency = "RM";
              } else if (item.crowdfunding_item.company.currency == "jpy") {
                currency = "Yen";
              }
            }
            result = currency + " " + item.crowdfunding_item.price;
          }
          break;
      }

      return result;
    },

    cardOnClick(item) {
      this.$router.push({
        name: "VoucherHistoryDetailPage",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style scoped>
/* https://noahbres.github.io/blog/bonfire-devlog-3-how-to-make-an-events-card-list */
.event-card .desc {
  margin-bottom: 1.2em;
  font-size: 0.16em;
  padding-left: 0.1em;
}

.event-card .date-ribbon {
  position: absolute;
  top: 0px;
  left: 1em;
  background: #fe453e;
  color: #fff;
  padding: 0em 0.3em;
  padding-bottom: 0px;
  border-radius: 0px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.event-card .date-ribbon::before,
.event-card .date-ribbon::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0%;
  height: 0px;
}

.event-card .date-ribbon::before {
  left: 0;
  border-left: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-right: solid 2em transparent;
}

.event-card .date-ribbon::after {
  right: 0;
  border-right: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-left: solid 2em transparent;
}
</style>