<template>
  <v-container fluid>
    <v-sheet
      elevation="1"
      max-width="580"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto mt-6"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-check-circle"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">Thank you for your purchase</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        To see your purchase history, click
        <a href="#" class="text-decoration-none text-info">Here to continue.</a>
        <br />

        Otherwise, you're done!
      </p>

      <v-divider class="mb-4"></v-divider>

      <v-sheet>
        <v-row justify="start" class="ma-2">
          <p class="text-body-2" style="font-weight: bold">
            Recipt ID: #Aju7jk
          </p>
        </v-row>
        <v-row justify="start" class="ma-2">
          <p class="text-body-2" style="font-weight: bold">
            Payment On: 12/8/2023
          </p>
        </v-row>
      </v-sheet>

      <v-row justify="start" class="ma-2 mt-6">
        <p class="text-body-1" style="font-weight: bold">Purchase Details</p>
      </v-row>

      <Product
        v-for="product in products"
        :key="product.id"
        :productx="product" 
        :showActionBtn = false
      />

      <v-row justify="start" class="ma-2 mt-6">
        <p class="text-body-1" style="font-weight: bold">Payment Summery</p>
      </v-row>
      <v-row justify="start" class="ma-2 mt-0">
        <v-col cols="2">
          <p>Subtotal</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <p>RM450</p>
        </v-col>
      </v-row>

      <v-row justify="start" class="ma-2 mt-n7">
        <v-col cols="2">
          <p>Payment</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <p>Visa Card</p>
        </v-col>
      </v-row>

      <div class="text-end">
        <v-btn
          class="text-none"
          color="success"
          rounded
          variant="flat"
          width="90"
          :to="{ name: 'HomeView' }"
        >
          Done
        </v-btn>
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
import Product from "@/components/Product.vue";

export default {
  components: {
    Product,
  },

  data() {
    return {
      bookingTitle: [
        { title: "Shop", icon: "mdi-store" },
        { title: "Time", icon: "mdi-store-clock" },
        { title: "Service", icon: "mdi-content-cut" },
      ],
      products: [
        {
          id: 1,
          img: "https://picsum.photos/500/300?image=2",
          name: "Monday or Tuesday Night Sunset Skyline Cruise for One - Chicago Cruise Events",
          price: 16,
          quantity: 2,
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>