<template :style="myStyle">
  <div class="bg"></div>

  <BusinessNaviBar />

  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-sheet
      elevation="0"
      color="white"
      max-width="1200"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto mt-4"
    >
      <p class="text-h4 mb-1 font-weight-bold" style="color: #0a5393">
        {{ $t("busienssPage.salesTitle") }}
      </p>

      <p class="text-h6">
        {{ $t("busienssPage.salesDesc") }}
      </p>

      <v-btn
        width="80%"
        elevation="4"
        class="ma-6 gradient"
        height="60px"
        :ripple="false"
        rounded="pill"
        @click="joinOnClick"
      >
        <p class="text-h6 font-weight-bold text-white">
          {{ $t("busienssPage.joinFree") }}
        </p></v-btn
      >
    </v-sheet>
  </v-sheet>

  <div align="center">
    <v-img max-width="1200" :src="system_logo" alt="" />
  </div>

  <v-sheet max-width="1200" class="pa-4 text-center mx-auto mt-12">
    <p class="text-h6 font-weight-bold">
      {{ $t("busienssPage.letUsHelp") }}
      <span
        class="text-h5"
        style="
          background: linear-gradient(transparent 80%, #ffff00 60%);
          font-weight: bold;
        "
      >
        {{ $t("busienssPage.connect") }}
      </span>
      {{ $t("busienssPage.withYourCustomer") }}
    </p>
    <p class="text-body-1 text-center mt-3">
      {{ $t("busienssPage.connectDesc") }}
    </p>

    <!-- Document -->
    <div align="center">
      <v-sheet
        width="100%"
        class="mt-4"
        elevation="4"
        :rounded="xl"
        color="white"
      >
        <v-row no-gutters>
          <v-col cols="6" md="6" sm="12">
            <v-img :src="document_img" max-width="70%" class="ma-6" alt="" />
          </v-col>
          <v-col cols="6" md="6" sm="12">
            <p class="text-h6 text-center font-weight-bold ma-6">
              {{ $t("busienssPage.document") }}
            </p>
            <v-btn
              height="60px"
              width="80%"
              :ripple="false"
              rounded="pill"
              elevation="1"
              class="ma-6 gradient2"
              :to="{
                name: 'ContactUs',
                params: { type: '1' },
              }"
            >
              <p class="text-body font-weight-bold text-white">
                {{ $t("busienssPage.download") }}
              </p></v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </div>
  </v-sheet>

  <!-- WhatIsBeauty360 -->
  <v-sheet
    elevation="0"
    color="indigo-lighten-5"
    width="100%"
    class="pa-6 text-center mx-auto mt-4"
  >
    <div align="center">
      <v-sheet max-width="1200" rounded="xl" class="pa-4">
        <v-row no-gutters>
          <!-- <v-col cols="5" md="5" sm="12">
            <v-img :src="allinone_img" width="70%" class="ma-6" alt="" />
          </v-col> -->

          <v-col cols="12" md="12" sm="12" class="mt-0">
            <p class="text-h4 mb-1 font-weight mt-12" style="color: black">
              <span class="text-h6"> {{ $t("busienssPage.what") }} </span>Beauty
              360
            </p>

            <p class="text-h6 mt-8">
              {{ $t("busienssPage.concept1") }}
              <span
                class="text-h5"
                style="
                  background: linear-gradient(transparent 80%, #ffff00 60%);
                  font-weight: bold;
                "
              >
                {{ $t("busienssPage.shooManageFunc") }}
              </span>
              <br />
              {{ $t("busienssPage.itAlso") }}
              <span
                class="text-h5"
                style="
                  background: linear-gradient(transparent 80%, #ffff00 60%);
                  font-weight: bold;
                "
              >
                {{ $t("busienssPage.customer") }}
              </span>
            </p>

            <p class="mt-4 text-body-1">
              {{ $t("busienssPage.thisMakePossible") }}
            </p>

            <v-divider class="mt-8" length="280" thickness="1"></v-divider>
          </v-col>
        </v-row>


        <v-container class="mt-0">
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <v-sheet class="ma-4 pa-2 text-center" rounded="xl">
                <p class="text-h4 font-weight-bold mt-4">
                  {{ $t("busienssPage.its")
                  }}<span
                    class="text-h4 font-weight-bold"
                    style="
                      color: #f44336;
                      background: linear-gradient(transparent 60%, #ff9 60%);
                      font-weight: bold;
                    "
                  >
                    {{ $t("busienssPage.free") }}
                  </span>
                </p>

                <div align="center">
                  <v-img max-width="40%" :src="free_img" class="ma-6" alt="" />
                </div>
                <p class="text-body-1 pa-2">
                  {{ $t("busienssPage.free1") }}
                </p>
              </v-sheet>
            </v-col>

            <v-col cols="12" sm="4">
              <v-sheet class="ma-4 pa-2 text-center" rounded="xl">
                <p class="text-h4 font-weight-bold mt-4">
                  <span
                    class="text-h4 font-weight-bold"
                    style="
                      color: #f44336;
                      background: linear-gradient(transparent 60%, #ff9 60%);
                      font-weight: bold;
                    "
                  >
                    {{ $t("busienssPage.easy") }} </span
                  >{{ $t("busienssPage.touse") }}
                </p>
                <div align="center">
                  <v-img
                    max-width="40%"
                    :src="monitor_img"
                    class="ma-6"
                    alt=""
                  />
                </div>
                <p class="text-body-1 pa-2">
                  {{ $t("busienssPage.easyDesc") }}
                </p>
              </v-sheet>
            </v-col>

            <v-col cols="12" sm="4">
              <v-sheet class="ma-4 pa-2 text-center" rounded="xl">
                <p class="text-h4 font-weight-bold mt-4">
                  <span
                    class="text-h4 font-weight-bold"
                    style="
                      color: #f44336;
                      background: linear-gradient(transparent 60%, #ff9 60%);
                      font-weight: bold;
                    "
                  >
                    {{ $t("busienssPage.update") }}</span
                  >{{ $t("busienssPage.alltime") }}
                </p>
                <div align="center">
                  <v-img
                    :src="update_img"
                    max-width="40%"
                    class="ma-6"
                    alt=""
                  />
                </div>
                <p class="text-body-1 pa-2">
                  {{ $t("busienssPage.updateDesc") }}
                </p>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </div>
  </v-sheet>

  <!-- Howto -->
  <v-sheet class="pa-4 text-center mx-auto mt-4">
    <p class="text-h4 mb-1 font-weight-bold ma-2 pa-2" style="color: #2196f3">
      {{ $t("busienssPage.howStart") }}
    </p>

    <p class="mt-1 text-body-1 ma-2">
      {{ $t("busienssPage.howStartDesc") }}
    </p>

    <v-sheet
      elevation="0"
      color="white"
      max-width="1200"
      class="text-center mx-auto"
    >
      <v-container class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-sheet
              class="ma-4 pa-2 text-center"
              rounded="xl"
              color="blue-lighten-5"
            >
              <div align="center">
                <v-card
                  color="blue"
                  width="4em"
                  height="4em"
                  elevation="0"
                  class="mt-n6"
                  ><p class="text-center mt-2 text-h3 font-weight-bold">
                    1
                  </p></v-card
                >
                <p class="text-h6 pa-2 font-weight-bold">
                  {{ $t("busienssPage.step1Title") }}
                </p>
                <v-img max-width="40%" :src="memo_img" alt="" />
              </div>
              <p class="text-body-1 pa-2">
                {{ $t("busienssPage.step1") }}
              </p>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="4">
            <v-sheet
              class="ma-4 pa-2 text-center"
              rounded="xl"
              color="blue-lighten-5"
            >
              <div align="center">
                <v-card
                  color="blue"
                  width="4em"
                  height="4em"
                  elevation="0"
                  class="mt-n6"
                  ><p class="text-center mt-2 text-h3 font-weight-bold">
                    2
                  </p></v-card
                >
                <p class="text-h6 pa-2 font-weight-bold">
                  {{ $t("busienssPage.step2Title") }}
                </p>
                <v-img max-width="40%" :src="store_img" alt="" />
              </div>
              <p class="text-body-1 pa-2">
                {{ $t("busienssPage.step2") }}
              </p>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="4">
            <v-sheet
              class="ma-4 pa-2 text-center"
              rounded="xl"
              color="blue-lighten-5"
            >
              <div align="center">
                <v-card
                  color="blue"
                  width="4em"
                  height="4em"
                  elevation="0"
                  class="mt-n6"
                  ><p class="text-center mt-2 text-h3 font-weight-bold">
                    3
                  </p></v-card
                >
                <p class="text-h6 pa-2 font-weight-bold">
                  {{ $t("busienssPage.step3Title") }}
                </p>
                <v-img :src="like_img" max-width="40%" alt="" />
              </div>
              <p class="text-body-1 pa-2">
                {{ $t("busienssPage.step3") }}
              </p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-btn
          width="80%"
          elevation="4"
          class="ma-6 gradient"
          height="60px"
          :ripple="false"
          rounded="pill"
          @click="joinOnClick"
        >
          <p class="text-h6 font-weight-bold text-white">
            {{ $t("busienssPage.joinFree") }}
          </p></v-btn
        >
      </v-container>
    </v-sheet>
  </v-sheet>

  <!-- Features -->
  <v-sheet
    class="pa-0 ma-0 text-center mt-6 mx-auto"
    color="light-green-lighten-5"
    width="100%"
    elevation="0"
    ><br />
    <p class="text-h4 font-weight-bold ma-2 pa-2 mt-0" style="color: #1b5e20">
      {{ $t("busienssPage.ourFeature") }}
    </p>
    <p class="mt-1 text-body-1 ma-2">
      {{ $t("busienssPage.ourFeatureDesc") }}
    </p>

    <div align="left">
      <v-sheet
        elevation="0"
        color="light-green-lighten-5"
        max-width="1200"
        class="mx-auto"
      >
        <v-container class="mt-4">
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <v-card rounded="xl" class="ma-4">
                <v-img
                  alt=""
                  :src="reservation_img"
                  class="align-end"
                  height="200px"
                  cover
                >
                </v-img>

                <v-card-title>
                  {{ $t("busienssPage.onlineBooking") }}
                </v-card-title>

                <v-card-text>
                  {{ $t("busienssPage.onlineBookingDesc") }}
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="4">
              <v-card rounded="xl" class="ma-4">
                <v-img
                  :src="voucher_img"
                  class="align-end"
                  height="200px"
                  cover
                  alt=""
                >
                </v-img>

                <v-card-title>
                  {{ $t("busienssPage.voucher") }}
                </v-card-title>

                <v-card-text>
                  {{ $t("busienssPage.voucherDesc") }}
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="4">
              <v-card rounded="xl" class="ma-4">
                <v-img
                  :src="handphone_small"
                  class="align-end"
                  height="200px"
                  cover
                  alt=""
                >
                </v-img>

                <v-card-title>
                  {{ $t("busienssPage.membership") }}
                </v-card-title>

                <v-card-text>
                  {{ $t("busienssPage.membershipDesc") }}
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="4">
              <v-card rounded="xl" class="ma-4">
                <v-img
                  :src="notification_img"
                  class="align-end"
                  height="200px"
                  cover
                  alt=""
                >
                </v-img>

                <v-card-title>
                  {{ $t("busienssPage.promotions") }}
                </v-card-title>

                <v-card-text>
                  {{ $t("busienssPage.promotionsDesc") }}
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="4">
              <v-card rounded="xl" class="ma-4">
                <v-img
                  :src="payment_img"
                  class="align-end"
                  height="200px"
                  cover
                  alt=""
                >
                </v-img>

                <v-card-title>
                  {{ $t("busienssPage.subscription") }}
                </v-card-title>

                <v-card-text>
                  {{ $t("busienssPage.subscriptionDesc") }}
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="4">
              <v-card rounded="xl" class="ma-4">
                <v-img
                  :src="report_img"
                  class="align-end"
                  height="200px"
                  cover
                  alt=""
                >
                </v-img>

                <v-card-title>
                  {{ $t("busienssPage.reporting") }}
                </v-card-title>

                <v-card-text>
                  {{ $t("busienssPage.reportingDesc") }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div align="center">
            <v-btn
              width="80%"
              elevation="4"
              class="ma-6 gradient"
              height="60px"
              :ripple="false"
              rounded="pill"
              @click="joinOnClick"
            >
              <p class="text-h6 font-weight-bold text-white">
                {{ $t("busienssPage.joinFree") }}
              </p></v-btn
            >
          </div>
        </v-container>
      </v-sheet>
    </div>
  </v-sheet>

  <!-- weSupport -->
  <v-sheet
    elevation="0"
    color="white"
    width="100%"
    class="ma-0 text-center mx-auto mt-6"
  >
    <br />
    <p class="text-h4 font-weight mt-0" style="color: black">
      <span class="text-h6"> {{ $t("busienssPage.weSupport") }} </span
      ><span
        style="
          color: black;
          background: linear-gradient(transparent 60%, #ff9 60%);
          font-weight: bold;
        "
        >{{ $t("busienssPage.varoiusBuss") }}</span
      >
    </p>

    <v-sheet
      elevation="0"
      color="white"
      max-width="1200"
      class="text-center mx-auto"
    >
      <v-container>
        <v-row no-gutters>
          <v-col
            v-for="item in businessType"
            :key="item.title"
            cols="4"
            lg="2"
            sm="4"
          >
            <v-sheet
              class="ma-2 pa-4 text-center"
              width="100"
              height="100"
              rounded="pill"
            >
              <div align="center">
                <v-img width="50" height="50" :src="item.img" alt="" />
              </div>
              <p class="mt-0 text-body-2 font-weight-medium">
                {{ $t(item.title) }}
              </p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <br />
  </v-sheet>

  <!-- PriceList -->
  <v-sheet elevation="0" width="100%" color="">
    <v-container>
      <div align="center" class="mt-0">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-simple-table>
              <thead>
                <tr>
                  <th>
                    <p class="text-h5 font-weight-bold text-black pa-0">
                      <br /><span class="text-body-1">. </span>
                    </p>
                  </th>
                  <th>
                    <p class="text-h5 font-weight-bold text-pink pa-0">
                      {{ $t("busienssPage.free") }}<br />
                    </p>
                  </th>
                  <th>
                    <p class="text-h5 font-weight-bold text-green pa-0">
                      {{ $t("busienssPage.basic") }}<br />
                    </p>
                  </th>
                  <th>
                    <p class="text-h5 font-weight-bold text-blue pa-0">
                      {{ $t("busienssPage.pro") }}<br />
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="feature in features" :key="feature.name">
                  <td class="tdTitle">
                    <p
                      class="text-body-2 font-weight-bold"
                      style="color: black"
                    >
                      {{ $t(feature.name) }}
                    </p>
                  </td>
                  <td class="tdDesc">
                    <div align="center">
                      <v-icon
                        v-if="freeFeatures.includes(feature.name)"
                        color="pink"
                        size="large"
                        >mdi-check</v-icon
                      >
                    </div>
                  </td>
                  <td class="tdDesc">
                    <div align="center">
                      <v-icon
                        v-if="basicFeatures.includes(feature.name)"
                        color="green"
                        size="large"
                        >mdi-check</v-icon
                      >
                    </div>
                  </td>
                  <td class="tdDesc">
                    <div align="center">
                      <v-icon
                        v-if="advancedFeatures.includes(feature.name)"
                        color="blue"
                        size="large"
                        >mdi-check</v-icon
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-sheet>

  <!-- Banner -->
  <v-sheet elevation="0" width="100%" color="grey-lighten-4">
    <div align="center">
      <v-img
        class="mt-3 pa-12"
        :src="checkBannerLanguage"
        @click="bannerClick"
        max-width="960"
        alt=""
      ></v-img>
    </div>
  </v-sheet>

  <v-sheet elevation="0" width="100%">
    <v-sheet
      elevation="0"
      max-width="1200"
      class="text-center mx-auto mb-8 mt-6"
    >
      <p class="text-h4 mb-1 font-weight-bold ma-2 pa-2" style="color: #2196f3">
        FAQ
      </p>
      <v-expansion-panels class="mt-6">
        <v-expansion-panel
          class="pa-2"
          elevation="0"
          v-for="item in faqList"
          :key="item.title"
        >
          <v-expansion-panel-title
            class="text-text-subtitle-1 font-weight-bold text-blue"
          >
            Q
            <span class="mx-2 text-black"> {{ $t(item.title) }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="text-h6 font-weight-medium pa-2">
            {{ $t(item.desc) }}
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn
        width="80%"
        elevation="4"
        class="ma-6 gradient"
        height="60px"
        :ripple="false"
        rounded="pill"
        @click="joinOnClick"
      >
        <p class="text-h6 font-weight-bold text-white">
          {{ $t("busienssPage.joinFree") }}
        </p></v-btn
      >
    </v-sheet>
  </v-sheet>

  <v-sheet elevation="0" color="grey-lighten-4" width="100%" class="pa-0">
    <div align="center">
      <br />
      <p class="text-h5 mb-1 font-weight mt-0" style="color: black">
        {{ $t("busienssPage.weAre") }}
      </p>
    </div>

    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="12">
          <v-sheet class="ma-0 pa-2" rounded="lg" color="transparent">
            <v-img
              class="ma-0 pa-0 mt-0"
              :src="office_img"
              max-height="320"
              cover
              aspect-ratio="16/9"
              alt=""
            />
            <p class="text-h6 mt-2">Hello!</p>
            <p class="text-body-2 mt-0">
              {{ $t("busienssPage.weAreDesc") }}
            </p>

            <div align="center">
              <v-row justify="center" no-gutters class="mt-6">
                <v-col cols="12" md="6">
                  <v-btn
                    rounded="pill"
                    elevation="0"
                    width="70%"
                    class="white--text ma-2"
                    height="40px"
                    variant="outlined"
                    prepend-icon="mdi-email"
                    :ripple="false"
                    :to="{
                      name: 'ContactUs',
                      params: { type: '0' },
                    }"
                  >
                    {{ $t("shop.contact_us") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    rounded="pill"
                    elevation="0"
                    width="70%"
                    class="white--text ma-2"
                    height="40px"
                    variant="outlined"
                    prepend-icon="mdi-whatsapp"
                    :ripple="false"
                    href="https://wa.me/message/JYYGE5SKNUKKK1"
                    target="_blank"
                  >
                    WhatsApp
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>

  <v-bottom-navigation :active="showBottomNav" bg-color="blue" elevation="1">
    <v-btn
      color="green"
      elevation="0"
      width="70%"
      class="white--text ma-2"
      height="40px"
      prepend-icon="mdi-pencil-outline"
      :ripple="false"
      @click="joinOnClick"
    >
      {{ $t("busienssPage.step1Title") }}
    </v-btn>

    <v-btn
      color="green"
      elevation="0"
      width="70%"
      class="white--text ma-2"
      height="40px"
      prepend-icon="mdi-whatsapp"
      :ripple="false"
      href="https://wa.me/message/JYYGE5SKNUKKK1"
      target="_blank"
    >
      WhatsApp Us!
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import BusinessNaviBar from "@/components/BusinessNaviBar.vue";

export default {
  components: {
    BusinessNaviBar,
  },

  computed: {
    checkBannerLanguage() {
      switch (this.$i18n.locale) {
        case "zhCN":
          return this.banner_img_zhcn;

        default:
          return this.banner_img;
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  data() {
    return {
      logo_src: require("@/assets/newlogo.png"),
      learning_img: require("@/assets/img/online-learning-concept.png"),
      work_img: require("@/assets/img/girl-work-on-laptop.png"),
      developer_img: require("@/assets/img/developer-team.png"),
      system_logo: require("@/assets/img/pc_iphone.png"),
      banner_img: require("@/assets/img/campaign.jpg"),
      banner_img_zhcn: require("@/assets/img/campaign_zhcn.jpg"),
      hair_icon: require("@/assets/img/salon.png"),
      office_img: require("@/assets/img/itea.jpeg"),
      memo_img: require("@/assets/img/memo.png"),
      store_img: require("@/assets/img/store.png"),
      like_img: require("@/assets/img/like.png"),
      free_img: require("@/assets/img/free.png"),
      monitor_img: require("@/assets/img/monitor.png"),
      update_img: require("@/assets/img/update.png"),
      document_img: require("@/assets/img/document.jpg"),
      allinone_img: require("@/assets/img/allinone.png"),
      reservation_img: require("@/assets/img/reservation_small.jpg"),
      notification_img: require("@/assets/img/notification_small.jpg"),
      report_img: require("@/assets/img/report_small.jpg"),
      payment_img: require("@/assets/img/payment_small.jpg"),
      voucher_img: require("@/assets/img/voucher_small.jpg"),
      handphone_small: require("@/assets/img/handphone_small.jpg"),
      showBottomNav: false,
      lastScrollPosition: 0,

      myStyle: {
        backgroundColor: "#16a085",
      },
      businessType: [
        {
          title: "utls.hair_salon",
          img: require("@/assets/img/salon.png"),
        },
        {
          title: "utls.beauty_salon",
          img: require("@/assets/img/beauty.png"),
        },
        {
          title: "utls.nail_salon",
          img: require("@/assets/img/nail.png"),
        },
        {
          title: "utls.baber_salon",
          img: require("@/assets/img/baber.png"),
        },
        {
          title: "utls.massage_spa",
          img: require("@/assets/img/massage.png"),
        },
        {
          title: "utls.gym_fit",
          img: require("@/assets/img/treadmill.png"),
        },
        {
          title: "utls.personal",
          img: require("@/assets/img/coach.png"),
        },
        {
          title: "utls.therapy",
          img: require("@/assets/img/therapy.png"),
        },
        {
          title: "utls.tatoo",
          img: require("@/assets/img/tatoo.png"),
        },
        {
          title: "utls.tanning",
          img: require("@/assets/img/chair.png"),
        },
      ],
      faqList: [
        {
          title: "busienssPage.howSoon",
          desc: "busienssPage.howSoonAns",
        },
        {
          title: "busienssPage.useAllFunc",
          desc: "busienssPage.useAllFuncAns",
        },
        {
          title: "busienssPage.demoOK",
          desc: "busienssPage.demoOKAns",
        },
        {
          title: "busienssPage.noInternet",
          desc: "busienssPage.noInternetAns",
        },
        {
          title: "busienssPage.alwaysFree",
          desc: "busienssPage.alwaysFreeAns",
        },
        {
          title: "busienssPage.no360",
          desc: "busienssPage.no360Ans",
        },
        {
          title: "busienssPage.whatDiff",
          desc: "busienssPage.whatDiffAns",
        },
        {
          title: "busienssPage.posOK",
          desc: "busienssPage.posOKAns",
        },
      ],
      features: [
        { name: "busienssPage.on_booking" },
        { name: "busienssPage.membership" },
        { name: "busienssPage.voucher" },
        { name: "busienssPage.promotions" },
        { name: "busienssPage.shopManagement" },
        { name: "busienssPage.realTime" },
        { name: "busienssPage.monthlySub" },
        { name: "busienssPage.ticket" },
        { name: "busienssPage.data" },
        { name: "busienssPage.ec" },
      ],

      freeFeatures: [
        "busienssPage.on_booking",
        "busienssPage.membership",
        "busienssPage.voucher",
        "busienssPage.promotions",
        "busienssPage.shopManagement",
      ],
      basicFeatures: [
        "busienssPage.on_booking",
        "busienssPage.membership",
        "busienssPage.voucher",
        "busienssPage.promotions",
        "busienssPage.shopManagement",
        "busienssPage.realTime",
        "busienssPage.monthlySub",
        "busienssPage.ticket",
      ],
      advancedFeatures: [
        "busienssPage.on_booking",
        "busienssPage.membership",
        "busienssPage.voucher",
        "busienssPage.promotions",
        "busienssPage.shopManagement",
        "busienssPage.realTime",
        "busienssPage.monthlySub",
        "busienssPage.ticket",
        "busienssPage.data",
        "busienssPage.ec",
      ],
    };
  },

  methods: {
    handleScroll() {
      const currentScroll =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScroll < 50) {
        this.showBottomNav = false;
      } else {
        // Scrolling up
        this.showBottomNav = true;
      }

      this.lastScrollPosition = currentScroll;
    },

    joinOnClick() {
      this.$router.push({
        name: "ShopRegister",
      });
    },

    bannerClick() {
      this.openCampaignPage();
    },

    openCampaignPage() {
      this.$router.push({
        name: "ForShopLanguageSelect",
      });
    },
  },
};
</script>

<style scoped>
.scrollContainer {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
}
.scrollContent {
  display: flex;
  flex-wrap: nowrap;
}
.bg {
  width: 100%;
  height: 20%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  background: url("@/assets/img/wave.png") no-repeat center;
  background-size: cover;
  background-color: white;
  transform: scale(1);
}

.text-center {
  text-align: center;
}

.tdTitle {
  width: 0%;
  text-align: right;
  padding: 0em;
  background-color: white;
}

.tdDesc {
  width: 0%;
  padding: 1.8em;
}

.gradient {
  border: none;
  outline: none;
  position: relative;
  background: linear-gradient(to right, #d81b60, #f57f17);
  cursor: pointer;
}

.gradient2 {
  border: none;
  outline: none;
  position: relative;
  background: linear-gradient(to right, #1de9b6, #8bc34a);
  cursor: pointer;
}
</style>