import { USER_RESERVATION_API, USER_RESERVATION_API_V2 } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';
import uitls from "@/uitls/Uitls";

const state = () => ({
  reservations: [],
  currentPage: 0,
  lastPage: 0,
  calendarReservationList: [],
})

const getters = {
  reservations: (state) => state.reservations,
  currentPage: (state) => state.currentPage,
  lastPage: (state) => state.lastPage,
}

const mutations = {
  SET_RESERVATIONS(state, reservations) {
    state.reservations.push(...reservations)
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage
  },
  SET_LAST_PAGE(state, lastPage) {
    state.lastPage = lastPage
  },
  CLEAR_RESERVATIONS(state) {
    state.reservations = []
  },
  CLEAR_CURRENT_PAGE(state) {
    state.currentPage = 0
  },
  CLEAR_LAST_PAGE(state) {
    state.lastPage = 0
  },
  SET_CALENDAR_RESERVATION_LIST(state, data) {
    state.calendarReservationList = data;
  },
}

const actions = {
  async listUserReservation({ dispatch, commit }, params = {}) {
    const response = await dispatch(`requestUserReservations`, params)
    commit('SET_RESERVATIONS', response.reservations)
    commit('SET_CURRENT_PAGE', response.current_page)
    commit('SET_LAST_PAGE', response.last_page)
    return response
  },
  async requestUserReservations({ commit }, params = {}) {
    const response = await axiosClient.get(`${USER_RESERVATION_API}`, {
      params,
    })
    const data = response.data
    return {
      reservations: data.data,
      current_page: data.current_page,
      last_page: data.last_page,
    }
  },
  async fetchReservationDetail({ commit }, params = {}) {
    const userResevationId = params.user_reservation_id
    const response = await axiosClient.get(
      `${USER_RESERVATION_API}/${userResevationId}`,
      { params }
    )
    return response.data
  },
  async storeReservation({ commit }, params = {}) {
    const response = await axiosClient.post(`${USER_RESERVATION_API}`, params)
    return response.data
  },
  async storeReservationV2({ commit }, params = {}) {
    const response = await axiosClient.post(
      `${USER_RESERVATION_API_V2}`,
      params
    )
    return response.data
  },
  async cancelReserve({ commit }, params = {}) {
    const userResevationId = Number(params.user_reservation_id)
    const response = await axiosClient.get(
      `${USER_RESERVATION_API_V2}/cancel/${userResevationId}`,
      params
    )
    return response
  },
  async storeReservationItem({ commit }, params = {}) {
    const response = await axiosClient.post(
      `${USER_RESERVATION_API}/item`,
      params
    )
    return response.data
  },
  async fetchReservationUpdate({ commit }, params = {}) {
    const userResevationId = Number(params.user_reservation_id)
    const response = await axiosClient.put(
      `${USER_RESERVATION_API}/${userResevationId}`,
      params
    )
    return response
  },
  async getReservationList({ commit }, params = {}) {
    const response = await axiosClient.get(
      `${USER_RESERVATION_API}/search/_admin`,
      {
        params,
      }
    )
    return response.data.data
  },
  // カレンダー用の全予約一覧取得
  async fetchReservationListForCalender({ commit }, params = {}) {
    const response = await axiosClient.get(`${USER_RESERVATION_API}/schedules`, { params })
    return response.data
  },
  clearReservationData({ commit }) {
    commit('CLEAR_RESERVATIONS')
    commit('CLEAR_CURRENT_PAGE')
    commit('CLEAR_LAST_PAGE')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}