<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn
      class="ma-0"
      icon="mdi-arrow-left"
      @click="$router.push('/mypage')"
    ></v-btn>
    <v-app-bar-title>{{ $t("settingpage.settings") }}</v-app-bar-title>
  </v-app-bar>

  <v-main>
    <v-container fluid>
      <v-sheet
        elevation="0"
        max-width="1200"
        rounded="lg"
        width="100%"
        class="pa-0 mx-auto mt-6"
      >
        <p class="text-h6 mx-2" style="font-weight: bold">
          <v-icon
            icon="mdi-login"
            color="black"
            size="20px"
            class="mb-1"
          ></v-icon>
          {{ $t("settingpage.login") }}
        </p>

        <v-list lines="one" class="pa-6">
          <v-list-item
            v-for="item in loginList"
            :key="item"
            :title="$t(item)"
            :value="$t(item)"
            active-color="primary"
            rounded="xl"
            variant="plain"
            @click="listSection1OnClick(item)"
          >
            <template v-slot:append>
              <v-icon color="grey-lighten-1" icon="mdi-chevron-right"></v-icon>
            </template>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </v-list>

        <p class="text-h6 mx-2" style="font-weight: bold">
          <v-icon
            icon="mdi-account-circle"
            color="black"
            size="20px"
            class="mb-1"
          ></v-icon>
          {{ $t("settingpage.user_information") }}
        </p>

        <v-list lines="one" class="pa-6">
          <v-list-item
            v-for="item in userInfoList"
            :key="item"
            :title="$t(item)"
            :value="$t(item)"
            active-color="primary"
            rounded="xl"
            variant="plain"
            @click="listSection2OnClick(item)"
          >
            <template v-slot:append>
              <v-icon color="grey-lighten-1" icon="mdi-chevron-right"></v-icon>
            </template>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </v-list>

        <!-- <p class="text-h6 mx-2" style="font-weight: bold">
        <v-icon icon="mdi-more" color="black" size="20px" class="mb-1"></v-icon>
        More
      </p>

      <v-list lines="one" class="pa-6">
        <v-list-item
          v-for="item in moreList"
          :key="item"
          :title="item"
          :value="item"
          active-color="primary"
          rounded="xl"
          variant="plain"
        >
          <template v-slot:append>
            <v-icon color="grey-lighten-1" icon="mdi-chevron-right"></v-icon>
          </template>
        </v-list-item>
      </v-list> -->
      </v-sheet>
    </v-container>
  </v-main>

  <VSonner position="top-center" />
</template>

<script>
import NaviBar from "@/components/NaviBar.vue";
import { VSonner, toast } from "vuetify-sonner";

export default {
  components: {
    NaviBar,
    VSonner,
  },

  data() {
    return {
      loginList: ["settingpage.new_email", "settingpage.new_password"],
      userInfoList: [
        "settingpage.profile",
        "settingpage.contact",
        "settingpage.interest",
        "settingpage.news",
      ],
      moreList: ["Interest", "Cupon", "Share with your friend"],
    };
  },

  methods: {
    listSection1OnClick(action) {
      if (action === "settingpage.new_email") {
        this.$router.push({ name: "EmailSetting" });
      } else if (action === "settingpage.new_password") {
        this.$router.push({ name: "PasswordSetting" });
      }
    },

    listSection2OnClick(action) {
      if (action === "settingpage.profile") {
        this.$router.push({ name: "ProfileSetting" });
      } else if (action === "settingpage.contact") {
        this.$router.push({ name: "ContactSetting" });
      } else if (action === "settingpage.interest") {
        this.$router.push({
          name: "InterestSetting",
          params: { isNewUser: false },
        });
      } else if (action === "Trouble") {
        this.$router.push({
          name: "TroubleSetting",
          params: { isNewUser: false },
        });
      } else if (action === "settingpage.news") {
        this.$router.push({ name: "NewsLetterSetting" });
      }
    },
  },
};
</script>

<style>
</style>