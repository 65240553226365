<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-container>
      <p class="text-h5 mx-2" style="font-weight: bold">
        <v-icon
          icon="mdi-newspaper"
          color="light-blue"
          size="24px"
          class="mb-1"
        ></v-icon>
        {{ $t("searchresults.search_results") }}
        <span class="text-h5 text-black" style="font-weight: bold">
          {{ articleList.length }}
        </span>
      </p>

      <v-row>
        <v-col cols="9" class="mt-4">
          <v-text-field
            variant="outlined"
            v-model="searchKeyword"
            single-line
            hide-details
            clearable
            @keydown.enter.prevent="startSearchOnClick"
          >
            <v-field-label style="font-weight: normal">{{
              keyword
            }}</v-field-label>
          </v-text-field>
        </v-col>

        <v-col cols="3">
          <v-btn
            icon="mdi-magnify"
            elevation="0"
            color="blue"
            class="mt-5"
            :ripple="false"
            @click="startSearchOnClick"
          >
          </v-btn>
        </v-col>

        <v-col
          v-for="item in articleList"
          :key="item.name"
          cols="12"
          md="6"
          xs="12"
          sm="12"
        >
          <ArticleTableCellCard
            :articleObjects="item"
            @articleIDCallBack="articleIDCallBack"
          ></ArticleTableCellCard>
        </v-col>
      </v-row>
    </v-container>

    <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" />
  </v-sheet>
</template>

<script>
import ArticleTableCellCard from "@/components/ArticleTableCellCard.vue";
import Uitls from "@/uitls/Uitls";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  props: ["keyword"],

  components: {
    ArticleTableCellCard,
    Breadcrumbs,
  },

  data() {
    return {
      local_logo: require("@/assets/img/logo120.png"),
      articleList: [],
      searchKeyword: "",
      loading: false,
    };
  },

  async mounted() {
    this.searchArticle(this.keyword);
  },

  computed: {
    watch: {},

    breadCrumbsProps: function () {
      return {
        data: [
          { name: "mypage.topPage", to: "/" },
          { name: "homeview.new_topics", to: "/topic" },
          { name: "searchresults.search_results", disable: true },
          { name: this.keyword },
        ],
      };
    },
  },

  methods: {
    async searchArticle(value) {
      this.articleList = await this.$store.dispatch(
        "ArticleStore/getWordPressSearchArticle",
        {
          search: value,
          page: 1,
          per_page: 100,
          _embed: true,
        }
      );
    },

    startSearchOnClick() {
      this.resetRouterPrompt(this.searchKeyword);
      this.searchArticle(this.searchKeyword);
    },

    articleIDCallBack(articleID) {
      this.$router.push({
        name: "ArticleViewDetail",
        params: { id: articleID },
      });
    },

    resetRouterPrompt(value) {
      this.$router.replace({ path: value });
    },
  },
};
</script>

<style scoped></style>