import { SEARCH_SHOP_API, SHOP_API, LOCATION_SEARCH_SHOP_API, SHOP_BUSINESSHOURS_API, SHOP_PICTURES_API, PREFECTURE_API_V2 } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';
import Utils from "@/uitls/Uitls";

const state = () => ({
  shopDetail: null,
  shopTotalCount: null,
})

const getters = {
  shopDetail: (state) => state.shopDetail,
  shopTotalCount: (state) => state.shopTotalCount,
}

const mutations = {
  SET_DATA: (state, data) => {
    state.shopDetail = data;
  },
  SET_DATA_SHOP_COUNT: (state, data) => {
    state.shopTotalCount = data;
  },
}

const actions = {
  async searchShop(
    { commit },
    payload = {
      keyword: '',
      prefecture: 0,
      product_id: 0,
    }
  ) {
    try {
      const params = Utils.cloneDeep(payload)
      const keyword = params.keyword
      delete params.keyword
      let url = SEARCH_SHOP_API
      if (!Utils.isEmpty(keyword)) {
        url = url + `/${keyword}`
      }
      const response = await this.$axios.get(url, {
        params,
      })
      return response.data
    } catch (error) {
      return null
    }
  },

  async searchShopKeyword({ commit }, payload = {}) {
    try {
      const params = Utils.cloneDeep(payload)
      const keyword = params.keyword
      delete params.keyword
      let url = SEARCH_SHOP_API

      if (!Utils.isEmpty(keyword)) {
        url = url + `/${keyword}`
      }
      const response = await axiosClient.get(url, {
        params,
      })
      return response.data
    } catch (error) {
      return null
    }
  },

  async locationSearchShop({ commit }, params = {}) {
    try {
      const response = await axiosClient.get(`${LOCATION_SEARCH_SHOP_API}`, { params })
      return response.data
    } catch (error) {
      return null
    }
  },

  async fetchShops({ commit }, params = {}) {
    try {
      const response = await axiosClient.get(`${SHOP_API}`, { params })
      commit('SET_DATA_SHOP_COUNT', response.data.total);
      return response.data
    } catch (error) {
      return null
    }
  },

  async fetchShop({ commit }, id) {
    try {
      const response = await axiosClient.get(`${SHOP_API}/${id}`)
      // commit('SET_DATA', response.data);
      return response.data
    } catch (error) {
      console.log(error); // Failed to execute 'setItem' on 'Storage': Setting the value of 'vuex' exceeded the quota.
      return null
    }
  },
  async fetchShopBusinessHours({ commit }, id) {
    try {
      const response = await axiosClient.get(`${SHOP_BUSINESSHOURS_API}/${id}`)
      return response.data
    } catch (error) {
      return null
    }
  },


  async fetchShopPictures({ commit }, { id, category }) {

    try {
      const response = await axiosClient.get(`${SHOP_PICTURES_API}/${id}?category=${category}`)
      return response.data
    } catch (error) {
      return null
    }
  },

  async fetchRandomShopPictures({ commit }, { limit, category }) {

    try {
      const response = await axiosClient.get(`${SHOP_PICTURES_API}?limit=${limit}&category=${category}`)
      return response.data
    } catch (error) {
      return null
    }
  },

  async getPrefectureByCountry({ commit }, params) {
    const response = await axiosClient.get(`${PREFECTURE_API_V2}/` + params)
    return response
  },

  async getNearestCity({ commit }, params = {}) {
    try {
      const response = await axiosClient.post(`${SHOP_API}/nearest-city`, params)
      return response
    } catch (error) {
      return null
    }
  },

  async getAutocompleteItemList({ commit }, params = {}) {
    try {
      const response = await axiosClient.post(`${SHOP_API}/autocomplete/list`, params)
      return response
    } catch (error) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}