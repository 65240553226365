<template>
  <v-sheet class="pa-0 ma-0" color="grey-lighten-4">
    <v-container fluid>
      <v-expansion-panels>
        <v-expansion-panel elevation="0">
          <v-expansion-panel-title class="select-none">
            <div class="text-h5 mx-2" style="font-weight: normal">
              <v-icon
                icon="mdi-magnify"
                color="balck"
                size="24px"
                class="mb-1"
              ></v-icon>
              Search Wala
            </div>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <v-rol>
              <v-col class="d-flex child-flex" :cols="12" md="12">
                <p class="text-h6">Hair lenght</p>
                <v-chip-group
                  v-model="selection"
                  class="mx-4"
                  column
                  selected-class="text-deep-purple-accent-4"
                >
                  <v-chip filter v-for="n in tags" :key="n">{{ n }}</v-chip>
                </v-chip-group>
              </v-col>
            </v-rol>

            <v-rol>
              <v-col class="d-flex child-flex" :cols="12">
                <p class="text-h6">Hair color</p>
                <v-chip-group
                  v-model="selection"
                  class="mx-4"
                  column
                  selected-class="text-deep-purple-accent-4"
                >
                  <v-slide-group>
                    <v-slide-group-item v-for="n in tags" :key="n">
                      <v-chip filter>{{ n }}</v-chip>
                    </v-slide-group-item>
                  </v-slide-group>
                </v-chip-group>
              </v-col>
            </v-rol>

            <v-rol>
              <v-col class="d-flex child-flex" :cols="12">
                <p class="text-h6">testScroll</p>
                <v-spacer></v-spacer>
                <v-chip-group
                  v-model="selection"
                  column
                  selected-class="text-deep-purple-accent-4"
                  class="mx-4 v-select__selections"
                >
                  <v-chip filter class="v-chip" v-for="tag in tags" :key="tag">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-rol>

            <div
              class="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lx:grid-cols-8 gap-4 items-stretch"
            >
              <v-col>
                <v-sheet class="rounded-xl">
                  <v-container>
                    <div class="text-h6 mx-2" style="font-weight: normal">
                      Hair lenght
                    </div>
                    <v-row class="ma-0">
                      <v-radio-group v-model="radioGroup" inline>
                        <v-radio
                          label="All"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Very Short"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Short"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Medium"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Semi-Long"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Long"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>

                    <div class="text-h6 mx-2" style="font-weight: normal">
                      Hair color
                    </div>
                    <v-row class="ma-0">
                      <v-radio-group v-model="model" inline>
                        <v-radio
                          label="All"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Red"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Gold"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Blue"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Black"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Gray"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>

                    <div class="text-h6 mx-2" style="font-weight: normal">
                      Hair image
                    </div>
                    <v-row class="ma-0">
                      <v-radio-group v-model="model" inline>
                        <v-radio
                          label="All"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Very Short"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Short"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Medium"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Semi-Long"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Long"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>

                    <div class="text-h6 mx-2" style="font-weight: normal">
                      Gender
                    </div>
                    <v-row class="ma-0">
                      <v-radio-group v-model="model" inline>
                        <v-radio
                          label="All"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Female"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                        <v-radio
                          label="Male"
                          hide-details
                          :ripple="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>

                    <v-row justify="center" no-gutters>
                      <router-link
                        :to="{ name: 'SearchResult' }"
                        style="text-decoration: none; color: inherit"
                      >
                        <v-btn
                          elevation="0"
                          color="green"
                          width="90%"
                          class="mt-0 white--text"
                          height="50px"
                          :ripple="false"
                          >Search</v-btn
                        >
                      </router-link>
                    </v-row>

                    <v-col>
                      <input
                        id="search-form-address"
                        class="px-3 py-2 d-table-cell search-form-address"
                        v-model="searchText"
                        placeholder="Search Address"
                        style="display: block"
                      />
                      <v-icon
                        icon="mdi-magnify"
                        color="red"
                        size="36px"
                        class="mb-1"
                        @click="addressSearch"
                      ></v-icon>
                    </v-col>
                  </v-container>
                </v-sheet>
              </v-col>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      radioGroup: 0,
      isExpand: [],
      selection: 0,
      tags: [
        "All",
        "Work",
        "Home Improvement",
        "Vacation",
        "Food",
        "Drawers",
        "Shopping",
        "Art",
        "Tech",
        "Creative Writing",
      ],
    };
  },

  computed: {
    expandMessage() {
      return this.isExpand[0] ? "閉じる" : "開く";
      // 開いている:true、閉じている:false
    },
  },
};
</script>

<style scoped>
.v-chip {
  overflow: initial;
}

.v-select__selections {
  overflow: scroll;
  flex-wrap: nowrap;
}
</style>