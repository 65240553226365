<template>
  <v-container fluid>
    <v-row align="center" justify="center" class="mt-6">
      <v-avatar size="120">
        <v-img :src="avatarImage" cover :lazy-src="local_avatar"></v-img>
      </v-avatar>
    </v-row>

    <p class="text-center text-h6 mt-6" style="font-weight: bold">
      {{ userDisplayName }}
    </p>
    <p class="text-center text-grey" style="font-weight: normal">
      <v-icon color="grey" class="mt-n1" size="18">mdi-identifier</v-icon>
      {{ userInfo.id }}
    </p>
    <p class="text-center text-grey" style="font-weight: normal">
      <v-icon color="grey" class="mt-n1" size="18">mdi-email</v-icon>
      {{ userEmail }}
    </p>

    <v-row align="center" justify="center" class="mt-6" v-if="haveTicket">
      <v-card
        class="mt-3 ma-6"
        elevation="1"
        max-width="640"
        height="auto"
        rounded="lg"
        width="100%"
      >
        <v-img
          src="https://picsum.photos/600/600?random"
          :lazy-src="local_logo"
          class="align-end"
          gradient="to bottom, rgba(0,0,0,.4), rgba(0,0,0,.9)"
          height="200px"
          cover
        >
          <v-card-title class="text-white text-h4">Beauty Salon</v-card-title>
          <v-card-subtitle class="text-white">Kuala Lumpur</v-card-subtitle>
          <v-card-text class="text-white text-body-2">
            <span class="text-h6">Ticket left: 3</span> <br />

            <v-divider></v-divider>
            Purchase date: 2024/1/26 <br />
            Exipre before: 2024/12/5 <br />
            Last used: 2024/2/1
          </v-card-text>
        </v-img>

        <v-card-actions>
          <v-img
            class="ma-4"
            src=" https://docs.lightburnsoftware.com/img/QRCode/ExampleCode.png"
            max-width="150"
          />
          <v-spacer></v-spacer>
          <v-btn variant="outlined" class="mx-2">{{
            $t("mypage.purchaseHistory")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-sheet
      elevation="0"
      max-width="640"
      rounded="lg"
      width="100%"
      class="mx-auto mb-16"
      color="transparent"
    >
      <v-item-group
        selected-class="bg-primary"
        align="center"
        justify="center"
        class="mt-8"
      >
        <v-row>
          <v-col
            v-for="item in menuList"
            :key="item.title"
            cols="6"
            sm="3"
            md="4"
          >
            <v-btn
              rounded="lg"
              height="200"
              width="250"
              elevation="0"
              color="blue-lighten-5"
              :ripple="false"
              :to="{ name: item.link }"
            >
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-avatar color="white" size="100" class="mt-2">
                    <v-icon
                      :icon="item.icon"
                      size="60"
                      color="blue-lighten-1"
                    ></v-icon>
                  </v-avatar>

                  <v-sheet
                    color="blue-lighten-1"
                    rounded="lg"
                    class="mx-auto mt-8"
                    height="40"
                    width="150"
                  >
                    <v-row align="center" justify="center">
                      <v-col style="font-weight: bold" class="text-body-2">
                        {{ $t(item.title) }}
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
        </v-row>
      </v-item-group>
    </v-sheet>
  </v-container>
  <VSonner position="top-center" />
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { VSonner, toast } from "vuetify-sonner";
import utils from "@/uitls/Uitls";
import { useHead } from "@vueuse/head";

export default {
  components: {
    VSonner,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
      userTrouble: "troubleStore/userTrouble",
    }),
  },

  watch: {
    isAuthenticated(to, from) {
      if (this.isAuthenticated) {
        // this.parseUserInfo();
      }
    },
    user(to, from) {},
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });
  },

  data() {
    return {
      local_logo: require("@/assets/img/balck120.png"),
      userDisplayName: "",
      userEmail: "",
      local_avatar: require("@/assets/img/avatar.png"),
      avatarImage: null,
      haveTicket: false,
      troubles: [],
      menuList: [
        { title: "mypage.topPage", icon: "mdi-home-outline", link: "HomeView" },
        {
          title: "mypage.search",
          icon: "mdi-store-search-outline",
          link: "SearchResult",
        },
        {
          title: "mypage.favorite",
          icon: "mdi-heart-outline",
          link: "FavoritePage",
        },
        { title: "mypage.booking", icon: "mdi-calendar", link: "BookingPage" },
        {
          title: "mypage.voucherHistory",
          icon: "mdi-sale-outline",
          link: "VoucherHistoryPage",
        },
        // {
        //   title: "mypage.purchaseHistory",
        //   icon: "mdi-basket-outline",
        //   link: "PurchaseHistory",
        // },
        {
          title: "mypage.setting",
          icon: "mdi-cog-outline",
          link: "SettingPage",
        },
      ],
    };
  },

  beforeMount() {
    if (!this.isAuthenticated) {
      this.$store
        .dispatch("authStore/checkUserLoginState")
        .then((res) => {
          this.parseUserInfo();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },

  async mounted() {
    if (!utils.isEmpty(this.userInfo)) {
      this.userDisplayName = this.userInfo.name;
      this.userEmail = this.userInfo.email;
      if (!utils.isEmpty(this.userInfo.profile)) {
        this.avatarImage = this.userInfo.profile.picture_url_small;
      } else {
        this.avatarImage = this.local_avatar;
      }
    } else {
      if (this.isAuthenticated) {
        this.parseUserInfo();
      }
    }
  },

  methods: {
    parseUserInfo() {
      const params = {
        id_token: this.isAuthenticated,
      };

      this.$store
        .dispatch("authStore/loginWithIdToken2", params)
        .then((res) => {
          // console.log(res);
          this.userDisplayName = this.userInfo.name;
          this.userEmail = this.userInfo.email;
          if (!utils.isEmpty(this.userInfo.profile)) {
            this.avatarImage = this.userInfo.profile.picture_url_small;
          } else {
            this.avatarImage = this.local_avatar;
          }

          this.parseUserTroubles();
          this.parseUserInterests();
        })
        .catch((err) => {
          console.error(err);
          // console.log(params);
        });
    },

    async parseUserTroubles() {
      await this.$store
        .dispatch("troubleStore/getUserTroubles", this.userInfo.id)
        .then((response) => {})
        .catch((error) => {
          throw new Error(error);
        });
    },

    async parseUserInterests() {
      console.log("---parseUserInterests---");
      await this.$store
        .dispatch("interestStore/getUserInterests", this.userInfo.id)
        .then((response) => {
          console.log(utils.isEmpty(response));
          if (utils.isEmpty(response)) {
            this.$router.push({
              name: "InterestSetting",
              params: { isNewUser: true },
            });
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
  },
};
</script>

<style>
</style>