import { STAFF_API, STAFF_PICTURES_API } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';
import Utils from "@/uitls/Uitls";

const state = () => ({})

const getters = {}

const mutations = {}

const actions = {
  async fetchStaffs({ commit }, params = {}) {
    const response = await axiosClient.get(`${STAFF_API}`, { params })
    return response.data.data
  },
  async fetchStaffPictures({ commit }, params) {
    const response = await axiosClient.get(`${STAFF_PICTURES_API}`, { params })
    const pictures = response.data
    return pictures
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
