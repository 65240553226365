
<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    max-width="800"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-sheet
      elevation="1"
      max-width="600"
      rounded="lg"
      width="90%"
      class="ma-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-email-fast-outline"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">{{ $t("contactus.thank_you") }}</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        {{ $t("contactus.thank_you_desc") }}
      </p>

      <v-divider class="mb-4"></v-divider>
      <div class="text-end">
        <v-btn
          class="ma-2"
          color="success"
          rounded
          variant="flat"
          width="90"
          @click="done"
        >
          {{ $t("busienssPage.done") }}
        </v-btn>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import axios from "axios";
import utils from "@/uitls/Uitls";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });
  },

  data: function () {
    return {};
  },

  methods: {
    done() {
      this.$router.push({ name: "HomeView" });
    },
  },

  async mounted() {},
};
</script>

<style>
</style>