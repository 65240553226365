<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn
      class="ma-0"
      icon="mdi-arrow-left"
      @click="$router.push('/voucher-history')"
    ></v-btn>
    <v-app-bar-title>{{ $t("mypage.voucherHistory") }}</v-app-bar-title>
  </v-app-bar>

  <v-main>
    <v-sheet
      class="pa-0 ma-0 mx-auto"
      color="white"
      max-width="1200"
      rounded="lg"
      width="100%"
      elevation="0"
    >
      <v-container fluid v-if="eachData">
        <v-icon icon="mdi-store" color="black" size="16" class="mx-0" />
        <span style="font-size: 12px" class="mx-2 text-black">
          {{ getInfo(eachData, "shop") }}
        </span>

        <v-row class="ma-0">
          <v-col cols="12">
            <VoucherDiscountCell
              class="mt-2"
              :item="eachData.crowdfunding_item"
              :showAllItem="false"
              :showTicketCount="false"
            ></VoucherDiscountCell>
            <div align="center">
              <v-btn
                class="mt-2"
                elevation="2"
                block
                color="green"
                height="60px"
                style="font-weight: bold"
                disabled
              >
                {{ $t("crowdfunding.usedVoucher") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-sheet height="190" class="text-center">
        <v-progress-circular
          :indeterminate="loadingDialog"
          :size="100"
          color="blue"
          class="mt-12"
          >{{ $t("homeview.loading") }}
        </v-progress-circular>
      </v-sheet>
    </v-dialog>
  </v-main>
</template>

<script scoped>
import { mapGetters } from "vuex";
import Constants from "@/uitls/Constants";
import utils from "@/uitls/Uitls";
import VoucherDiscountCell from "@/components/VoucherDiscountCell.vue";

export default {
  components: {
    VoucherDiscountCell,
  },

  props: ["id"],

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
    }),
  },

  watch: {
    isAuthenticated(to, from) {
      if (this.isAuthenticated) {
      }
    },
  },

  data() {
    return {
      loadingDialog: true,
      eachData: null,
    };
  },

  beforeMount() {
    if (!this.isAuthenticated) {
      this.$store
        .dispatch("authStore/checkUserLoginState")
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  },

  async mounted() {
    if (this.userInfo) {
      this.getUserVoucherData();
    }
  },

  methods: {
    //TODO
    async getUserVoucherData() {
      await this.$store
        .dispatch("userStore/getUserVoucherData", this.id)
        .then((response) => {
          if (response) {
            this.eachData = JSON.parse(JSON.stringify(response));
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },

    getInfo(item, type) {
      let result = null;

      switch (type) {
        case "image":
          if (item.crowdfunding_item && item.crowdfunding_item.crowdfunding) {
            result = new URL(
              Constants.IMAGE_URL +
                item.crowdfunding_item.crowdfunding.picture_path
            ).href;
          }
          break;
        case "discount_rate":
          if (item.crowdfunding_item) {
            result =
              ((item.crowdfunding_item.original_price -
                item.crowdfunding_item.price) /
                item.crowdfunding_item.original_price) *
              100;
            result = parseFloat(result.toFixed(0)) + "%";
          }
          break;
        case "name":
          if (item.crowdfunding_item) {
            result = item.crowdfunding_item.name;
          }
          break;
        case "shop":
          if (
            item.crowdfunding_item &&
            item.crowdfunding_item.crowdfunding &&
            item.crowdfunding_item.crowdfunding.shop
          ) {
            result = item.crowdfunding_item.crowdfunding.shop.name;
          }
          break;
        case "prefecture":
          if (
            item.crowdfunding_item &&
            item.crowdfunding_item.crowdfunding &&
            item.crowdfunding_item.crowdfunding.shop
          ) {
            result = this.$i18n.t(
              utils.prefectureCodeToString(
                item.crowdfunding_item.crowdfunding.shop.prefecture
              )
            );
          }
          break;
        case "original_price":
          if (item.crowdfunding_item) {
            let currency = "RM";
            if (
              item.crowdfunding_item.company &&
              item.crowdfunding_item.company.currency
            ) {
              if (item.crowdfunding_item.company.currency == "myr") {
                currency = "RM";
              } else if (item.crowdfunding_item.company.currency == "jpy") {
                currency = "Yen";
              }
            }
            result = currency + " " + item.crowdfunding_item.original_price;
          }
          break;
        case "price":
          if (item.crowdfunding_item) {
            let currency = "RM";
            if (
              item.crowdfunding_item.company &&
              item.crowdfunding_item.company.currency
            ) {
              if (item.crowdfunding_item.company.currency == "myr") {
                currency = "RM";
              } else if (item.crowdfunding_item.company.currency == "jpy") {
                currency = "Yen";
              }
            }
            result = currency + " " + item.crowdfunding_item.price;
          }
          break;
      }

      return result;
    },

    cardOnClick() {
      this.$router.push({
        name: "VoucherHistoryDetailPage",
        params: { id: this.crowdfundingsObjects.id },
      });
    },
  },
};
</script>

<style scoped>
/* https://noahbres.github.io/blog/bonfire-devlog-3-how-to-make-an-events-card-list */
.event-card .desc {
  margin-bottom: 1.2em;
  font-size: 0.16em;
  padding-left: 0.1em;
}

.event-card .date-ribbon {
  position: absolute;
  top: 0px;
  left: 1em;
  background: #fe453e;
  color: #fff;
  padding: 0em 0.3em;
  padding-bottom: 0px;
  border-radius: 0px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.event-card .date-ribbon::before,
.event-card .date-ribbon::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0%;
  height: 0px;
}

.event-card .date-ribbon::before {
  left: 0;
  border-left: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-right: solid 2em transparent;
}

.event-card .date-ribbon::after {
  right: 0;
  border-right: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-left: solid 2em transparent;
}
</style>