<template>

  <v-sheet
    class="pa-0 ma-0 mx-auto"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-sheet class="mt-4 ma-0" rounded max-height="600px">
      <v-row justify="center" no-gutters>
        <v-col class="pa-2" cols="12">
          <p class="text-h5">{{ $t("busienssPage.createBzAc") }}</p>
          <p class="text-caption">
            {{ $t("busienssPage.createBzAcDesc") }}
          </p>
          <v-container fluid class="mt-0">
            <v-form v-model="formValid" @submit.prevent="onSubmit">
              <v-responsive class="mx-auto" max-width="600">
                <v-text-field
                  hide-details="auto"
                  :label="$t('loginpage.your_name')"
                  v-model="nameNew"
                  variant="underlined"
                  :readonly="loading"
                  :placeholder="$t('loginpage.your_name')"
                  prepend-icon="mdi-account"
                  :error-messages="v$.nameNew.$errors.map((e) => e.$message)"
                  @input="v$.nameNew.$touch"
                  @blur="v$.nameNew.$touch"
                ></v-text-field>

                <v-text-field
                  class="mt-6"
                  v-model="passwordNew"
                  :label="$t('loginpage.password')"
                  :hint="$t('loginpage.password_desc')"
                  persistent-hint
                  variant="underlined"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  counter
                  @click:append="showPassword = !showPassword"
                  :error-messages="
                    v$.passwordNew.$errors.map((e) => e.$message)
                  "
                  @input="v$.passwordNew.$touch"
                  @blur="v$.passwordNew.$touch"
                >
                </v-text-field>

                <br />
                <p class="text-caption text-center">
                  {{ $t("loginpage.agree1") }}
                  <a
                    :href="'/termsconditions'"
                    class="text-decoration-none text-info"
                  >
                    {{ $t("loginpage.tou") }}
                  </a>
                  {{ $t("loginpage.agree2") }}
                  <a
                    :href="'/privacypolicy'"
                    class="text-decoration-none text-info"
                  >
                    {{ $t("loginpage.privacy") }}
                  </a>
                  {{ $t("loginpage.statement") }}.
                </p>

                <v-btn
                  :ripple="false"
                  class="mt-2"
                  :disabled="!formValid"
                  :loading="loading"
                  block
                  color="success"
                  size="x-large"
                  type="submit"
                  elevation="0"
                  rounded="pill"
                  @click="checkData"
                >
                  {{ $t("busienssPage.continue") }}
                </v-btn>
              </v-responsive>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>

import { useHead } from "@vueuse/head";
import { mapGetters, mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  email,
  required,
  sameAs,
  helpers,
  minLength,
  numeric,
  maxLength,
} from "@vuelidate/validators";

export default {
  components: {
   
  },

  computed: {
    ...mapState({
      registerInfo: (state) => state.ShopRegisterStore.registerInfo,
    }),
  },

  mounted() {
    // console.log("registerInfo %o", this.registerInfo.user_email);
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });

    return { v$: useVuelidate() };
  },

  data: () => ({
    formValid: false,
    nameNew: null,
    passwordNew: null,
    showPassword: false,
    loading: false,
  }),

  validations: () => ({
    nameNew: {
      required,
      minLength: minLength(4),
    },
    passwordNew: {
      required,
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        return containsUppercase && containsLowercase && containsNumber;
      },
      minLength: minLength(6),
    },
  }),

  methods: {
    async checkData() {
      if (!this.passwordNew || !this.nameNew) return;

      const item = {
        user_email: this.registerInfo.user_email,
        nameNew: this.nameNew,
        passwordNew: this.passwordNew,
      };

      await this.$store
        .dispatch("ShopRegisterStore/updateRegisInfo", item)
        .then((data) => {
          console.log("registerInfoCompleted: %o", this.registerInfo);

          this.$router.push({
            name: "ShopRegisterDetails",
          });
        })
        .catch((e) => {
          console.error("error: ", e);
        });
    },

    onSubmit() {
      if (!this.formValid) return;
    },
  },
};
</script>

<style scoped>
</style>