<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-stepper
      v-model="e1"
      elevation="0"
      :prev-text="$t('busienssPage.prev')"
      :next-text="$t('busienssPage.next')"
    >
      <v-stepper-header>
        <v-stepper-item value="1" class="text-caption"></v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="2" class="text-caption"></v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="3" class="text-caption"></v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="4" class="text-caption"></v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="5" class="text-caption"></v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="6" class="text-caption"></v-stepper-item>
      </v-stepper-header>
      <v-progress-linear
        v-model="processValue"
        color="blue"
        height="10"
      ></v-progress-linear>

      <v-stepper-actions
        class="mt-6"
        @click:prev="prevOnCLick"
        @click:next="nextOnCLick"
      ></v-stepper-actions>

      <v-stepper-window>
        <v-stepper-window-item value="1">
          <v-sheet class="pa-0" rounded elevation="0">
            <v-row no-gutters>
              <v-col class="" cols="12">
                <p class="text-h5">{{ $t("busienssPage.tellUsBzName") }}</p>
                <p class="text-caption">
                  {{ $t("busienssPage.tellUsBzNameDesc") }}
                </p>
              </v-col>
            </v-row>

            <v-text-field
              hide-details="auto"
              v-model="businessName"
              class="mt-6"
              clearable
              :label="$t('shopsetup.businessname')"
              :hint="$t('shopsetup.businessname_desc')"
              :placeholder="$t('shopsetup.businessname')"
              persistent-hint
              variant="underlined"
              :error-messages="v$.businessName.$errors.map((e) => e.$message)"
              @input="v$.businessName.$touch"
              @blur="v$.businessName.$touch"
            >
            </v-text-field>

            <v-text-field
              class="mt-4"
              v-model="websideName"
              clearable
              :label="$t('shopsetup.website')"
              :hint="$t('shopsetup.website_desc')"
              persistent-hint
              variant="underlined"
              :error-messages="v$.websideName.$errors.map((e) => e.$message)"
              @input="v$.websideName.$touch"
              @blur="v$.websideName.$touch"
            >
            </v-text-field>
          </v-sheet>
        </v-stepper-window-item>

        <!-- WhatService -->
        <v-stepper-window-item value="2">
          <v-sheet class="pa-0" rounded max-height="1200px">
            <v-row no-gutters>
              <v-col class="" cols="12">
                <p class="text-h5">{{ $t("busienssPage.whatService") }}</p>
                <p class="text-caption">
                  {{ $t("busienssPage.whatServiceDesc") }}
                </p>
              </v-col>
            </v-row>

            <p
              v-if="itemSelect.length >= 1"
              class="text-4 mt-3 text-blue"
              style="font-weight: bold"
            >
              {{ $t("busienssPage.priService") }}:
              {{ $t("utls." + getPrimaryTitle) }}
            </p>

            <p v-if="itemSelect.length > 1" class="text-body-2 mt-0 text-grey">
              {{ $t("busienssPage.othService") }}:
              {{ $t("utls." + getOtherTitle) }}
            </p>

            <v-item-group multiple class="mt-6" v-model="itemSelect" max="2">
              <v-row>
                <v-col
                  v-for="item in businessType"
                  :key="item.title"
                  cols="6"
                  md="3"
                >
                  <v-item v-slot="{ isSelected, toggle }">
                    <v-sheet
                      class="ma-2 pa-2 text-center"
                      rounded="xl"
                      :color="isSelected ? 'blue' : ''"
                      :ripple="false"
                      @click="toggle"
                    >
                      <div align="center">
                        <v-img
                          class="mx-4"
                          height="50"
                          :src="item.img"
                          aspect-ratio="1.0"
                        ></v-img>
                      </div>
                      <p class="text-body1 flex-grow-1 mt-2">
                        {{ $t("utls." + item.languageTitle) }}
                      </p>
                    </v-sheet>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-sheet>
        </v-stepper-window-item>

        <!-- TeamSize -->
        <v-stepper-window-item value="3">
          <v-sheet class="pa-0" rounded max-width="1200px">
            <v-row justify="center" no-gutters>
              <v-col class="" cols="12">
                <p class="text-h5">{{ $t("busienssPage.teamSize") }}</p>
                <p class="text-caption">
                  {{ $t("busienssPage.teamSizeDesc") }}
                </p>
              </v-col>
            </v-row>

            <v-radio-group
              class="mt-4"
              v-model="radiosCapacity"
              @change="radiosChanged"
              :mandatory="true"
            >
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.meOnly')"
                value="one"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.2to5')"
                value="two"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.6to10')"
                value="six"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.11more')"
                value="eleven"
                color="blue"
              ></v-radio>
            </v-radio-group>

            <v-card
              v-if="showAddDummyStaffAlert"
              variant="tonal"
              :title="$t('busienssPage.tips')"
              :text="$t('busienssPage.tipsDesc')"
              prepend-icon="mdi-lightbulb-on-outline"
              elevation="0"
            ></v-card>
          </v-sheet>
        </v-stepper-window-item>

        <!-- Location -->
        <v-stepper-window-item value="4">
          <v-sheet class="pa-0" rounded max-height="1200px">
            <v-row justify="center" no-gutters>
              <v-col class="" cols="12">
                <p class="text-h5">{{ $t("busienssPage.setLocation") }}</p>
                <p class="text-caption">
                  {{ $t("busienssPage.setLocationDesc") }}
                </p>
              </v-col>
            </v-row>

            <v-select
              prepend-inner-icon="mdi-map-marker-outline"
              transition="none"
              class="mt-6"
              :label="$t('shopsetup.business_location')"
              :items="prefectureList"
              variant="outlined"
              :item-title="(v) => v.code ? $t('prefecture.' + v.code) : v.name"
              item-value='id'
              v-model="stateSelected"
            ></v-select>

            <v-text-field
              class="mt-2"
              clearable
              v-model="shopAddressNew"
              :label="$t('shop.address')"
              :hint="$t('shopsetup.location_valid')"
              persistent-hint
              variant="underlined"
              :error-messages="v$.shopAddressNew.$errors.map((e) => e.$message)"
              @input="v$.shopAddressNew.$touch"
              @blur="v$.shopAddressNew.$touch"
            >
            </v-text-field>
          </v-sheet>
        </v-stepper-window-item>

        <!-- BusinessPackage -->
        <v-stepper-window-item value="5">
          <v-row justify="center" no-gutters>
            <v-col class="" cols="12">
              <p class="text-h5">{{ $t("busienssPage.selectPackage") }}</p>
            </v-col>
          </v-row>
          <v-container class="mt-0">
            <v-item-group mandatory v-model="selectionPackage">
              <v-row justify="center" no-gutters>
                <v-col class="" cols="12" sm="6">
                  <v-item v-slot="{ isSelected, toggle }">
                    <v-sheet
                      class="ma-2 pa-2"
                      rounded="xl"
                      elevation="2"
                      @click="toggle"
                      :color="isSelected ? 'blue' : ''"
                    >
                      <div align="center">
                        <p class="text-h4 font-weight-bold mt-4">
                          {{ $t("busienssPage.free") }}
                        </p>
                        <v-img max-width="60%" :src="learning_img" />

                        <v-btn
                          :ripple="false"
                          class="ma-4"
                          width="220"
                          variant="outlined"
                          rounded
                          elevation="2"
                        >
                          {{ $t("busienssPage.select") }}
                        </v-btn>
                      </div>
                      <p class="text-6 font-weight-bold ma-3">
                        {{ $t("busienssPage.function") }}
                      </p>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.on_booking')"
                        prepend-icon="mdi-calendar"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.membership')"
                        prepend-icon="mdi-account-group"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.voucher')"
                        prepend-icon="mdi-sale"
                        color="transparent"
                      ></v-card>

                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.shopManagement')"
                        prepend-icon="mdi-store"
                        color="transparent"
                      ></v-card>
                    </v-sheet>
                  </v-item>
                </v-col>

                <v-col class="" cols="12" sm="6">
                  <v-item v-slot="{ isSelected, toggle }">
                    <v-sheet
                      class="ma-2 pa-2"
                      rounded="xl"
                      elevation="2"
                      @click="toggle"
                      :color="isSelected ? 'blue' : ''"
                    >
                      <div align="center">
                        <p class="text-h4 font-weight-bold mt-4">
                          {{ $t("busienssPage.basic") }}
                        </p>
                        <v-img max-width="60%" :src="work_img" />
                        <v-btn
                          :ripple="false"
                          class="ma-4"
                          variant="outlined"
                          width="220"
                          rounded
                          elevation="2"
                        >
                          {{ $t("busienssPage.select") }}
                        </v-btn>
                      </div>
                      <p class="text-6 font-weight-bold ma-3">
                        {{ $t("busienssPage.function") }}
                      </p>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.on_booking')"
                        prepend-icon="mdi-calendar"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.membership')"
                        prepend-icon="mdi-account-group"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.voucher')"
                        prepend-icon="mdi-sale"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.shopManagement')"
                        prepend-icon="mdi-store"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.realTime')"
                        prepend-icon="mdi-seat"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.monthlySub')"
                        prepend-icon="mdi-cash-sync"
                        color="transparent"
                      ></v-card>
                      <v-card
                        elevation="0"
                        :title="$t('busienssPage.ticket')"
                        prepend-icon="mdi-ticket"
                        color="transparent"
                      ></v-card>
                    </v-sheet>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-container>
        </v-stepper-window-item>

        <!-- HowKnowUs -->
        <v-stepper-window-item value="6">
          <v-sheet class="pa-0" rounded max-height="1200px">
            <v-row justify="center" no-gutters>
              <v-col class="" cols="12">
                <p class="text-h5">{{ $t("busienssPage.howKnowUs") }}</p>
              </v-col>
            </v-row>

            <v-radio-group
              class="mt-4"
              v-model="radiosKnow360"
              :mandatory="true"
              @change="radiosKnow360Changed"
            >
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.byFriend')"
                value="byFriend"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.bySalesman')"
                value="bySalesman"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.byGoogle')"
                value="byGoogle"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.socialMedia')"
                value="socialMedia"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.byAdMall')"
                value="byAdMall"
                color="blue"
              ></v-radio>
              <v-radio
                class="text-body-1 text-black"
                :label="$t('busienssPage.other')"
                value="other"
                color="blue"
              ></v-radio>
            </v-radio-group>

            <v-text-field
              v-if="showOtherTextForKnow360"
              :label="$t('busienssPage.specify')"
              v-model="otherTextForKnow360"
              variant="underlined"
            ></v-text-field>

            <v-btn
              v-if="showSubmitBtn"
              :ripple="false"
              class="mt-2"
              color="success"
              block
              size="x-large"
              width="100%"
              :loading="loading"
              @click="submitOnClick"
              elevation="0"
              rounded="pill"
            >
              {{ $t("busienssPage.clickSubmit") }}
            </v-btn>
          </v-sheet>
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>
  </v-sheet>

  <v-dialog v-model="openDoneSubmitDialog" width="auto">
    <v-sheet
      elevation="12"
      max-width="600"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-check-circle"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">{{ $t("busienssPage.acSubmited") }}</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        {{ $t("busienssPage.acSubmitedDesc") }}
        <span style="font-size: 14px; font-weight: bold" class="text-blue">
          {{ registerInfo.user_email }}</span
        >
      </p>

      <v-divider class="mb-4"></v-divider>

      <div class="text-end">
        <v-btn
          class="text-none"
          color="success"
          rounded
          variant="flat"
          width="90"
          :to="{ name: 'BusinessPage' }"
        >
          {{ $t("busienssPage.done") }}
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
  <VSonner position="top-center" />
</template>

<script>
import Uitls from "@/uitls/Uitls";
import { useHead } from "@vueuse/head";
import { VSonner, toast } from "vuetify-sonner";
import { useVuelidate } from "@vuelidate/core";
import GFDataShopRegist from "@/uitls/GFDataShopRegist";
import { mapGetters, mapState, mapActions } from "vuex";
import axios from "axios";
import {
  email,
  required,
  url,
  helpers,
  minLength,
  numeric,
  maxLength,
} from "@vuelidate/validators";

export default {
  components: {
    VSonner,
  },

  computed: {
    ...mapState({
      registerInfo: (state) => state.ShopRegisterStore.registerInfo,
    }),

    getPrimaryTitle() {
      if (this.itemSelect.length == 0) {
        return "";
      } else if (this.itemSelect.length == 1) {
        return this.businessType[this.itemSelect].languageTitle;
      } else {
        return this.businessType[this.itemSelect[0]].languageTitle;
      }
    },

    getOtherTitle() {
      const nameArray = [];

      for (let position in this.itemSelect) {
        const element =
          this.businessType[this.itemSelect[position]].languageTitle;
        if (position >= 1) {
          nameArray.push(element);
        }
      }
      //remove html tag
      const tmp = document.createElement("DIV");
      tmp.innerHTML = nameArray;
      return tmp.textContent || tmp.innerText || "";
    },
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });

    return { v$: useVuelidate() };
  },

  async mounted() {
    console.log("mounted %o", this.registerInfo);
    await this.getPrefectureList()
  },

  data: () => ({
    e1: 0,
    processValue: 0,
    itemSelect: [],
    radiosCapacity: null,
    radiosKnow360: null,
    showAddDummyStaffAlert: false,
    showOtherTextForKnow360: false,
    showSubmitBtn: false,
    openDoneSubmitDialog: false,
    stateSelected: null,
    businessName: null,
    websideName: null,
    shopAddressNew: null,
    selectionPackage: null,
    otherTextForKnow360: null,
    locationTags: Uitls.locationTags(),
    businessType: Uitls.businessTypeTags(),
    learning_img: require("@/assets/img/online-learning-concept.png"),
    work_img: require("@/assets/img/girl-work-on-laptop.png"),
    loading: false,
    gfSurveyUrl: GFDataShopRegist.GFDocUrl(),
    surveyList: GFDataShopRegist.GFDocSurvey(),
    prefectureList: [],
  }),

  validations() {
    return {
      businessName: {
        required,
      },
      websideName: {
        url,
      },
      shopAddressNew: {
        required,
        minLength: minLength(6),
      },
    };
  },

  methods: {
    nextOnCLick() {
      if (this.e1 == 0) {
        if (!this.businessName) {
          toast(this.$i18n.tc("busienssPage.bzNameNeed"), {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        } else {
          this.e1++;
          this.processValue = this.processValue + (this.e1 + 17);
        }
      } else if (this.e1 == 1) {
        if (this.itemSelect.length == 0) {
          toast(this.$i18n.tc("busienssPage.serviceNeed"), {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        } else {
          this.e1++;
          this.processValue = this.processValue + (this.e1 + 17);
        }
      } else if (this.e1 == 2) {
        if (!this.radiosCapacity) {
          toast(this.$i18n.tc("busienssPage.teamNeed"), {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        } else {
          this.e1++;
          this.processValue = this.processValue + (this.e1 + 17);
        }
      } else if (this.e1 == 3) {
        if (!this.stateSelected || Uitls.isEmpty(this.shopAddressNew)) {
          toast(this.$i18n.tc("busienssPage.localNeed"), {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        } else if (this.stateSelected == 0) {
          toast(this.$i18n.tc("busienssPage.noAll"), {
            cardProps: {
              color: "warning",
              class: "my-toast",
            },
          });
        } else {
          this.e1++;
          this.processValue = this.processValue + (this.e1 + 17);
        }
      } else if (this.e1 == 4) {
        if (this.selectionPackage == null) {
          toast(this.$i18n.tc("busienssPage.packageNeed"), {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        } else {
          this.e1++;
          this.processValue = 100;
        }
      }
    },

    prevOnCLick() {
      this.e1--;
      if (this.e1 == 0) {
        this.processValue = 0;
      } else {
        this.processValue = this.processValue + (this.e1 - 17);
      }
    },

    radiosChanged() {
      if (this.radiosCapacity != "one") {
        this.showAddDummyStaffAlert = true;
      } else {
        this.showAddDummyStaffAlert = false;
      }
    },

    radiosKnow360Changed() {
      this.showSubmitBtn = true;
      if (this.radiosKnow360 == "other") {
        this.showOtherTextForKnow360 = true;
      } else {
        this.showOtherTextForKnow360 = false;
      }
    },

    submitOnClick() {
      this.submitGoogleForm();
    },

    async submitGoogleForm() {
      this.loading = true;
      let postItems = [];

      for (let index = 0; index < this.surveyList.length; index++) {
        const item = this.surveyList[index];

        switch (index) {
          case 0:
            var postItem =
              "entry." + item.name + "=" + this.registerInfo.user_email;
            postItems.push(postItem);
            break;

          case 1:
            var postItem =
              "entry." + item.name + "=" + this.registerInfo.nameNew;
            postItems.push(postItem);
            break;

          case 2:
            var postItem =
              "entry." + item.name + "=" + this.registerInfo.passwordNew;
            postItems.push(postItem);
            break;

          case 3:
            var postItem = "entry." + item.name + "=" + this.businessName;
            postItems.push(postItem);
            break;

          case 4:
            var postItem = "entry." + item.name + "=" + this.websideName;
            postItems.push(postItem);
            break;

          case 5:
            var postItem =
              "entry." +
              item.name +
              "=" +
              this.businessType[this.itemSelect[0]].title;
            postItems.push(postItem);
            break;

          case 6:
            if (this.itemSelect.length == 2) {
              var postItem =
                "entry." +
                item.name +
                "=" +
                this.businessType[this.itemSelect[1]].title;
              postItems.push(postItem);
            }
            break;

          case 7:
            var postItem = "entry." + item.name + "=" + this.radiosCapacity;
            postItems.push(postItem);
            break;

          case 8:
            var postItem = "entry." + item.name + "=" + this.stateSelected;
            postItems.push(postItem);
            break;

          case 9:
            var postItem = "entry." + item.name + "=" + this.shopAddressNew;
            postItems.push(postItem);
            break;

          case 10:
            var postItem = "entry." + item.name + "=" + this.selectionPackage;
            postItems.push(postItem);
            break;

          case 11:
            var postItem;
            if (this.showOtherTextForKnow360) {
              postItem = "entry." + item.name + "=" + this.otherTextForKnow360;
            } else {
              postItem = "entry." + item.name + "=" + this.radiosKnow360;
            }

            postItems.push(postItem);
            break;
          default:
            break;
        }
      }

      const sentence = postItems.join("&");
      const url = this.gfSurveyUrl + "?" + sentence;

      setTimeout(() => {
        this.loading = false;
        this.openDoneSubmitDialog = true;
      }, 2000);

      const response = await axios.get(url);
      console.log(response.status);
    },

    async getPrefectureList() {
      const vm = this
      vm.prefectureList = []
      await vm.$store.dispatch("shopStore/getPrefectureByCountry", 'malaysia')
        .then(async response => {
          if (response && response.data && response.data.length > 0) {
            response.data.forEach(v => {
              vm.prefectureList.push({
                id: v.id,
                name: v.name,
                code: v.code,
              })
            })
          }
        }).catch((e) => {
          console.error(e);
        })

    },
  },
};
</script>

<style scoped>
</style>