<template>
  <v-dialog max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <div class="carousel-wrapper">
        <div v-if="imgList.length" class="carousel">
          <div :class="!expand ? 'staff' : 'shop'" class="img-container">
            <transition name="fade">
              <img v-if="expand" v-bind="attrs" v-on="on" :src="imgList[currImgIdx]" :key="imgList[currImgIdx]" :class="{ shop: expand }" class="currImg image">
              <img v-else :src="imgList[currImgIdx]" :key="imgList[currImgIdx]" class="currImg image">
            </transition>
          </div>
          <v-btn v-if="imgList.length > 1" @click="moveToOtherImg(currImgIdx - 1, $event)" :class="!expand ? 'staff' : 'shop'" color="nobitel" fab small absolute left top aria-label="左" class="scroll-btn__left">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn v-if="imgList.length > 1" @click="moveToOtherImg(currImgIdx + 1, $event)" :class="!expand ? 'staff' : 'shop'" color="nobitel" fab small absolute right top aria-label="右" class="scroll-btn__right">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <transition-group :name="transition_name" tag="div" class="crop">
            <div v-for="(chunk, i) in arrChunk" v-show="currSlide == i" :key="`key-${i}`" class="carousel_chunk">
              <div v-for="(item, j) in chunk" :key="`key-${j}`" @click="currImgIdx = j + i * chunkSize" :class="{ currImgBorder: item === imgList[currImgIdx] }" class="chunk_item">
                <img :src="item">
              </div>
            </div>
          </transition-group>
        </div>
        <div v-else align="center" class="carousel">
          <img v-if="isStaff" :src="$utils.noStaffImage" class="currImg shop-img__image">
          <img v-else :src="$utils.noShopImage" class="shop-img__image">
        </div>
      </div>
    </template>
    <template v-slot:default="">
      <transition name="fade">
        <img v-if="imgList" :src="imgList[currImgIdx]" class="shop-img__image">
      </transition>
    </template>
  </v-dialog>
</template>

<style scoped>
.shop-img__image {
  width: 100%;
  animation: fadeOut 3s;
  animation-fill-mode: forwards;
}

.shop-img__noimage {
  max-width: 100%;
}

.staff-img__image {
  max-width: 100%;
  object-position: 50% 50%;
  width: 100%;
}

.img-container {
  position: relative;
  max-width: 100%;
}

.img-container.shop {
  height: 300px;
}

.img-container.staff {
  height: 0;
  padding-bottom: 100%;
  margin: 0 auto;
}

.img-container.modal {
  width: 100%;
}

.image {
  position: absolute;
  left: 0;
  max-width: 100%;
  object-position: 50% 50%;
  width: 100%;
}

.image:nth-of-type(1) {
  animation: fadeOut 3s;
  animation-fill-mode: forwards;
}

.crop {
  height: 96px;
  overflow: hidden;
  width: 100%;
  padding: 8px 0;
}

.carousel .theme--light.v-btn.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.38) !important;
}

.carousel .currImg.shop {
  cursor: pointer;
  height: 300px;
}

.carousel_chunk {
  display: flex;
  justify-content: inherit;
  align-items: center;
}

.chunk_item {
  height: 80px;
  margin: 2px;
  padding: 1px;
  border: 1px solid transparent;
  cursor: pointer;
}

.chunk_item.currImgBorder {
  background: #91B73A;
  border-color: transparent;
}

.chunk_item img {
  display: inline-block;
  height: 100%;
}

.scroll-btn__left {
  position: sticky;
  right: 0;
  margin-left: 10px;
  cursor: pointer;
}

.scroll-btn__left.shop {
  margin-top: -300px;
}

.scroll-btn__left.staff {
  margin-top: -400px;
}

.scroll-btn__right {
  position: sticky;
  margin-right: 10px;
  float: right;
  cursor: pointer;
}

.scroll-btn__right.shop {
  margin-top: -155px;
}

.scroll-btn__right.staff {
  margin-top: -206px;
}

.v-dialog {
  box-shadow: unset !important;
}

@media (max-width: 600px) { /* Replace '600px' with your `$mobile-break-point` value */
  .v-dialog.v-dialog--active {
    margin: 10px !important;
  }
}

</style>

<script>
// import { mdiChevronLeft, mdiChevronRight } from '@mdi/js' // eslint-disable-line
export default {
  props: {
    imgList: {
      type: Array,
      default: () => [],
    },
    expand: {
      type: Boolean,
      default: true,
    },
    isStaff: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      //   mdiChevronLeft,
      //   mdiChevronRight, // eslint-disable-line
      currSlide: 0,
      currImgIdx: 0,
      transition_name: 'slide_next',
      timer: null,
    }
  },
  computed: {
    arrChunk() {
      return this.imgList
        ? Array.from(
            { length: Math.ceil(this.imgList.length / this.chunkSize) },
            (v, i) =>
              this.imgList.slice(
                i * this.chunkSize,
                i * this.chunkSize + this.chunkSize
              )
          )
        : null
    },
    chunkSize() {
      return this.imgList ? this.imgList.length : 0
    },
  },
  created() {
    this.timer = setInterval(() => {
      this.moveToOtherImg(this.currImgIdx + 1, null)
    }, 3000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer)
    })
  },
  methods: {
    moveToOtherImg(n, event) {
      // ボタンクリックで画像スワイプした場合、自動スワイプメソッドをリセット
      if (event) {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.moveToOtherImg(this.currImgIdx + 1)
        }, 3000)
      }
      this.currImgIdx =
        n < 0 ? this.imgList.length - 1 : n % this.imgList.length
    },
  },
}
</script>
