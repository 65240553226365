<template>
  <v-container fluid>
    <v-sheet
      elevation="1"
      max-width="580"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto mt-6"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-card-account-mail"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">{{ $t("loginpage.verify_email") }}</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        {{ $t("loginpage.verify_email_1") }}
        <span style="font-weight: bold"> {{ user_email }} </span>
        {{ $t("loginpage.verify_email_2") }}
      </p>

      <p class="mb-4 text-medium-emphasis text-body-2">
        <a @click="btnOnClick" class="text-decoration-none text-info">{{
          $t("loginpage.click_here")
        }}</a>
        {{ $t("loginpage.to_page") }}
      </p>
    </v-sheet>
  </v-container>
  <VSonner position="top-center" />
</template>


<script scoped>
import { VSonner, toast } from "vuetify-sonner";

export default {
  props: ["user_email"],

  setup(props) {
    // setup() receives props as the first argument.
    console.log("user_email: " + props.user_email);
  },

  data() {
    return {};
  },

  methods: {
    btnOnClick() {
      this.$router.push({ name: "LoginPage" });
    },

    sendEmailVerification() {
      this.$store
        .dispatch("authStore/sendEmailVerification")
        .then((res) => {
          toast("Verify email sent", {
            cardProps: {
              color: "success",
              class: "my-toast",
            },
          });
        })

        .catch((err) => {
          toast(err, {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },
  },
};
</script>

<style scoped>
</style>