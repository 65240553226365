import axios from 'axios'
import Constants from '@/uitls/Constants'
import Uitls from '@/uitls/Uitls'
import { WORDPRESS_POSTS_API, WORDPRESS_CATEGORIES_API, WORDPRESS_TAGS_API, WORDPRESS_SEARCH_API } from '@/store/api'
import i18n from '@/uitls/Translations'

const state = {
  article: null,
  articleCategiry: null,
};

const getters = {
  getArticle: state => {
    return state.article;
  },
  getArticleCategory: state => {
    return state.articleCategiry;
  },
};

const mutations = {
  SET_DATA: (state, data) => {
    state.article = data;
  },
  SET_DATA_ARTICLE_CATEGORY: (state, data) => {
    state.articleCategiry = data
  },
};

const actions = {


  async getWordPressTop8Article({ commit }) {

    var url;
    switch (i18n.global.locale) {
      case "en":
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API + "?per_page=6";
        break;
      case "zhCN":
        url = Constants.ARTICLE_ZH_URL + WORDPRESS_POSTS_API + "?per_page=6";
        break;
      case "ms":
        url = Constants.ARTICLE_MS_URL + WORDPRESS_POSTS_API + "?per_page=6";
        break;
      default:
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API + "?per_page=6";
        break;
    }

    const response = await axios.get(url)
    return response.data
  },

  async getWordPressArticleList({ commit, page = 1 }) {

    var url;
    switch (i18n.global.locale) {
      case "en":
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API + "?per_page=20" + "&page=" + page;
        break;
      case "zhCN":
        url = Constants.ARTICLE_ZH_URL + WORDPRESS_POSTS_API + "?per_page=20" + "&page=" + page;
        break;
      case "ms":
        url = Constants.ARTICLE_MS_URL + WORDPRESS_POSTS_API + "?per_page=20" + "&page=" + page;
        break;
      default:
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API + "?per_page=20" + "&page=" + page;
        break;
    }

    await axios
      .get(url)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          commit("SET_DATA", response.data);
        } else {
          return Promise.reject;
        }
      })
      .catch((error) => {
        // handle error
        console.error(error);
        throw error;
      });
  },

  async getWordPressCategories({ commit }, params = {}) {

    var url;
    switch (i18n.global.locale) {
      case "en":
        url = Constants.ARTICLE_URL + WORDPRESS_CATEGORIES_API;
        break;
      case "zhCN":
        url = Constants.ARTICLE_ZH_URL + WORDPRESS_CATEGORIES_API;
        break;
      case "ms":
        url = Constants.ARTICLE_MS_URL + WORDPRESS_CATEGORIES_API;
        break;
      default:
        url = Constants.ARTICLE_URL + WORDPRESS_CATEGORIES_API;
        break;
    }

    const response = await axios.get(url, {
      params,
    })

    commit("SET_DATA_ARTICLE_CATEGORY", response.data);

    return response.data;

    // .then(response => {
    //   if (response.status === 200 || response.status === 201) {
    //     commit("SET_DATA_ARTICLE_CATEGORY", response.data);
    //     return response.data
    //   } else {
    //     return Promise.reject;
    //   }
    // })
    //   .catch((error) => {
    //     // handle error
    //     console.error(error);
    //     throw error;
    //   });
  },

  async getWordPressSearchArticle({ commit }, params = {}) {

    var url;
    switch (i18n.global.locale) {
      case "en":
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API;
        break;
      case "zhCN":
        url = Constants.ARTICLE_ZH_URL + WORDPRESS_POSTS_API;
        break;
      case "ms":
        url = Constants.ARTICLE_MS_URL + WORDPRESS_POSTS_API;
        break;
      default:
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API;
        break;
    }

    const response = await axios.get(url, {
      params,
    })
    return response.data
  },

  async getWordPressCategoryList({ commit }, params = {}) {


    var url;
    switch (i18n.global.locale) {
      case "en":
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API;
        break;
      case "zhCN":
        url = Constants.ARTICLE_ZH_URL + WORDPRESS_POSTS_API;
        break;
      case "ms":
        url = Constants.ARTICLE_MS_URL + WORDPRESS_POSTS_API;
        break;
      default:
        url = Constants.ARTICLE_URL + WORDPRESS_POSTS_API;
        break;
    }

    const response = await axios.get(url, {
      params,
    })
    return response.data
  },


  async getWordPressSingleTag({ commit }, value) {

    var url;
    switch (i18n.global.locale) {
      case "en":
        url = Constants.ARTICLE_URL + `${WORDPRESS_TAGS_API}/${value}`;
        break;
      case "zhCN":
        url = Constants.ARTICLE_ZH_URL + `${WORDPRESS_TAGS_API}/${value}`;
        break;
      case "ms":
        url = Constants.ARTICLE_MS_URL + `${WORDPRESS_TAGS_API}/${value}`;
        break;
      default:
        url = Constants.ARTICLE_URL + `${WORDPRESS_TAGS_API}/${value}`;
        break;
    }


    const response = await axios.get(url);
    return response.data
  },

  async fetchWordPressSingleArticle({ commit }, payload = {}) {
    const params = Uitls.cloneDeep(payload)

    let id
    if (!Uitls.isNil(params.id)) {
      id = params.id
      delete params.id
    }

    var url;
    switch (i18n.global.locale) {
      case "en":
        url = Constants.ARTICLE_URL + `${WORDPRESS_POSTS_API}/${id}`;
        break;
      case "zhCN":
        url = Constants.ARTICLE_ZH_URL + `${WORDPRESS_POSTS_API}/${id}`;
        break;
      case "ms":
        url = Constants.ARTICLE_MS_URL + `${WORDPRESS_POSTS_API}/${id}`;
        break;
      default:
        url = Constants.ARTICLE_URL + `${WORDPRESS_POSTS_API}/${id}`;
        break;
    }

    const response = await axios.get(url, {
      params,
    })

    return response.data
  },


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
