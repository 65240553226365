const state = {
  registerInfo: null,
};

const getters = {
  getRegisterInfo: (state) => state.registerInfo,
};

const mutations = {
  SET_DATA: (state, data) => {
    state.registerInfo = data;
  },
};

const actions = {
  async updateRegisInfo({ commit, state, dispatch }, data) {
    commit("SET_DATA", data)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
