<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn class="ma-0" icon="mdi-arrow-left" @click="$router.push('/settingpage')"></v-btn>
    <v-app-bar-title>{{$t("settingpage.contact")}} </v-app-bar-title>
  </v-app-bar>
  <v-main>
    <v-container fluid>
      <v-sheet
        elevation="0"
        max-width="1200"
        rounded="lg"
        width="100%"
        class="pa-0 mx-auto mt-6"
      >
        <v-text-field
          hide-details="auto"
          :label="$t('settingpage.your_contact_number')"
          v-model="phoneNew"
          variant="underlined"
          :readonly="loading"
          prepend-icon="mdi-phone"
          clearable
          :placeholder="$t('settingpage.your_contact_number')"
          :error-messages="v$.phoneNew.$errors.map((e) => e.$message)"
          @input="v$.phoneNew.$touch"
          @blur="v$.phoneNew.$touch"
        ></v-text-field>

        <v-row justify="center" no-gutters>
          <v-btn
            elevation="0"
            color="blue"
            width="93%"
            class="ma-6"
            height="50px"
            :ripple="false"
            rounded="pill"
            :loading="loading"
            @click="checkUserInsertValue"
          >
            {{$t("settingpage.submit")}}
          </v-btn>
        </v-row>
      </v-sheet>
    </v-container>
  </v-main>
  <VSonner position="top-center" />
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { minLength, numeric, required } from "@vuelidate/validators";
import { VSonner, toast } from "vuetify-sonner";
import utils from "@/uitls/Uitls";

export default {
  components: {
    VSonner,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  computed: {
    ...mapGetters({
      userInfo: "userStore/userInfo",
    }),
  },

  data: () => ({
    phoneNew: null,
    loading: false,
    userPost: {
      id: null,
      profile: {
        phone: null,
      },
    },
  }),

  validations() {
    return {
      phoneNew: {
        required,
        numeric,
        minLength: minLength(6),
      },
    };
  },

  mounted() {
    this.userPost.id = this.userInfo.id;

    if (
      !utils.isEmpty(this.userInfo.profile) &&
      !utils.isEmpty(this.userInfo.profile.phone)
    ) {
      this.phoneNew = this.userInfo.profile.phone;
    }

  },

  methods: {
    async checkUserInsertValue() {
      if (!this.phoneNew) return;

      this.loading = true;

      this.userPost.profile = {
        phone: this.phoneNew,
      };

      const dataUpdate = {};
      dataUpdate.user = this.userPost;

      await this.$store
        .dispatch("userStore/updateUserInfo", dataUpdate)
        .then((data) => {
          this.loading = false;
          toast("Contact number changed.", {
            cardProps: {
              color: "success",
              class: "my-toast",
            },
          });

          setTimeout(() => {
            this.$router.push({ name: "SettingPage" });
          }, 3000);
        })
        .catch((e) => {
          this.loading = false;
          console.error("error: ", e);
          toast("Contact change faild, please try again.", {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },
  },
};
</script>