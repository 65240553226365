<template>
  <div class="booking-card mb-5 ma-0" v-if="eachData">
    <div class="booking-card-details" :class="getColorClass(eachData.status)">
      <div class="booking-card-details-status">
        <p class="text-body-2" style="font-weight: bold">
          {{ getStatusMessage(eachData.status) }}
        </p>
      </div>
    </div>

    <div class="booking-card-right-side">
      <v-row no-gutters>
        <!-- <v-col cols="12">
          <v-chip
            class="booking-card-details-chip"
            :color="getChipColorClass(eachData.status)"
            variant="flat"
          >
            {{ getStatusMessage(eachData.status) }}
          </v-chip>
        </v-col> -->

        <v-col cols="12" class="mt-0">
          <span style="font-weight: bold">{{
            $t("reservation.reserve_id")
          }}</span>
          <span style="font-weight: bold">: {{ eachData.id }}</span>
        </v-col>
      </v-row>

      <!-- <v-row no-gutters v-if="eachData.provider_code">
        <v-col cols="12" class="mt-2">
          {{ $t("mypage.brand") }}: {{ eachData.provider_code }}
        </v-col>
      </v-row> -->

      <v-row v-if="eachData.shop">
        <v-col cols="12" class="mt-2">
          <v-icon icon="mdi-store" color="black" class="mx-0" />
          <span class="mx-2 text-body-1">{{ eachData.shop.name }}</span>
        </v-col>
      </v-row>

      <v-row v-if="eachData.starts_at">
        <v-col cols="12" class="mt-2">
          <v-icon icon="mdi-calendar" color="black" class="mx-0" />
          <span class="mx-2 text-body-1">{{ eachData.starts_at }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    eachData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },

  watch: {
    eachData() {
      const vm = this;
      vm.handleEachData();
    },
  },
  mounted() {
    const vm = this;
    vm.handleEachData();
  },

  methods: {
    handleEachData() {},
    getStatusMessage(status) {
      switch (status) {
        case "requested":
          return this.$t("reservationdetails.pending");
        case "reserved":
          return this.$t("reservationdetails.reserved");
        case "canceled":
          return this.$t("reservationdetails.canceled");
        case "reserve-canceled":
          return this.$t("reservationdetails.reserveCanceled");
        case "visited":
          return this.$t("reservationdetails.visited");
        case "request-expired":
          return this.$t("reservationdetails.requestExpired");
        case "reserve-expired":
          return this.$t("reservationdetails.reserveExpired");
        default:
          return status;
      }
    },
    getColorClass(status) {
      switch (status) {
        case "requested":
          return "requested-chip";
        case "reserved":
          return "reserved-chip";
        case "canceled":
          return "canceled-chip";
        case "reserve-canceled":
          return "reserve-canceled-chip";
        case "visited":
          return "visited-chip";
        case "request-expired":
          return "request-expired-chip";
        case "reserve-expired":
          return "reserve-expired-chip";
        default:
          return "";
      }
    },
    getChipColorClass(status) {
      switch (status) {
        case "requested":
        case "reserved":
          return "#e53935";
        case "visited":
          return "#007ac1";
        case "canceled":
        case "reserve-canceled":
        case "request-expired":
        case "reserve-expired":
          return "grey-lighten-2";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.v-row {
  margin: 0;
  align-items: center;
}

.v-col {
  padding: 0;
}

.booking-card {
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  margin: 10em 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
}

.booking-card-details {
  display: flex;
  align-items: center;
  width: 10em;
  color: white;
  border-radius: 2em 0 2em 0px;
  text-align: center;
}

.booking-card-details-status {
  padding: 8px;
  width: 100%;
}

.booking-card-right-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  flex: 1;
  width: 100%;
  margin-left: 1.5em;
  position: relative;
}

span.v-chip.booking-card-details-chip {
  position: relative;
  justify-content: center;
  padding: 4;
  margin-left: 0px;
}

.details {
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
}

.requested-chip {
  background-color: #3a96b7;
}

.reserved-chip {
  background-color: #91b73a;
}

.canceled-chip {
  background-color: #616161;
}

.reserve-canceled-chip {
  background-color: #616161;
}

.visited-chip {
  background-color: #007ac1;
}

.request-expired-chip {
  background-color: #616161;
}

.reserve-expired-chip {
  background-color: #616161;
}
</style>