<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
  </v-sheet>

  <!-- display a salesorderList history below -->
  <v-sheet
    elevation="0"
    max-width="1200"
    rounded="lg"
    width="100%"
    class="pa-0 mx-auto mt-6"
    v-if="salesorderList.length > 0"
  >
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-basket-outline"
        color="blue"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t("mypage.purchaseHistory") }}
    </p>

    <v-sheet
      elevation="0"
      rounded="lg"
      width="100%"
      class="pa-3 mx-auto mt-4"
      v-for="(salesorder, index) in salesorderList"
      :key="index"
    >
      <v-row no-gutters>
        <v-col cols="4">
          <v-img
            :src="salesorder.picture_path"
            height="100"
            width="100"
            class="mb-5"
          ></v-img>
        </v-col>
        <v-col cols="4">
          <p class="text-body-1">{{ salesorder.description }}</p>
          <p class="text-caption">{{ salesorder.code }}</p>
          <p class="text-caption">{{ salesorder.created_at }}</p>
        </v-col>
        <v-col cols="6">
          <p>{{ $t("mypage.quantity") }}: {{ salesorder.qty }}</p>
          <p>{{ $t("mypage.status") }}: Available</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <p style="font-weight: bold">RM {{ salesorder.totalamount }}</p>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="green"
            variant="outlined"
            rounded="lg"
            :ripple="false"
            class="mt-5"
            block
            @click="goToDetail(salesorder.id)"
          >
            {{ $t("mypage.check_order") }}
          </v-btn>
          <br />
          <v-divider> </v-divider>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
  <!-- Without data -->
  <v-sheet
    elevation="0"
    max-width="1200"
    rounded="lg"
    width="100%"
    class="pa-0 mx-auto mt-6"
    v-if="salesorderList.length == 0"
  >
    <div>
      <v-sheet
        elevation="0"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="blue"
          icon="mdi-basket-outline"
          size="112"
        ></v-icon>

        <h2 class="text-h5 mb-6">{{ $t("settingpage.no_purchase") }}</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          {{ $t("settingpage.like_shop_desc") }}
          <br />

          <a :href="'/hairsalon/searchresult'" class="text-decoration-none text-info">{{
            $t("settingpage.find_shop")
          }}</a>
        </p>
      </v-sheet>
    </div>
  </v-sheet>
  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
        >{{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { VSonner, toast } from "vuetify-sonner";
import utils from "@/uitls/Uitls";

export default {
  components: {
    VSonner,
  },

  computed: {
    ...mapState({
      soList: (state) => state.CrowdfundingSalesOrderStore.soList,
    }),
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
      userTrouble: "troubleStore/userTrouble",
    }),
  },

  watch: {
    isAuthenticated(to, from) {
      if (this.isAuthenticated) {
        // this.parseUserInfo();
      }
    },
    user(to, from) {},
  },

  data() {
    return {
      salesorderList: [],
      loadingDialog: true,
    };
  },

  beforeMount() {},

  async mounted() {
    await this.parseData()
      .then(
        () => {
          this.salesorderList = this.soList.data;
          this.loadingDialog = false;
        },
        (error) => {
          console.error("Nothing from server." + error);
          this.loadingDialog = false;
          this.$router.push("/404");
        }
      )
      .catch((e) => {
        console.error("error: ", e);
        this.loadingDialog = false;
        this.$router.push("/404");
      });
  },

  methods: {
    ...mapActions({
      parseData: "CrowdfundingSalesOrderStore/getData",
    }),
    goToDetail(id) {
      this.$router.push({ name: "PurchaseDetail", params: { id: id } });
    },
  },
};
</script>