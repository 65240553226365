import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/global.css'
import vuetify from "./plugins/vuetify";
import VueSnip from 'vue-snip'
import FlagIcon from "vue-flag-icon";
import VueGtag from "vue-gtag-next";
import VueSocialSharing from 'vue-social-sharing'
import VuetifyToast from 'vuetify-toast-snackbar'
import store from './store'
import VueCookies from 'vue3-cookies'
import Uitls from './uitls/Uitls';
import GFData from "@/uitls/GFData";
import Vue3Autocounter from 'vue3-autocounter';
import { createHead } from '@vueuse/head'
import i18n from './uitls/Translations'
import { createGtm } from '@gtm-support/vue-gtm';
import VuetifyUseDialog from 'vuetify-use-dialog'

import { computed } from "vue";
import { useDisplay } from "vuetify";
const isMobileDevice = computed(() => {
  if (useDisplay().smAndDown.value) {
    return true;
  } else {
    return false;
  }
});




//https://github.com/mirari/v-viewer/tree/v3
// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'

import axiosClient from './plugins/axiosClient';
import VueAxios from 'vue-axios'

// https://github.com/xon52/x5-gmaps
import v3gmaps from 'v3-gmaps'
// Optional stylesheet needed for showing errors and the Popup component
import 'v3-gmaps/dist/style.css';


const app = createApp(App)

//Assign the global variable before mounting
app.config.globalProperties.isMobileDevice = isMobileDevice
app.config.globalProperties.GLOBAL_MAX_WIDTH = "95%";
app.config.globalProperties.$axios = { ...axiosClient };
app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.$uitls = { ...Uitls };
app.config.globalProperties.$store = { ...store };


app.use(router)
app.use(vuetify)
app.use(VueSnip)
app.use(i18n)
app.use(FlagIcon)
// app.use(VueViewer)
app.use(VueAxios, axiosClient)
app.use(Uitls)
app.use(GFData)
app.use(store)
app.use(VueSocialSharing)
app.use(v3gmaps, { key: 'AIzaSyAvTxz-6HZtoMMdBkFKXOcJtMsiFM11mzc', libraries: ['visualization'] })
app.use(Vue3Autocounter)
app.use(createHead())

app.use(VuetifyUseDialog, {
  confirmDialog: {
    // useConfirm options
    title: null
  },
  snackbar: {
    // useSnackbar options
    snackbarProps: {
      timeout: 2000,
    }
  }
})

app.use(VueGtag, {
  property: {
    id: "G-12FH72GLFT" //MesureID:G-12FH72GLFT ,408112158
  }
},
  router
)

app.use(createGtm({
  id: "GTM-N4PKCLT8",
  vueRouter: router,
  debug: true
})
)

app.use(VueCookies, {
  expireTimes: "30d",
  secure: false,
});


// app.use(VuetifyToast);


app.mount("#app")






