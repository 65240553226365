
<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn
      class="ma-0"
      icon="mdi-arrow-left"
      @click="$router.push('/mypage')"
    ></v-btn>
    <v-app-bar-title>{{ $t("settingpage.favorite") }}</v-app-bar-title>
  </v-app-bar>

  <v-main>
    <v-sheet
      class="pa-0 ma-0 mx-auto"
      color="white"
      max-width="1200"
      rounded="lg"
      width="100%"
      elevation="0"
    >
      <v-container fluid>
        <!--  Favorite Stylist -->
        <div v-if="shops">
          <v-row no-gutters center warp class="d-flex mt-0">
            <v-col v-for="shop in shops" :key="shop" cols="12" md="4">
              <router-link
                :to="{
                  name: 'Shop',
                  params: { id: shop.shop_id, openPage: '1', scrollPage: '0' },
                }"
                style="text-decoration: none; color: inherit"
              >
                <v-card
                  class="text-left mb-4 mx-auto"
                  color="white"
                  max-width="344"
                >
                  <v-responsive class="pt-0">
                    <v-img
                      v-if="shop.shop_img_small"
                      max-width="100%"
                      height="200px"
                      :lazy-src="local_shop"
                      :src="shop.shop_img_small"
                      class="fill-width"
                      cover
                      :aspect-ratio="this.isMobileDevice.value ? 1 : 16 / 9"
                    ></v-img>
                  </v-responsive>

                  <v-card-text>
                    {{ shop.shop_title }}
                  </v-card-text>
                </v-card>
              </router-link>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-sheet
            elevation="0"
            max-width="600"
            rounded="lg"
            width="100%"
            class="pa-4 text-center mx-auto"
          >
            <v-icon
              class="mb-5"
              color="blue"
              icon="mdi-heart-outline"
              size="112"
            ></v-icon>

            <h2 class="text-h5 mb-6">{{ $t("settingpage.no_favorite") }}</h2>

            <p class="mb-4 text-medium-emphasis text-body-2">
              {{ $t("settingpage.like_shop_desc") }}
              <br />

              <a :href="'/hairsalon/searchresult'" class="text-decoration-none text-info">
                {{ $t("settingpage.find_shop") }}</a
              >
            </p>
          </v-sheet>
        </div>
        <!--  Favorite Stylist -->
        <!-- <div>
    <br />
    <br />
      <p class="text-h5 mx-2" style="font-weight: bold">
        <v-icon icon="mdi-heart" color="red" size="24px" class="mb-1"></v-icon>
        Favorite Stylist
      </p>

      <v-row no-gutters center warp class="d-flex pa-2">
        <v-col v-for="n in 3" :key="n" cols="6" xs="3" sm="3">
          <v-card
            class="text-left ma-3"
            color="white"
            @click.stop="cardOnClick = true"
            :ripple="false"
          >
            <v-responsive class="pt-0">
              <v-img
                max-height="96%"
                src="../stylelist.jpeg"
                class="fill-width"
                cover
                aspect-ratio="1"
              ></v-img>
            </v-responsive>

            <v-card-text class="mt-n4">
              <div class="text-h6" style="font-weight: normal">
                Angle Kitty
              </div>

              <v-chip-group>
                <v-chip>en,ch,jp</v-chip>
                <v-chip>Top</v-chip>
                <v-chip>Man</v-chip>
                <v-chip>Color</v-chip>
              </v-chip-group>

              <div class="mt-2 text-body-2" style="font-weight: normal">
                <v-icon
                  icon="mdi-store"
                  size="16"
                  color="black"
                  class="mt-n1"
                />
                Tokyo Beauty Salon
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div> -->

        <!--  Favorite Article -->
        <!-- <div>
      <br />
    <br />
      <p class="text-h5 mx-2" style="font-weight: bold">
        <v-icon icon="mdi-heart" color="red" size="24px" class="mb-1"></v-icon>
        Favorite Article
      </p>
      <v-row class="pa-2">
        <v-col v-for="n in 3" :key="n" cols="6" md="2">
          <v-card>
            <v-img cover src="article.jpg"></v-img>
            <v-card-text>title</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div> -->
      </v-container>
    </v-sheet>

    <VSonner position="top-center" />

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-sheet height="190" class="text-center">
        <v-progress-circular
          :indeterminate="loadingDialog"
          :size="100"
          color="blue"
          class="mt-12"
          >{{ $t("homeview.loading") }}
        </v-progress-circular>
      </v-sheet>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { VSonner, toast } from "vuetify-sonner";
import Constants from "@/uitls/Constants";
import utils from "@/uitls/Uitls";

export default {
  components: {
    VSonner,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      item: "authStore/item",
    }),
  },

  data() {
    return {
      shops: [],
      shop_pictures: [],
      local_shop: require("@/assets/img/logo120.png"),
      loadingDialog: true,
    };
  },

  created() {},

  beforeMount() {},

  async mounted() {
    const params = {
      id: this.item.user.id,
      target_type: Constants.FAVORITE_TYPE_SHOP,
      page: 1,
      per_page: 300,
    };

    await this.$store
      .dispatch("userStore/fetchFavorites", params)
      .then((response) => {
        this.loadingDialog = false;

        const shopData = [];
        response.data.forEach((shop) => {
          if (shop.shop.status) {
            var shop_img = Constants.NO_IMAGE;
            if (shop.shop.pictures.length > 0) {
              shop_img = shop.shop.pictures[0].picture_url_small;
            }

            shopData.push(utils.setShopDetails(shop.shop, shop_img));
          }
        });

        this.shops = shopData;

        const tmp = [];
        for (let index in this.shops) {
          tmp.push(this.shops[index].shop_id);
        }
        console.log(tmp);
      })
      .catch((error) => {
        this.loadingDialog = false;
        toast(error.message, {
          cardProps: {
            color: "error",
            class: "my-toast",
          },
        });
      });
  },

  methods: {
    prefectureCodeToString(code) {
      return utils.prefectureCodeToString(code);
    },
  },
};
</script>

<style>
</style>