import { INTEREST_API, USER_API } from '@/store/api'
import axiosClient from '@/plugins/axiosClient';

const state = () => ({
  userInterest: [],
})

const getters = {
  userInterest: (state) => state.userInterest,
}

const mutations = {
  SET_USER_INTEREST(state, item) {
    state.userInterest = item
  },
}

const actions = {
  async fetchAll({ commit }) {
    const response = await axiosClient.get(`${INTEREST_API}`)
    return response.data
  },
  async updateUserInterest({ commit, state, dispatch }, data) {
    try {
      const response = await axiosClient.post(`${USER_API}/interests`, data)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error
      }
      return null
    }
  },
  async getUserInterests({ commit, state, dispatch }, id) {
    try {
      const response = await axiosClient.get(`${USER_API}/interests/${id}`)
      commit('SET_USER_INTEREST', response.data)
      return response.data
    } catch (error) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}